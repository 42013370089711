import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const ArrowLeftCurved: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="50"
      height="26"
      viewBox="0 0 50 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.0002C4.61219 13.0002 13.0226 6.50024 13.0226 0.000244141M0 13.0002C4.61219 13.0002 13.0226 20.0419 13.0226 26.0002M0 13.0002L50 13.0003"
        stroke={theme.COLORS.ACCENT._200}
        strokeWidth="2"
      />
    </svg>
  );
};

export default withTheme(ArrowLeftCurved);
