import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import emptyPlayListImage from '../../../assets/empty-playlist.svg';
import { LocaleKeys } from '../../../locale';
import { TListedPlaylist } from '../../../types/responses/video';
import { getPlaylistItemPath } from '../../../utils/paths';
import { Footer, PreviewWrapper, Title, Wrapper } from './components';

type TProps = {
  playListData: TListedPlaylist;
};

export const Preview = styled(PreviewWrapper)`
  &:not(.empty) {
    overflow: visible;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  img.empty {
    object-fit: contain;
    left: -20px;
    top: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
  }
`;

export const PreviewListedItem = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    -0.27447px -0.30107px 2.2px 0px rgba(0, 0, 0, 0.11),
    -1.29751px -1.42326px 8.6px 0px rgba(0, 0, 0, 0.16),
    -3.36854px -3.69499px 27px 0px rgba(0, 0, 0, 0.27);

  img {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition:
      width 0.3s ease-out,
      height 0.3s ease-out;
  }

  &:not(:only-child):not(:last-of-type)::after {
    content: '';
    display: block;
    position: absolute;
    background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      rgba(0, 0, 0, 0.2) 50% / cover no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:not(:only-child) {
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    &:last-of-type {
      bottom: 0;
      right: 0;
      top: unset;
      left: unset;
    }
  }
`;

export const WrapperStyled = styled(Wrapper)`
  :hover {
    ${PreviewListedItem.toString()} {
      img {
        width: 110%;
        height: 110%;
      }
    }
  }
`;

export const PlaylistItem: FC<TProps> = ({ playListData }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  return (
    <WrapperStyled to={getPlaylistItemPath(playListData.id)}>
      <Preview
        className={
          [...playListData.videos].filter((el) => !!el.videoPreview).length > 1
            ? 'empty'
            : undefined
        }
      >
        {playListData.videos &&
        [...playListData.videos].filter((el) => !!el.videoPreview).length >
          1 ? (
          [...playListData.videos]
            .filter((el) => !!el.videoPreview)
            .splice(0, 2)
            .map((video) => (
              <PreviewListedItem key={video.id}>
                <img
                  src={video.videoPreview?.url}
                  className={'image'}
                  alt={'Превью плейлиста'}
                />
              </PreviewListedItem>
            ))
        ) : (
          <img
            className={'empty'}
            src={emptyPlayListImage}
            alt="Плейлист без обложки"
          />
        )}
      </Preview>
      <Title className={'name'}>{playListData.title}</Title>
      <Footer>
        <span>
          {playListData.videos.length || t('playlist.noVideo')}{' '}
          {t('playlist.video')}
        </span>
      </Footer>
    </WrapperStyled>
  );
};
