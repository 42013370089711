import { useCallback, useEffect, useMemo } from 'react';

export type TUseFormEnterProps<T extends Function> = {
  submitFunc: T;
  availableToPress?: boolean;
};

export const useFormEnterPress = <T extends Function>(
  props: TUseFormEnterProps<T>
) => {
  const isAvailable = useMemo(
    () => !props.availableToPress,
    [props.availableToPress]
  );

  const handlePress = useCallback(() => {
    if (isAvailable) {
      props.submitFunc();
    }
  }, [isAvailable, props.submitFunc]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handlePress();
      }
    };

    document.addEventListener('keypress', listener);

    return () => {
      document.removeEventListener('keypress', listener);
    };
  }, [handlePress]);
};
