import { Form, Input, Modal } from 'antd';
import App from 'antd/lib/app';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { useUserAccountSelector } from '../../state/auth/selectors';
import { playListAllActions } from '../../state/playlists/listSlice';
import { getPlaylistItemPath } from '../../utils/paths';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type TPlayListCreateForm = {
  title: string;
  ownerId?: string;
};

export const PlaylistCreateModal: FC<TProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const account = useUserAccountSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const onFormFinish = async (values: TPlayListCreateForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    try {
      setLoading(true);

      const response = await API.playlists.create({
        ...values,
        ownerId: account?.id,
      });
      dispatch(playListAllActions.resetState());
      navigate(getPlaylistItemPath(response.data.id));
      message.success(t('playlist.success'));
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('playlist.error'));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('playlist.title')}
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFormFinish}>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
          ]}
        >
          <Input className="themed" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
