import { Form, Input, Modal } from 'antd';
import App from 'antd/lib/app';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { useUserAccountSelector } from '../../state/auth/selectors';
import { useMeSelector } from '../../state/me/selectors';
import { meActions } from '../../state/me/slice';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type TNameForm = {
  firstName: string;
  lastName: string;
  middleName?: string;
};

export const AccountNameModal: FC<TProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const account = useUserAccountSelector();
  const { meData } = useMeSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const onFormFinish = async (values: TNameForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    try {
      setLoading(true);

      await API.me.patchMyUser({
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
      });
      message.success(t('profile.success'));
      dispatch(meActions.resetMe());
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('profile.error'));
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: meData?.firstName ?? undefined,
      lastName: meData?.lastName ?? undefined,
      middleName: meData?.middleName ?? undefined,
    });
  }, [meData]);

  return (
    <Modal
      title={t('profile.title')}
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFormFinish}>
        <Form.Item
          name="firstName"
          label={t('profile.name.first')}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            {
              pattern: /^[A-Za-zа-яА-ЯёЁ]+$/gs,
              message: t('rules.pattern'),
            },
          ]}
        >
          <Input className="themed" placeholder={t('profile.name.first')} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={t('profile.name.last')}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            {
              pattern: /^[A-Za-zа-яА-ЯёЁ]+$/gs,
              message: t('rules.pattern'),
            },
          ]}
        >
          <Input className="themed" placeholder={t('profile.name.last')} />
        </Form.Item>
        <Form.Item
          name="middleName"
          label={t('profile.name.middle')}
          rules={[
            {
              pattern: /^[A-Za-zа-яА-ЯёЁ]+$/gs,
              message: t('rules.pattern'),
            },
          ]}
        >
          <Input className="themed" placeholder={t('profile.name.middle')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
