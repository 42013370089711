import styled from '@emotion/styled';
import { Popconfirm, message, notification } from 'antd';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { LocaleKeys } from '../../../locale';
import { TLinkListed } from '../../../types/responses/video';
import {
  getPlayerEditLinkPath,
  getPlayerLink,
  getVideoLinkStatisticPath,
} from '../../../utils/paths';
import CopyIcon from '../../icons/copy-icon';
import DuplicateIcon from '../../icons/duplicate-icon';
import SettingsIcon from '../../icons/settings-icon';
import StatIcon from '../../icons/stat-icon';
import { Trash } from '../../icons/trash';

const VideoLinkWrapper = styled('div')`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 64px;
  }
`;

const ColContent = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  padding: 24px 16px;
  border-radius: 16px;
  height: 104px;
`;

const CenteredCol = styled(ColContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-out;

  &.danger {
    svg path {
      transition: fill 0.3s ease-out;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BLACK._300};

    &.danger {
      background-color: ${({ theme }) => theme.COLORS.DANGER};
      svg path {
        fill: ${({ theme }) => theme.COLORS.WHITE._100};
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ContentText = styled('div')`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ContentSubText = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};

  &.ghost {
    color: ${({ theme }) => theme.COLORS.BLACK._700};
  }
`;

const Title = styled('span')`
  display: inline-block;
  padding-left: 4px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

type TProps = TLinkListed & {
  index: number;
  handleDeleteLink: (id: string) => void;
  handleDuplicate: (id: string) => void;
};

const RowsWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr)) 216px;
  grid-gap: 8px;
  margin-bottom: 8px;
  position: relative;

  @media (min-width: 1250px) {
    grid-template-columns: repeat(5, minmax(128px, 1fr)) 216px;
  }

  .abs-container {
    position: relative;
    width: 216px;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-end: -1;

    ${CenteredCol} {
      position: absolute;
      left: 0;
      right: 0;
      width: 216px;
      height: 216px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

export const VideoLink: FC<TProps> = ({
  index,
  id,
  videoSetting,
  handleDeleteLink,
  handleDuplicate,
  videoId,
  description,
  clicksCount,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const copyLink = useCallback(async () => {
    const linkHref = getPlayerLink(id);

    try {
      await navigator.clipboard.writeText(linkHref);
      message.success(t('video.link.copy.success'));
    } catch (e) {
      notification.error({
        message: t('video.link.copy.error'),
        description: (
          <React.Fragment>
            {t('video.link.copy.byYourself')}{' '}
            <a href={linkHref} target="_blank">
              {linkHref}
            </a>
          </React.Fragment>
        ),
      });
    }
  }, [id]);

  const handleDelete = () => {
    handleDeleteLink(id);
  };

  const onHandleDuplicate = () => {
    handleDuplicate(id);
  };

  return (
    <VideoLinkWrapper>
      <div>
        <Title>
          {t('video.link.title')}
          {index + 1}
        </Title>
        <Title>/</Title>
        <Title>{videoSetting?.title || t('statistics.noName')}</Title>
      </div>
      <RowsWrapper>
        <ColContent
          style={{
            gridColumnEnd: 4,
            gridColumnStart: 1,
          }}
        >
          <ContentText>
            {description || t('statistics.link.noDescription')}
          </ContentText>
        </ColContent>
        <NavLink to={getVideoLinkStatisticPath(videoId, id)}>
          <CenteredCol>
            <StatIcon />
            <ContentSubText>{t('video.link.statistics')}</ContentSubText>
          </CenteredCol>
        </NavLink>
        <NavLink to={getPlayerEditLinkPath(videoId, id)}>
          <CenteredCol>
            <SettingsIcon />
            <ContentSubText>{t('video.link.settings')}</ContentSubText>
          </CenteredCol>
        </NavLink>
        <div className="abs-container">
          <CenteredCol onClick={copyLink}>
            <CopyIcon />
            <ContentSubText>{t('video.link.copy.title')}</ContentSubText>
          </CenteredCol>
        </div>
        <ColContent>
          <ContentText>{clicksCount ?? 0}</ContentText>
          <ContentSubText className="ghost">
            {t('statistics.link.views')}
          </ContentSubText>
        </ColContent>
        <ColContent>
          <ContentText>{videoSetting.increaseAttentionValue ?? 0}</ContentText>
          <ContentSubText className="ghost">
            {t('statistics.link.attention')}
          </ContentSubText>
        </ColContent>
        <ColContent>
          <ContentText>
            {videoSetting.minimumAttentionLimitValue ?? 0}
          </ContentText>
          <ContentSubText className="ghost">
            {t('statistics.link.involvement')}
          </ContentSubText>
        </ColContent>
        <CenteredCol onClick={onHandleDuplicate}>
          <DuplicateIcon />
          <ContentSubText>{t('video.link.duplicate.title')}</ContentSubText>
        </CenteredCol>
        <Popconfirm
          title={t('video.link.deleting.title')}
          description={t('video.link.deleting.description')}
          onConfirm={handleDelete}
          okText={t('buttons.deleting.success')}
          cancelText={t('buttons.cancel')}
          placement="bottom"
          okButtonProps={{
            danger: true,
            type: 'primary',
          }}
        >
          <CenteredCol className="danger">
            <Trash />
            <ContentSubText>{t('video.link.delete')}</ContentSubText>
          </CenteredCol>
        </Popconfirm>
        <div />
      </RowsWrapper>
    </VideoLinkWrapper>
  );
};
