import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type TPopover = {
  id: string;
  callback: () => void;
};

type TContext = {
  clearPopovers: (save?: string) => void;
  initPopover: (callback: TPopover) => void;
};

const context = createContext<TContext>({
  clearPopovers: () => {},
  initPopover: () => {},
});

export const usePopoversContext = () => useContext(context);

export const PopoversService: FC<PropsWithChildren> = ({ children }) => {
  const [_, setCloseCallbackList] = useState<Record<string, () => void>>({});

  const clearPopovers = useCallback(async (save?: string) => {
    setCloseCallbackList((prev) => {
      Object.keys(prev).forEach((key) => {
        if (save !== key) {
          prev[key]();
        }
      });

      return prev;
    });
  }, []);

  const initPopover = useCallback((popover: TPopover) => {
    setCloseCallbackList((prev) => ({
      ...prev,
      [popover.id]: popover.callback,
    }));
  }, []);

  return (
    <context.Provider value={{ clearPopovers, initPopover }}>
      {children}
    </context.Provider>
  );
};
