import { PersistConfig, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { IS_DEV_MODE, REDUX_STORE_VERSION } from '../constants';

export type TStateMigrationFn<StateType> = (
  state: StateType
) => Promise<StateType>;

export type TStateMigration<StateType> = Record<
  number,
  TStateMigrationFn<StateType>
>;

type TConfigProps<StateType> = {
  key: string;
  stateMigrations?: TStateMigration<StateType>;
};

export const getPersistConfig: <StateType>(
  props: TConfigProps<StateType>
) => PersistConfig<StateType> = ({ key, stateMigrations }) => ({
  key,
  storage,
  version: REDUX_STORE_VERSION,
  migrate: stateMigrations
    ? // @ts-ignore
      createMigrate(stateMigrations, { debug: IS_DEV_MODE })
    : undefined,
});
