import styled from '@emotion/styled';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DividerStyled } from '../../components/common/divider-styled';
import {
  BaseModal,
  ModalFooterWrapper,
} from '../../components/modal/base-modal';
import { useAppDispatch } from '../../state';
import { setLegalFormAction } from '../../state/finance/legalReportCreateSlice';
import { Paths } from '../../types/common';
import { TLegalForm } from '../../types/finance';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showReport: () => void;
  t: TFunction<string[]>;
};

export const LegalCheckBox = styled(Checkbox)`
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  display: flex;
  align-items: flex-start !important;

  .ant-checkbox {
    align-self: flex-start;
    margin-top: 4px;
  }

  a {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};

    &:hover {
      color: ${({ theme }) => theme.COLORS.ACCENT._500} !important;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  gap: 20px;

  .ant-form-item {
    flex-basis: 50%;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 30px;

  .ant-form-item .ant-form-item-label > label {
    color: ${({ theme }) => theme.COLORS.WHITE._600} !important;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }
`;

export const LegalFormModal: FC<TProps> = ({
  open,
  setOpen,
  showReport,
  t,
}) => {
  const [form] = Form.useForm<TLegalForm>();
  const dispatch = useAppDispatch();

  const [legalChecked, setLegalChecked] = useState(false);

  const onFinish = useCallback(
    (values: TLegalForm) => {
      if (!legalChecked) {
        void message.error(t('legalFormModal.needToCheckLegal'));

        return;
      }

      dispatch(setLegalFormAction(values));
      setOpen(false);
      showReport();
    },
    [legalChecked, t]
  );

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={t('legalFormModal.title')}
      width={600}
      maskClosable={false}
      t={t}
      footer={
        <ModalFooterWrapper>
          <LegalCheckBox
            checked={legalChecked}
            onChange={(e) => setLegalChecked(e.target.checked)}
          >
            <Trans
              t={t}
              i18nKey="legalFormModal.acceptLegal"
              components={{
                Link: <Link to={Paths.FINANCE} target="_blank" />,
              }}
            />
          </LegalCheckBox>
          <Button type="primary" onClick={form.submit}>
            {t('legalFormModal.next')}
          </Button>
        </ModalFooterWrapper>
      }
    >
      <FormWrapper>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            label={t('legalForm.company')}
            name="company"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('legalForm.directorFio')}
            name="directorFio"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('legalForm.inn')}
            name="inn"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FormRow>
            <Form.Item
              label={t('legalForm.kpp')}
              name="kpp"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('legalForm.ogrn')}
              name="ogrn"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormRow>
          <Form.Item
            label={t('legalForm.legalAddress')}
            name="legalAddress"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <DividerStyled />
          <Form.Item
            label={t('legalForm.currentAccount')}
            name="currentAccount"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('legalForm.corrAccount')}
            name="corrAccount"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FormRow>
            <Form.Item
              label={t('legalForm.bik')}
              name="bik"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('legalForm.bank')}
              name="bank"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormRow>
          <DividerStyled
            style={{
              marginTop: 15,
            }}
          />
        </Form>
      </FormWrapper>
    </BaseModal>
  );
};
