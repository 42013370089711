import styled from '@emotion/styled';
import { FC } from 'react';

import userIcon from '../../assets/user-icon.svg';

type TProps = {
  url?: string;
  className?: string;
  imgClassName?: string;
};

const Wrapper = styled('div')`
  min-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    &.vector {
      width: 40px;
      height: 40px;
      object-fit: unset;
    }
  }
`;

export const UserAvatar: FC<TProps> = ({ url, className, imgClassName }) => {
  const imgClass = imgClassName ? imgClassName : !url ? 'vector' : undefined;

  return (
    <Wrapper className={className}>
      <img src={url ?? userIcon} className={imgClass} alt="Аватар" />
    </Wrapper>
  );
};
