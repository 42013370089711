import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import emptyPlayListImage from '../../../assets/empty-playlist.svg';
import { LocaleKeys } from '../../../locale';
import { TListedPlaylist } from '../../../types/responses/video';
import { getPlaylistItemPath } from '../../../utils/paths';
import { Preview, PreviewListedItem } from './playlist-item';
import {
  VideoColDescription,
  VideoColStatItem,
  VideoColStatText,
  VideoColStatTitle,
  VideoColStatWrapper,
  VideoColText,
  VideoColTextWrapper,
  VideoGridContentWrapper,
} from './video-col-item';

type TProps = {
  playListData: TListedPlaylist;
};

const WrapperLink = styled(NavLink)`
  position: relative;
`;

export const PlaylistColItem: FC<TProps> = ({ playListData }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  return (
    <WrapperLink to={getPlaylistItemPath(playListData.id)}>
      <VideoGridContentWrapper>
        <Preview
          className={
            [...playListData.videos].filter((el) => !!el.videoPreview).length >
            1
              ? 'empty'
              : undefined
          }
        >
          {playListData.videos &&
          [...playListData.videos].filter((el) => !!el.videoPreview).length >
            1 ? (
            [...playListData.videos]
              .filter((el) => !!el.videoPreview)
              .splice(0, 2)
              .map((video) => (
                <PreviewListedItem key={video.id}>
                  <img
                    src={video.videoPreview?.url}
                    className={'image'}
                    alt={'Превью плейлиста'}
                  />
                </PreviewListedItem>
              ))
          ) : (
            <img
              className={'empty'}
              src={emptyPlayListImage}
              alt="Плейлист без обложки"
            />
          )}
        </Preview>
        <VideoColTextWrapper>
          <VideoColText level={3}>{playListData.title}</VideoColText>
          <VideoColDescription>
            {playListData.videos.length} {t('playlist.video')}
          </VideoColDescription>
        </VideoColTextWrapper>
        <VideoColStatWrapper>
          <VideoColStatItem>
            <VideoColStatTitle>
              {dayjs(playListData.createdAt).format('DD MMMM HH:mm')}
            </VideoColStatTitle>
            <VideoColStatText>{t('stats.created')}</VideoColStatText>
          </VideoColStatItem>
          <VideoColStatItem>
            <VideoColStatTitle>
              {dayjs(playListData.updatedAt).format('DD MMMM HH:mm')}
            </VideoColStatTitle>
            <VideoColStatText>{t('stats.updated')}</VideoColStatText>
          </VideoColStatItem>
        </VideoColStatWrapper>
      </VideoGridContentWrapper>
    </WrapperLink>
  );
};
