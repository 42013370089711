import { LangType } from '../state/locale/localeSlice';
import {
  ECurrency,
  EFinancePeriod,
  TFinanceFeature,
  TPrice,
  TTariffPlan,
  TariffFeatures,
} from '../types/finance';

export function getPlanPriceByPeriodAndLocale(
  period: EFinancePeriod,
  locale: LangType,
  prices: Pick<TTariffPlan, 'pricePerMonth' | 'pricePerYear'>
): TPrice {
  let priceObject: Record<ECurrency, TPrice>;

  switch (period) {
    case EFinancePeriod.YEAR:
      priceObject = prices.pricePerYear;
      break;
    case EFinancePeriod.MONTH:
      priceObject = prices.pricePerMonth;
      break;
    default:
      priceObject = prices.pricePerMonth;
  }

  switch (locale) {
    case 'ru':
      return priceObject[ECurrency.RUR];
    case 'en':
      return priceObject[ECurrency.USD];
    default:
      return priceObject[ECurrency.USD];
  }
}

export function mapTariffFeaturesToArray(
  features: TariffFeatures
): TFinanceFeature[] {
  const featuresArray: TFinanceFeature[] = [];

  (Object.keys(features) as (keyof TariffFeatures)[]).map((key) => {
    featuresArray.push({
      name: key,
      value: features[key],
    });
  });

  return featuresArray;
}
