import styled from '@emotion/styled';
import { Checkbox, Modal } from 'antd';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import enIco from '../../assets/en-lang-ico.svg';
import ruIco from '../../assets/ru-lang-ico.svg';
import { APP_LANGUAGES } from '../../constants/app-lang';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { LangType, setLang } from '../../state/locale/localeSlice';
import { useLocaleSelector } from '../../state/locale/selector';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const getIcoPathByLocale = (locale?: LangType) => {
  switch (locale) {
    case 'en':
      return enIco;
    case 'ru':
      return ruIco;
    default:
      return enIco;
  }
};

const LanguageCheckbox = styled(Checkbox)`
  position: relative;
  display: flex;
  color: white;
  background-color: rgba(0, 0, 0, 65%);
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 16px;
`;

const CheckboxIco = styled('img')`
  position: absolute;
  right: 16px;
  object-fit: cover;
`;

export const ChangeLanguageModal: FC<TProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const currentLanguage = useLocaleSelector();
  const [language, setLanguage] = useState<LangType>(currentLanguage.lang);

  const onFormFinish = () => {
    dispatch(setLang(language));
    onClose();
  };

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Modal
      title={t('language.title')}
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      onOk={onFormFinish}
      cancelText={t('buttons.cancel')}
    >
      {APP_LANGUAGES.map((item) => {
        return (
          <LanguageCheckbox
            key={item.value}
            checked={item.value === language}
            onChange={() => setLanguage(item.value)}
          >
            {item.text}
            <CheckboxIco src={getIcoPathByLocale(item.value)} alt={item.text} />
          </LanguageCheckbox>
        );
      })}
    </Modal>
  );
};
