import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { useUserAccountSelector } from '../../state/auth/selectors';
import { useMeSelector } from '../../state/me/selectors';
import { UserAvatar } from '../common/user-avatar';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled(UserAvatar)`
  margin-bottom: 16px;
`;

const Name = styled('h3')`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 8px 0;
`;

const Email = styled('span')`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

export const UserInfo: FC = () => {
  const { t } = useTranslation([LocaleKeys.ACCOUNT]);
  const account = useUserAccountSelector();
  const { meData } = useMeSelector();

  const userName =
    meData && meData.firstName
      ? [meData.lastName, meData.firstName, meData.middleName]
          .filter(Boolean)
          .join(' ')
      : t('info.name');

  return (
    <Wrapper>
      <Avatar url={account?.profilePhotoUrl ?? undefined} />
      <Name>{userName}</Name>
      <Email>{meData?.email ?? t('info.email')}</Email>
    </Wrapper>
  );
};
