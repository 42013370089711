import styled from '@emotion/styled';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { NBSP } from '../../utils/text-formatters';
import { CheckMark } from '../icons/check-mark';
import { CommonSelect } from './common-select';
import {
  WithDescriptionSelectWrapper,
  WithDescriptionTextItem,
  WithDescriptionWrapper,
} from './video-marker';

const TextAreaWrapper = styled('div')`
  margin-top: 20px;
  position: relative;
  max-width: 700px;
`;

type TProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  text?: string;
  setText?: (text: string) => void;
};

export const SubtitlesArea: FC<{
  text?: string;
  setText?: (text: string) => void;
  isInput?: boolean;
  placeholder?: string;
}> = ({ text, setText, isInput, placeholder }) => {
  const [value, setValue] = useState<string>(text ?? '');

  useEffect(() => {
    setValue(text ?? '');
  }, [text]);

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(e.target.value);
      if (setText) {
        setText(e.target.value ?? '');
      }
    },
    [setText]
  );

  return (
    <TextAreaWrapper>
      {isInput ? (
        <Input
          className="themed"
          value={value}
          onChange={handleValueChange}
          size="large"
          placeholder={placeholder}
          style={{
            height: 80,
            padding: '24px 32px',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '130%',
            marginTop: -20,
          }}
        />
      ) : (
        <TextArea
          className="dark-t-area"
          autoSize={{ minRows: 10, maxRows: 14 }}
          value={value}
          onChange={handleValueChange}
          placeholder={placeholder}
        />
      )}
    </TextAreaWrapper>
  );
};

export const Subtitles: FC<TProps> = ({ value, setValue, setText, text }) => {
  return (
    <Fragment>
      <WithDescriptionWrapper>
        <WithDescriptionSelectWrapper>
          <CommonSelect label="Субтитры" value={value} setValue={setValue} />
        </WithDescriptionSelectWrapper>
        <WithDescriptionTextItem>
          Текст распознан{NBSP}
          <CheckMark />
        </WithDescriptionTextItem>
      </WithDescriptionWrapper>
      <SubtitlesArea text={text} setText={setText} />
    </Fragment>
  );
};
