import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import React, { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseBlock } from '../components/common/blocks';
import { PageBackButton } from '../components/common/page-back-button';
import {
  BaseText,
  BaseTitle,
  MainBlock,
  MainBlockTitle,
} from '../components/video-page/about-video/components';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useLoadVideo } from '../hooks/use-load-video';
import { LocaleKeys } from '../locale';
import mockVideo from '../mocks/mock-video.mp4';
import { useLocaleSelector } from '../state/locale/selector';
import { useVideoSelector } from '../state/video/selectors';
import { TChunkMapped } from '../types/responses/video';
import { IExportIndex } from '../types/stats';
import { pluralize } from '../utils/text-formatters';

const Wrapper = styled('div')`
  padding-bottom: 60px;
  padding-right: 40px;
`;

export const DataWrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 1fr) minmax(
      140px,
      1fr
    );
  grid-template-rows: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
  height: fit-content;
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 5fr 2fr;
  column-gap: 60px;
  margin-top: 80px;
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

const LoadingWrapper = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  min-height: 340px;
`;

const StatsTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: white;
  gap: 30px;
  margin-top: -12px;

  > div {
    height: 4px;
  }
`;

export const LinkStatistic: FC = () => {
  const curLanguage = useLocaleSelector();
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const [, , linkId] = useLoadVideo();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { linksData, loading, data } = useVideoSelector();

  const linkToView = useMemo(() => {
    if (!linksData) {
      return undefined;
    }
    return linksData.find((el) => el.id === linkId);
  }, [linksData, linkId]);

  const chunksData: TChunkMapped[] = useMemo(() => {
    if (linkToView) {
      return linkToView.videoChunks.chunks.map((el) => ({
        ...el,
        dataJson:
          el.dataJson && typeof el.dataJson === 'string'
            ? JSON.parse(el.dataJson)
            : undefined,
      }));
    }

    return [];
  }, [linkToView]);

  const indexesAVG = useMemo(() => {
    let data: Record<
      keyof Omit<IExportIndex, 'presence'>,
      {
        sum: number;
        count: number;
      }
    > = {
      amazement: {
        sum: 0,
        count: 0,
      },
      attention: {
        sum: 0,
        count: 0,
      },
      happiness: {
        sum: 0,
        count: 0,
      },
      involvement: {
        sum: 0,
        count: 0,
      },
      tiredness: {
        sum: 0,
        count: 0,
      },
    };

    chunksData.forEach((el) => {
      data = {
        ...data,
        amazement: {
          sum: data.amazement.sum + (el.dataJson?.amazement.average ?? 0),
          count: data.amazement.count + 1,
        },
        attention: {
          sum: data.attention.sum + (el.dataJson?.attention.average ?? 0),
          count: data.attention.count + 1,
        },
        happiness: {
          sum: data.happiness.sum + (el.dataJson?.happiness.average ?? 0),
          count: data.happiness.count + 1,
        },
        involvement: {
          sum: data.involvement.sum + (el.dataJson?.involvement.average ?? 0),
          count: data.involvement.count + 1,
        },
        tiredness: {
          sum: data.tiredness.sum + (el.dataJson?.tiredness.average ?? 0),
          count: data.tiredness.count + 1,
        },
      };
    });

    return {
      amazement: Math.round(data.amazement.sum / data.amazement.count),
      attention: Math.round(data.attention.sum / data.attention.count),
      happiness: Math.round(data.happiness.sum / data.happiness.count),
      involvement: Math.round(data.involvement.sum / data.involvement.count),
      tiredness: Math.round(data.tiredness.sum / data.tiredness.count),
    };
  }, [chunksData]);

  return (
    <Wrapper>
      <PageBackButton
        text={`${t('statistics.link.button.title')} ${
          !loading
            ? linkToView?.videoSetting.title ||
              t('statistics.link.button.noName')
            : t('statistics.link.button.loading')
        }`}
      />
      {loading ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <VideoWrapper>
            <VideoPlayer
              videoSrc={data?.originUrls ?? mockVideo}
              ref={videoRef}
              preview={
                data?.videoPreview?.url
                  ? {
                      url: data?.videoPreview?.url,
                      alt: data?.title ?? t('statistics.link.button.noName'),
                    }
                  : undefined
              }
            />
          </VideoWrapper>
          <DataWrapper>
            <MainBlockTitle
              style={{
                position: 'absolute',
              }}
            >
              {t('statistics.link.title')}
            </MainBlockTitle>
            <MainBlock
              style={{
                marginTop: 50,
              }}
            >
              <BaseTitle>{data?.viewsCount ?? 0}</BaseTitle>
              <BaseText>
                {pluralize(data?.viewsCount ?? 0, curLanguage.lang, [
                  t('videos.viewing.one'),
                  t('videos.viewing.many'),
                  t('videos.viewing.few'),
                ])}
              </BaseText>
            </MainBlock>
            <MainBlock>
              <BaseTitle>
                {linkToView?.clicksCount ?? 0}{' '}
                {pluralize(data?.viewsCount ?? 0, curLanguage.lang, [
                  t('videos.viewing.one'),
                  t('videos.viewing.many'),
                  t('videos.viewing.few'),
                ])}
              </BaseTitle>
              <BaseText>{t('statistics.link.via')}</BaseText>
            </MainBlock>
            <BaseBlock>
              <BaseTitle>{indexesAVG.attention}%</BaseTitle>
              <BaseText>{t('statistics.link.attention')}</BaseText>
            </BaseBlock>
            <BaseBlock>
              <BaseTitle>{indexesAVG.involvement}%</BaseTitle>
              <BaseText>{t('statistics.link.involvement')}</BaseText>
            </BaseBlock>
            <BaseBlock>
              <BaseTitle>{indexesAVG.happiness}%</BaseTitle>
              <BaseText>{t('statistics.link.happiness')}</BaseText>
            </BaseBlock>
          </DataWrapper>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <TimeLineControl
              ref={videoRef}
              showByStats
              chunksToVisualize={chunksData}
            />
          </div>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <StatsTextWrapper>
              <div>{t('statistics.link.attention')}</div>
              <div>{t('statistics.link.involvement')}</div>
              <div>{t('statistics.link.happiness')}</div>
              <div>{t('statistics.link.fatigue')}</div>
              <div>{t('statistics.link.distraction')}</div>
            </StatsTextWrapper>
          </div>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};
