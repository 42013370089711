import { AxiosError } from 'axios';
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from '../api';
import { useAppDispatch } from '../state';
import { useAuthSelector } from '../state/auth/selectors';
import { logoutAction } from '../state/auth/slice';
import { useMeSelector } from '../state/me/selectors';
import { meActions } from '../state/me/slice';
import { Paths } from '../types/common';

const unAuthPaths: string[] = [
  Paths.LOGIN,
  Paths.FORGOT_PASSWORD,
  Paths.REGISTRATION,
  Paths.FORGOT_COMPLETE,
];

export const AuthService: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { authed } = useAuthSelector();
  const { meLoaded, meError, mePending } = useMeSelector();
  const navigate = useNavigate();
  const pendingRef = useRef(false);

  useEffect(() => {
    if (
      unAuthPaths.includes(location.pathname) &&
      authed &&
      location.pathname !== Paths.FORGOT_PASSWORD
    ) {
      navigate(Paths.BASE);
    } else if (
      !authed &&
      !unAuthPaths.includes(location.pathname) &&
      !unAuthPaths.includes(
        location.pathname.split('/').map((el) => `/${el}`)[1]
      )
    ) {
      navigate(Paths.LOGIN);
    }
  }, [authed, location]);

  const handleLoadMe = useCallback(async () => {
    try {
      pendingRef.current = true;
      dispatch(meActions.startMe());

      const response = await API.me.getMyAccount();

      dispatch(meActions.finishMe(response.data));

      pendingRef.current = false;
    } catch (e: unknown) {
      pendingRef.current = false;
      dispatch(meActions.errorMe());
      // @ts-ignore
      if ('response' in (e as AxiosError) && e.response.status === 403) {
        dispatch(logoutAction());
      }

      // @ts-ignore
      if (e && e?.response && e?.response?.status === 401) {
        dispatch(logoutAction());
      }
    }
  }, []);

  useEffect(() => {
    if (authed && !pendingRef.current && !meLoaded && !mePending && !meError) {
      void handleLoadMe();
    }
  }, [authed, meLoaded, mePending, meError]);

  return <Fragment>{children}</Fragment>;
};
