import { LangType } from '../state/locale/localeSlice';

type TPluralizeLangFn = (
  dict: [string, string, string],
  value: number
) => string;

export const NBSP = '\u00A0';

const pluralizeRu: TPluralizeLangFn = (dict, value) => {
  const lastChar = Number(value.toString().slice(-1));
  const mod_100 = value % 100;

  switch (true) {
    case lastChar === 1 && (mod_100 < 10 || mod_100 > 20):
      return dict[0]; // Просмотр
    case lastChar >= 5 || lastChar === 0:
      return dict[1]; // Просмотров
    case lastChar < 5 && mod_100 < 10 && mod_100 > 20:
      return dict[2]; // Просмотра
    default:
      return dict[1];
  }
};

const pluralizeEn: TPluralizeLangFn = (dict, value) => {
  return value === 1 ? dict[0] : dict[1];
};

export function pluralize(
  value: number,
  language: LangType,
  dict: [string, string, string]
): string {
  switch (true) {
    case language === 'ru':
      return pluralizeRu(dict, value);
    case language === 'en':
      return pluralizeEn(dict, value);
    default:
      return pluralizeRu(dict, value);
  }
}
