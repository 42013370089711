import styled from '@emotion/styled';
import { ChangeEvent, FC, PropsWithChildren } from 'react';

type TProps = {
  value?: any;
  name: string;
  onChange?: (value?: any) => void;
  checked?: boolean;
};

const RadioMarker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  .circle {
    fill: ${({ theme }) => theme.COLORS.WHITE._600};
  }
  .checked {
    display: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const StyledRadio = styled.label`
  padding: 20px 25px;
  border-radius: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  background-color: ${({ theme }) => theme.COLORS.BLACK._200};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  cursor: pointer;

  input:checked + ${RadioMarker} {
    .circle {
      display: none;
    }
    .checked {
      display: block;
      path {
        fill: ${({ theme }) => theme.COLORS.ACCENT._200} !important;
      }
    }
  }

  .ant-radio-inner {
    display: none;
  }
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const RadioBlockGroup: FC<PropsWithChildren> = ({ children }) => {
  return <GroupWrapper>{children}</GroupWrapper>;
};

export const RadioBlock: FC<PropsWithChildren<TProps>> = ({
  value,
  name,
  children,
  onChange,
  checked,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <StyledRadio>
      <input
        type="radio"
        value={value}
        name={name}
        hidden
        onChange={handleChange}
        checked={checked}
      />
      {children}
      <RadioMarker>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="circle"
        >
          <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="checked"
        >
          <path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z" />
        </svg>
      </RadioMarker>
    </StyledRadio>
  );
};
