import { TChunkMapped } from '../types/responses/video';
import { IExportIndex, TStatByChunk } from '../types/stats';

const ADD_BASE_GRADIENT_RULE = (gradient: string) =>
  `linear-gradient(90deg, ${gradient})`;

interface WarmMap {
  success: string;
  warning: string;
  error: string;
}

function getColorsByIndex(
  indexKey: keyof Omit<IExportIndex, 'presence'>
): WarmMap {
  let baseWarmMap: WarmMap = {
    success: '#5CF66C',
    warning: '#FFCC17',
    error: '#FA5555',
  };

  if (indexKey === 'happiness') {
    return {
      success: baseWarmMap.success,
      warning: baseWarmMap.warning,
      error: '#686868',
    };
  }

  if (indexKey === 'tiredness' || indexKey === 'amazement') {
    return {
      success: baseWarmMap.error,
      warning: baseWarmMap.warning,
      error: baseWarmMap.success,
    };
  }

  return baseWarmMap;
}

export function createGradientColorByChunk(
  chunks: TChunkMapped[]
): Record<keyof Omit<IExportIndex, 'presence'>, string> {
  let resultGradient: Record<keyof Omit<IExportIndex, 'presence'>, string> = {
    amazement: '',
    attention: '',
    happiness: '',
    involvement: '',
    tiredness: '',
  };

  const neutralColor = '#686868';

  if (chunks.length < 1) {
    return resultGradient;
  }

  const lastTimeValue = chunks[chunks.length - 1].end;
  let lastPosition = 0;

  chunks.map((chunk) => {
    if (chunk.dataJson) {
      (Object.keys(chunk.dataJson) as (keyof TStatByChunk)[]).forEach((key) => {
        if (chunk.dataJson) {
          const data = chunk.dataJson[key];
          const position =
            Math.round((chunk.end / (lastTimeValue / 100)) * 10) / 10;

          const colorMap = getColorsByIndex(key);

          let color: string;

          if (data.average >= 70) {
            color = colorMap.success;
          } else if (data.average >= 40) {
            color = colorMap.warning;
          } else {
            color = colorMap.error;
          }

          const newValue =
            resultGradient[key]?.length > 0
              ? `${resultGradient[key]}, ${color} ${
                  lastPosition + 0.1
                }%, ${color} ${position}%`
              : `${color} ${lastPosition + 0.1}%, ${color} ${position}%`;

          resultGradient = {
            ...resultGradient,
            [key]: newValue,
          };
        }
      });

      lastPosition = Math.round((chunk.end / (lastTimeValue / 100)) * 10) / 10;
    } else {
      const position =
        Math.round((chunk.end / (lastTimeValue / 100)) * 10) / 10;

      (
        Object.keys(resultGradient) as (keyof Omit<IExportIndex, 'presence'>)[]
      ).map((el) => {
        resultGradient = {
          ...resultGradient,
          [el]:
            el.length > 0
              ? `${resultGradient[el]}, ${neutralColor} ${
                  lastPosition + 0.1
                }%, ${neutralColor} ${position}%`
              : `${neutralColor} ${
                  lastPosition + 0.1
                }%, ${neutralColor} ${position}%`,
        };
      });

      lastPosition = position;
    }
  });

  return {
    amazement: ADD_BASE_GRADIENT_RULE(resultGradient.amazement),
    attention: ADD_BASE_GRADIENT_RULE(resultGradient.attention),
    happiness: ADD_BASE_GRADIENT_RULE(resultGradient.happiness),
    involvement: ADD_BASE_GRADIENT_RULE(resultGradient.involvement),
    tiredness: ADD_BASE_GRADIENT_RULE(resultGradient.tiredness),
  };
}
