import styled from '@emotion/styled';
import { FC, PropsWithChildren, useCallback } from 'react';

import LayoutGridIcon from '../icons/layout-grid-icon';
import LayoutTableIcon from '../icons/layout-table-icon';
import { PageTitle } from './page-title';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocaleKeys } from '../../locale';


const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
`;

const LayoutWrapper = styled('div')`
  cursor: pointer;
  height: 24px;
  width: auto;

  svg {
    height: 24px;
    width: auto;
  }
`;

export const LS_LAYOUT_VALUE_LEY = 'page-layout-variant';

export const TitleWithLayout: FC<
  PropsWithChildren<{
    layout: 'col' | 'grid';
    onChange: (layout: 'col' | 'grid') => void;
  }>
> = ({ children, layout, onChange }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const handleChange = useCallback(() => {
    onChange(layout === 'col' ? 'grid' : 'col');
  }, [layout]);


  return (
    <Wrapper>
      <PageTitle>{children}</PageTitle>
      <Tooltip placement="left" title={t('playlist.sort')} >
        <LayoutWrapper onClick={handleChange}>
          {layout === 'col' ? <LayoutTableIcon /> : <LayoutGridIcon />}
        </LayoutWrapper>
      </Tooltip>
    </Wrapper>
  );
};
