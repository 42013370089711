import styled from '@emotion/styled';
import Tooltip from 'antd/lib/tooltip';
import React, {
  Fragment,
  MouseEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TChunkMapped } from '../../types/responses/video';
import { createGradientColorByChunk } from '../../utils/colors';
import { boolToText, textToBool, timeToString } from '../../utils/common';

const Wrapper = styled('div')<{ zeroPaddings: 'true' | 'false' }>`
  width: 100%;
  padding-bottom: ${({ zeroPaddings }) =>
    textToBool(zeroPaddings) ? 'unset' : '50px'};
`;

const Line = styled('div')<{ gradient?: string }>`
  height: 4px;
  border-radius: 2px;
  background-color: rgba(178, 178, 178, 0.5);
  background-image: ${({ gradient }) => gradient};
  overflow: hidden;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

const InLineMarker = styled('div')<{ percent: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(props) => props.percent}%;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
`;

const StatsLines = styled('div')<{ leftPos: number }>`
  cursor: pointer;
  position: relative;
  user-select: none;

  .ant-tooltip {
    left: ${({ leftPos }) => leftPos}% !important;
    transform: translateX(-50%) translateY(calc(100% + 30px));
    bottom: 0 !important;
  }
`;

const TimeMarker = styled('div')<{ leftPos: number }>`
  position: absolute;
  width: 4px;
  left: ${({ leftPos }) => leftPos}%;
  transform: translateX(-50%);
  top: -10px;
  bottom: -10px;
  background-color: ${({ theme }) => theme.COLORS.WHITE._100};
  border-radius: 2px;
`;

const TimeWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

type TProps = {
  showByStats?: boolean;
  zeroPaddings?: boolean;
  chunksToVisualize?: TChunkMapped[];
};

export const TimeLineControl = forwardRef<HTMLVideoElement | null, TProps>(
  ({ showByStats, zeroPaddings = false, chunksToVisualize }, videoRef) => {
    const [markerPos, setMarkerPos] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoDuration, setVideoDuration] = useState('00:00');
    const lineAreaRef = useRef<HTMLDivElement | null>(null);
    const [tooltipVisible, setToolTipVisible] = useState(false);

    const gradientMap = useMemo(() => {
      if (chunksToVisualize) {
        return createGradientColorByChunk(chunksToVisualize);
      }

      return undefined;
    }, [chunksToVisualize]);

    const handleLineClick: MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        if (
          lineAreaRef &&
          lineAreaRef.current &&
          videoRef &&
          'current' in videoRef &&
          videoRef.current
        ) {
          const { left } = lineAreaRef.current.getBoundingClientRect();
          const currentPercent = Math.round(
            (e.clientX - left) / (lineAreaRef.current?.clientWidth / 100)
          );

          videoRef.current.currentTime =
            (videoRef.current?.duration / 100) * currentPercent;
        }
      },
      []
    );

    useEffect(() => {
      if (videoRef && 'current' in videoRef && videoRef.current) {
        videoRef.current.ontimeupdate = (e) => {
          const target = e.target as HTMLVideoElement;

          setCurrentTime(Math.round(target.currentTime));

          setMarkerPos(target.currentTime / (target.duration / 100));
        };
      }
    }, []);

    useEffect(() => {
      let timeout: NodeJS.Timeout;

      const overListener = (e: React.MouseEvent<HTMLDivElement>) => {
        if (
          lineAreaRef &&
          lineAreaRef.current &&
          videoRef &&
          'current' in videoRef &&
          videoRef.current
        ) {
          if (timeout) {
            clearTimeout(timeout);
          }

          const { left } = lineAreaRef.current.getBoundingClientRect();
          let currentPercent = Math.round(
            (e.clientX - left) / (lineAreaRef.current.clientWidth / 100)
          );

          if (currentPercent < 0) {
            currentPercent = 0;
          } else if (currentPercent > 100) {
            currentPercent = 100;
          }

          setMarkerPos(currentPercent);

          const newTime = (videoRef.current?.duration / 100) * currentPercent;

          setCurrentTime(Math.round(newTime));

          timeout = setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.currentTime = newTime;
            }
          }, 50);
        }
      };

      const startListener = () => {
        document.addEventListener('mousemove', overListener as any);
      };

      const endListener = () => {
        document.removeEventListener('mousemove', overListener as any);
      };

      lineAreaRef.current?.addEventListener('mousedown', startListener);
      window.addEventListener('mouseup', endListener);

      return () => {
        lineAreaRef.current?.removeEventListener('mousedown', startListener);
        window.removeEventListener('mouseup', endListener);
      };
    }, []);

    useEffect(() => {
      const onListener = () => {
        setToolTipVisible(true);
      };

      const offListener = () => {
        setToolTipVisible(false);
      };

      if (lineAreaRef.current) {
        lineAreaRef.current.addEventListener('mouseenter', onListener);
        lineAreaRef.current.addEventListener('mouseleave', offListener);
      }

      return () => {
        if (lineAreaRef.current) {
          lineAreaRef.current.removeEventListener('mouseenter', onListener);
          lineAreaRef.current.removeEventListener('mouseleave', offListener);
        }
      };
    }, []);

    const timeInString = useMemo(() => {
      return timeToString(currentTime);
    }, [currentTime]);

    useEffect(() => {
      setTimeout(() => {
        const duration = timeToString(
          videoRef &&
            'current' in videoRef &&
            videoRef.current &&
            videoRef.current.duration
            ? Math.round(videoRef.current.duration)
            : 0
        );

        setVideoDuration(duration);
      }, 100);

      if (videoRef && 'current' in videoRef && videoRef.current) {
        videoRef.current?.addEventListener('loadeddata', () => {
          const duration = timeToString(
            videoRef &&
              'current' in videoRef &&
              videoRef.current &&
              videoRef.current.duration
              ? Math.round(videoRef.current.duration)
              : 0
          );

          setVideoDuration(duration);
        });
      }
    }, []);

    return (
      <Wrapper zeroPaddings={boolToText(zeroPaddings)}>
        <StatsLines
          onClick={handleLineClick}
          ref={lineAreaRef}
          leftPos={markerPos}
        >
          {showByStats ? (
            <Fragment>
              <Line gradient={gradientMap?.attention} />
              <Line gradient={gradientMap?.involvement} />
              <Line gradient={gradientMap?.happiness} />
              <Line gradient={gradientMap?.tiredness} />
              <Line gradient={gradientMap?.amazement} />
              <Tooltip
                title={timeInString}
                open={true}
                getPopupContainer={() => lineAreaRef.current as HTMLElement}
                overlayClassName="ant-tooltip-placement-bottom"
              >
                <TimeMarker leftPos={markerPos} />
              </Tooltip>
            </Fragment>
          ) : (
            <Fragment>
              <Line>
                <InLineMarker percent={markerPos} />
              </Line>
              <Tooltip
                title={timeInString}
                open={tooltipVisible}
                overlayClassName="ant-tooltip-placement-bottom"
                placement={'bottom'}
                style={{
                  left: `${markerPos}%`,
                }}
              >
                <TimeMarker leftPos={markerPos} />
              </Tooltip>
            </Fragment>
          )}
        </StatsLines>
        <TimeWrapper>
          <span>{timeInString}</span>
          <span>{videoDuration}</span>
        </TimeWrapper>
      </Wrapper>
    );
  }
);
