import App from 'antd/lib/app';
import { FC, Fragment, useCallback, useEffect, useRef } from 'react';

import { API } from '../../api';
import { useAppDispatch } from '../../state';
import { useVideoViewedSelector } from '../../state/video/selectors';
import {
  viewedVideosEndAction,
  viewedVideosErrorAction,
  viewedVideosStartAction,
} from '../../state/video/viewedSlice';
import { ESortOption } from '../../types/video';
import { sortByDateField } from '../../utils/video';
import { VideoColItem } from '../uploaded-videos/uploaded-items/video-col-item';
import { VideoItem } from '../uploaded-videos/uploaded-items/video-item';

export const VideosViewed: FC<{
  sortOption?: ESortOption;
  layout?: 'grid' | 'col';
}> = ({ sortOption, layout = 'grid' }) => {
  const dispatch = useAppDispatch();

  const loadingRef = useRef(false);

  const { pending, loaded, data, error } = useVideoViewedSelector();

  const { message } = App.useApp();

  const handleLoadVideos = useCallback(async () => {
    loadingRef.current = true;
    dispatch(viewedVideosStartAction());
    try {
      const response = await API.videos.getViewed();
      dispatch(viewedVideosEndAction(response.data));
      loadingRef.current = false;
    } catch (e) {
      message.error('Ошибка при загрузке видеозаписей');
      dispatch(viewedVideosErrorAction());
      loadingRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (!pending && !loaded && !loadingRef.current && !error) {
      void handleLoadVideos();
    }
  }, [loaded, pending, error]);

  return (
    <Fragment>
      {[...data]
        .sort((a, b) =>
          sortByDateField(a, b, 'createdAt', sortOption === ESortOption.OLD)
        )
        .map((el) => {
          return layout === 'grid' ? (
            <VideoItem key={el.id} videoData={el} />
          ) : (
            <VideoColItem key={el.id} videoData={el} />
          );
        })}
    </Fragment>
  );
};
