import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ESimpleDateKey } from '../../components/analytics/dates-list';

type TState = {
  selectedSimpleDateKey?: ESimpleDateKey;
  startDate?: string;
  endDate?: string;
  videosId?: string[];
  linkId?: string[];
  userId?: string[];
};

const initialState: TState = {
  selectedSimpleDateKey: ESimpleDateKey.DAY,
};

const filtersSlice = createSlice({
  name: 'analytics/filters',
  initialState: { ...initialState },
  reducers: {
    setDateFilterAction(
      state,
      action: PayloadAction<{
        key?: ESimpleDateKey;
        startDate?: string;
        endDate?: string;
      }>
    ) {
      state.selectedSimpleDateKey = action.payload.key;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setVideoFilterAction(state, action: PayloadAction<string[] | undefined>) {
      state.videosId = action.payload;
    },
    setLinkFilterAction(state, action: PayloadAction<string[] | undefined>) {
      state.linkId = action.payload;
    },
    setUserFilterAction(state, action: PayloadAction<string[] | undefined>) {
      state.userId = action.payload;
    },
    resetFiltersAction(state) {
      state = { ...initialState };
    },
  },
});

export const {
  setDateFilterAction,
  setLinkFilterAction,
  setUserFilterAction,
  setVideoFilterAction,
} = filtersSlice.actions;
export const analyticFiltersReducer = filtersSlice.reducer;
