import { FC } from 'react';

export const Trash: FC = () => {
  return (
    <svg
      width="28"
      height="31"
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1875 2.625C26.6562 2.625 27.125 3.09375 27.125 3.5625V5.4375C27.125 5.96484 26.6562 6.375 26.1875 6.375H1.8125C1.28516 6.375 0.875 5.96484 0.875 5.4375V3.5625C0.875 3.09375 1.28516 2.625 1.8125 2.625H8.84375L9.37109 1.57031C9.60547 1.10156 10.0742 0.75 10.6016 0.75H17.3398C17.8672 0.75 18.3359 1.10156 18.5703 1.57031L19.1562 2.625H26.1875ZM3.98047 28.1133L2.75 8.25H25.25L23.9609 28.1133C23.9023 29.6367 22.6719 30.75 21.1484 30.75H6.79297C5.26953 30.75 4.03906 29.6367 3.98047 28.1133Z"
        fill="#D53939"
      />
    </svg>
  );
};
