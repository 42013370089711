import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import {
  RadioBlock,
  RadioBlockGroup,
} from '../../components/form-items/radio-block';
import { BaseModal } from '../../components/modal/base-modal';
import { EPaymentMethod } from '../../types/finance';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onNextStep: (method: EPaymentMethod) => void;
  t: TFunction<string[]>;
};

export const SelectPaymentMethodModal: FC<TProps> = ({
  open,
  setOpen,
  onNextStep,
  t,
}) => {
  const [currentMethod, setCurrentMethod] = useState<EPaymentMethod>(
    EPaymentMethod.Individual
  );

  const onConfirm = useCallback(() => {
    onNextStep(currentMethod);
    setOpen(false);
  }, [onNextStep, currentMethod]);

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={t('paymentMethodModal.title')}
      okText={t('paymentMethodModal.next')}
      onOk={onConfirm}
      t={t}
      onCancelClick={() => setOpen(false)}
    >
      <RadioBlockGroup>
        <RadioBlock
          name="payment-type"
          value={EPaymentMethod.Individual}
          checked={currentMethod === EPaymentMethod.Individual}
          onChange={setCurrentMethod}
        >
          {t('paymentMethodModal.individual')}
        </RadioBlock>
        <RadioBlock
          name="payment-type"
          value={EPaymentMethod.Legal}
          checked={currentMethod === EPaymentMethod.Legal}
          onChange={setCurrentMethod}
        >
          {t('paymentMethodModal.legal')}
        </RadioBlock>
      </RadioBlockGroup>
    </BaseModal>
  );
};
