import { css } from '@emotion/react';

export const tableStyle = (fixHeight?: boolean) => css`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  min-height: ${fixHeight ? '200px' : 'unset'};
`;

export const headerStyle = css`
  background-color: #f4f4f4;
  color: #333;
`;

export const rowStyle = (expanded: boolean) => css`
  background-color: ${expanded ? '#242424' : '#151515'};
`;

export const cellStyle = (canExpanded: boolean) => css`
  padding: 8px;
  border-top: 1px solid #2e2e2e;
  border-bottom: 1px solid #2e2e2e;
  color: #fff;
  cursor: ${canExpanded ? 'pointer' : 'unset'};
  background-color: transparent;
`;

export const sortIconStyle = (isSorted: boolean) => css`
  transform: ${isSorted ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
`;
