import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

const Wrapper = styled('div')`
  grid-column-start: 2;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  border-radius: 20px;
  height: 50px;
  padding-inline: 20px;
  gap: 65px;
`;

const Name = styled('span')`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.WHITE._200};
`;

const ColorItem = styled('div')<{ color: string }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  display: flex;
  align-items: center;
  gap: 4px;

  .color {
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 5px;
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
`;

type TItemProps = {
  name: string;
};

const ItemTypeColor: FC<{
  text: string;
  color: string;
}> = ({ text, color }) => {
  return (
    <ColorItem color={color}>
      <div className="color" />
      <span>{text}</span>
    </ColorItem>
  );
};

const GradientWrapper = styled('div')<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 10px;

  .gradient {
    background-image: ${({ color }) => color};
    width: 100px;
    height: 4px;
    border-radius: 2px;
  }

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.COLORS.BLACK._600};
  }
`;

const GradientItem: FC<{ color: string }> = ({ color }) => {
  return (
    <GradientWrapper color={color}>
      <span>0</span>
      <div className="gradient" />
      <span>100</span>
    </GradientWrapper>
  );
};

const DescriptionItem: FC<PropsWithChildren<TItemProps>> = ({
  name,
  children,
}) => {
  return (
    <ItemWrapper>
      <Name>{name}</Name>
      <ContentWrapper>{children}</ContentWrapper>
    </ItemWrapper>
  );
};

export const StatsDescription: FC = () => {
  return (
    <Wrapper>
      <DescriptionItem name="Эмоции">
        <ItemTypeColor text="Грусть" color="#4E33F5" />
        <ItemTypeColor text="Радость" color="#32C738" />
        <ItemTypeColor text="Страх" color="#F53333" />
        <ItemTypeColor text="Спокойствие" color="#B2B2B280" />
        <ItemTypeColor text="Веселье" color="#FFAC4B" />
      </DescriptionItem>
      <DescriptionItem name="Внимательность">
        <GradientItem color="linear-gradient(90deg, #32C738, #F53333)" />
      </DescriptionItem>
      <DescriptionItem name="Вовлеченность">
        <GradientItem color="linear-gradient(90deg, rgba(178, 178, 178, 0.50) 0%, #39D5C9)" />
      </DescriptionItem>
    </Wrapper>
  );
};
