import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  LS_LAYOUT_VALUE_LEY,
  TitleWithLayout,
} from '../components/common/title-with-layout';
import { PageMenu } from '../components/uploaded-videos/page-menu';
import { PlaylistsViewed } from '../components/viewed-videos/playlists-viewed';
import { VideosViewed } from '../components/viewed-videos/videos-viewed';
import { useLocalStorageValue } from '../hooks/use-local-storage-value';
import { usePlaylistHandleSave } from '../hooks/use-playlist-handle-save';
import { Paths, SecondaryPaths } from '../types/common';
import { ESortOption } from '../types/video';
import { Content } from './uploaded-videos';

const Wrapper = styled('div')`
  padding-top: 20px;
`;

export const ViewedVideos: FC = () => {
  const [layout, setLayout] = useLocalStorageValue<'col' | 'grid'>(
    'grid',
    LS_LAYOUT_VALUE_LEY
  );

  const [, setPlayListModal] = useState(false);

  const [sortOption, setSortOption] = useState<ESortOption>(ESortOption.NEW);

  const [handleSavePlaylist, operationProps, exitEditMode] =
    usePlaylistHandleSave();

  return (
    <Wrapper>
      <TitleWithLayout layout={layout} onChange={setLayout}>
        Просмотренные видео
      </TitleWithLayout>
      <PageMenu
        playlistEditMode={false}
        setPlayListModal={setPlayListModal}
        handleSavePlaylist={handleSavePlaylist}
        exitEditMode={exitEditMode}
        operationsButtonProps={operationProps}
        sortOption={sortOption}
        setSortOption={setSortOption}
        videoLink={Paths.VIEWED_VIDEOS}
        playListLink={Paths.VIEWED_PLAYLISTS}
        blockEditPlaylist
      />
      <Routes>
        <Route element={<Content layout={layout} />}>
          <Route
            path={SecondaryPaths.BASE}
            element={<VideosViewed layout={layout} sortOption={sortOption} />}
          />
          <Route
            path={SecondaryPaths.PLAYLISTS}
            element={
              <PlaylistsViewed layout={layout} sortOption={sortOption} />
            }
          />
        </Route>
      </Routes>
    </Wrapper>
  );
};
