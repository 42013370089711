import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { videoByIdReducer } from './byIdSlice';
import { createVideoReducer } from './createSlice';
import { linksListReducer } from './linksListSlice';
import { videoListReducer } from './listSlice';
import { viewedReducer } from './viewedSlice';

const videoCreateConfig = {
  key: 'video/create/persist',
  storage,
};

export const videoReducers = combineReducers({
  create: persistReducer(videoCreateConfig, createVideoReducer),
  list: videoListReducer,
  byId: videoByIdReducer,
  viewed: viewedReducer,
  linksList: linksListReducer,
});
