import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ESimpleDateKey } from '../../components/analytics/dates-list';

export type TAllStatLocal = {
  viewingDuration: number;
  watchedTillEndCount: number;
  allWatchedCount: number;
  uploadVideosCount: number;
};

export type TDynamicFilters = {
  period?: Omit<ESimpleDateKey, ESimpleDateKey.PERIOD>;
  fromDate?: string;
  toDate?: string;
  allWatchedCount: number;
  viewingDuration: number;
  uploadVideosCount: number;
  watchedTillEndCount: number;
};

export type TExcelDownloadPayload = {
  byPeriod?: Omit<ESimpleDateKey, ESimpleDateKey.PERIOD>;
  fromDate?: string;
  toDate?: string;
};

export type TDynamicData = {
  allWatchedCountDiff: string; // '0.00 (0%)'
  uploadedVideosDiff: string; // '-1.00 (-100%)'
  viewingDurationDiff: string; // '0.00 (0%)'
  watchedTillEndCountDiff: string; // '0.00 (0%)'
};

type TState = {
  filters: TDynamicFilters | undefined;
  pending: boolean;
  loaded: boolean;
  error: boolean;
  data: TDynamicData | undefined;
  allStats: TAllStatLocal;
};

const initialState: TState = {
  filters: undefined,
  pending: false,
  loaded: false,
  error: false,
  data: undefined,
  allStats: {
    viewingDuration: 0,
    watchedTillEndCount: 0,
    allWatchedCount: 0,
    uploadVideosCount: 0,
  },
};

const dynamicsSlice = createSlice({
  name: 'analytics/dynamics',
  initialState: initialState as TState,
  reducers: {
    startDynamicsAction(state) {
      state.pending = true;
    },
    finishDynamicsAction(
      state,
      action: PayloadAction<{
        data: TDynamicData;
        filters: TDynamicFilters;
        allStats: TAllStatLocal;
      }>
    ) {
      state.data = action.payload.data;
      state.filters = action.payload.filters;
      state.allStats = action.payload.allStats;
      state.loaded = true;
      state.error = false;
      state.pending = false;
    },
    errorDynamicsAction(state) {
      state.pending = false;
      state.error = true;
      state.loaded = false;
    },
  },
});

export const {
  startDynamicsAction,
  finishDynamicsAction,
  errorDynamicsAction,
} = dynamicsSlice.actions;
export const dynamicsReducer = dynamicsSlice.reducer;
