import App from 'antd/lib/app';
import { FC, Fragment, useCallback, useEffect, useRef } from 'react';

import { API } from '../../api';
import { useAppDispatch } from '../../state';
import { useViewedPlaylists } from '../../state/playlists/selectors';
import {
  viewedPlaylistEndAction,
  viewedPlaylistErrorAction,
  viewedPlaylistStartAction,
} from '../../state/playlists/viewedSlice';
import { ESortOption } from '../../types/video';
import { sortByDateField } from '../../utils/video';
import { PlaylistColItem } from '../uploaded-videos/uploaded-items/playlist-col-item';
import { PlaylistItem } from '../uploaded-videos/uploaded-items/playlist-item';

export const PlaylistsViewed: FC<{
  sortOption: ESortOption;
  layout?: 'grid' | 'col';
}> = ({ sortOption, layout = 'grid' }) => {
  const { message } = App.useApp();
  const dispatch = useAppDispatch();
  const { loaded, pending, error, data } = useViewedPlaylists();
  const pendingRef = useRef(false);

  const handleLoadData = useCallback(async () => {
    dispatch(viewedPlaylistStartAction());
    try {
      const data = await API.playlists.getViewed();
      dispatch(viewedPlaylistEndAction(data.data));
      pendingRef.current = false;
    } catch (e) {
      dispatch(viewedPlaylistErrorAction());
      message.error('Ошибка при загрузке плейлистов');
      pendingRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loaded && !pending && !error && !pendingRef.current) {
      pendingRef.current = true;
      void handleLoadData();
    }
  }, [loaded, pending, error]);

  return (
    <Fragment>
      {[...data]
        .sort((a, b) =>
          sortByDateField(a, b, 'createdAt', sortOption === ESortOption.OLD)
        )
        .map((el) =>
          layout === 'grid' ? (
            <PlaylistItem key={el.id} playListData={el} />
          ) : (
            <PlaylistColItem key={el.id} playListData={el} />
          )
        )}
    </Fragment>
  );
};
