import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { APP_LANGUAGES } from '../../constants/app-lang';

export type LangType = 'ru' | 'en';

export type TLangPickItem = { value: LangType; text: string };

export interface IAppLocaleState {
  lang: LangType;
  variants: LangType[];
}

export const appLocaleInitialState: IAppLocaleState = {
  lang: APP_LANGUAGES[0].value,
  variants: APP_LANGUAGES.map((el) => el.value),
};

const appLocaleSlice = createSlice({
  name: 'app/locale',
  initialState: { ...appLocaleInitialState } as IAppLocaleState,
  reducers: {
    setLang(state, action: PayloadAction<LangType>) {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = appLocaleSlice.actions;
export const appLocaleReducer = appLocaleSlice.reducer;
