import styled from '@emotion/styled';
import { FC } from 'react';

import { TMenuItem } from '../../../types/common';
import { Logo } from '../../icons/logo';
import { MenuItem } from './menu-item';

type TProps = {
  list: TMenuItem[];
};

const Wrapper = styled('aside')`
  position: fixed;
  left: 0;
  top: 0;
  width: 168px;
  background-color: ${(props) => props.theme.COLORS.BLACK._400};
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
    max-width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 0;
    height: 0;
  }
`;

const LogoWrapper = styled('div')`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.COLORS.BLACK._400};
  padding-bottom: 16px;

  svg {
    margin-top: 16px;
    width: 180px;
  }
`;

const MenuList = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Menu: FC<TProps> = ({ list }) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <MenuList>
        {list.map((el) => (
          <MenuItem key={el.text} menuItem={el} />
        ))}
      </MenuList>
    </Wrapper>
  );
};
