import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { CommonSelect } from './common-select';
import { SlideInput } from './slide-input';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 80px);
  grid-row-gap: 20px;
  grid-column-gap: 10px;
`;

type TProps = {
  onBaseSettingsChange?: (key: string, value: any) => void;
  settings: Record<string, any>;
};

export const BaseSettings: FC<TProps> = ({
  onBaseSettingsChange,
  settings,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const [guarantee, setGuarantee] = useState(false);
  const [attention, setAttention] = useState(false);
  const [attentionRate, setAttentionRate] = useState(25);
  const [interest, setInterest] = useState(false);
  const [interestRate, setInterestRate] = useState(25);
  const [voice, setVoice] = useState(false);
  const [voiceRate, setVoiceRate] = useState(25);
  const [, setSpeedControl] = useState(false);
  const [, setTimeControl] = useState(false);
  const [distanceControl, setDistanceControl] = useState(false);

  useEffect(() => {
    if (settings) {
      setGuarantee(settings.viewingGuarantee);
      setAttention(settings.increaseAttention);
      setAttentionRate(settings.increaseAttentionValue);
      setInterest(settings.minimumAttentionLimit);
      setInterestRate(settings.minimumAttentionLimitValue);
      setVoice(settings.volumeLimit);
      setVoiceRate(settings.volumeLimitValue);
      setSpeedControl(settings.videoSpeedControl ?? false);
      setTimeControl(settings.rewindOption ?? false);
      setDistanceControl(settings.distanceControl);
    }
  }, [settings]);

  return (
    <Wrapper>
      <CommonSelect
        value={guarantee}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('viewingGuarantee', value);
          }
        }}
        label={t('settings.view')}
      />
      <div />
      <CommonSelect
        value={attention}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('increaseAttention', value);
          }
        }}
        label={t('settings.attention.title')}
      />
      <SlideInput
        label={t('settings.attention.value')}
        min={0}
        max={100}
        value={attentionRate}
        onChange={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('increaseAttentionValue', value);
          }
        }}
      />
      <CommonSelect
        value={interest}
        label={t('settings.attention.limit.title')}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('minimumAttentionLimit', value);
          }
        }}
      />
      <SlideInput
        label={t('settings.attention.limit.value')}
        min={0}
        max={100}
        value={interestRate}
        onChange={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('minimumAttentionLimitValue', value);
          }
        }}
      />
      <CommonSelect
        value={voice}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('volumeLimit', value);
          }
        }}
        label={t('settings.volume.title')}
      />
      <SlideInput
        label={t('settings.volume.value')}
        min={0}
        max={100}
        value={voiceRate}
        onChange={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('volumeLimitValue', value);
          }
        }}
      />
      {/*<CommonSelect*/}
      {/*  value={speedControl}*/}
      {/*  setValue={(value) => {*/}
      {/*    if (onBaseSettingsChange) {*/}
      {/*      onBaseSettingsChange('videoSpeedControl', value);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  label="Контроль скорости видео"*/}
      {/*/>*/}
      {/*<div />*/}
      {/*<CommonSelect*/}
      {/*  value={timeControl}*/}
      {/*  setValue={(value) => {*/}
      {/*    if (onBaseSettingsChange) {*/}
      {/*      onBaseSettingsChange('rewindOption', value);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  label="Возможность перемотки"*/}
      {/*/>*/}
      {/*<div />*/}
      <CommonSelect
        value={distanceControl}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('distanceControl', value);
          }
        }}
        label={t('settings.distance')}
      />
    </Wrapper>
  );
};
