import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TUser } from '../../types/responses/users';

type TState = {
  data: TUser[];
  loading: boolean;
  loaded: boolean;
  errorOnLoad: boolean;
};

const initialState: TState = {
  data: [],
  loaded: false,
  errorOnLoad: false,
  loading: false,
};

const usersListSlice = createSlice({
  name: 'users/list',
  initialState: initialState,
  reducers: {
    setUsersListAction: (state, action: PayloadAction<TUser[]>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setUsersListLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setUsersListLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUsersListError: (state, action: PayloadAction<boolean>) => {
      state.errorOnLoad = action.payload;
    },
    resetUsersListState: (state) => {
      state.data = [];
      state.loaded = false;
      state.loading = false;
      state.errorOnLoad = false;
    },
  },
});

export const {
  setUsersListAction,
  setUsersListError,
  setUsersListLoaded,
  setUsersListLoading,
  resetUsersListState,
} = usersListSlice.actions;
export const usersListReducer = usersListSlice.reducer;
