import styled from '@emotion/styled';
import { Button, Space, message } from 'antd';
import { TFunction } from 'i18next';
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { resetVideoStateAction } from '../../state/video/byIdSlice';
import { useVideoSelector } from '../../state/video/selectors';
import { TLinkForm } from '../../types/responses/video';
import { EPlayerMode, ModeOptions } from '../../types/video';
import { getVideoItemPath } from '../../utils/paths';
import { BaseSettings } from '../form-items/base-settings';
import { BlockSelectable, TOption } from '../form-items/block-selectable';
import { SubtitlesArea } from '../form-items/subtitles';

const Wrapper = styled('div')`
  margin-top: 60px;
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 80px;
  padding-bottom: 60px;
  padding-right: 80px;
`;

const Title = styled('span')`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

type TSettingItemProps = {
  title?: string;
};

const SettingItem: FC<PropsWithChildren<TSettingItemProps>> = ({
  title = '',
  children,
}) => {
  return (
    <Fragment>
      <Title>{title}</Title>
      <div>{children}</div>
    </Fragment>
  );
};

const getOptions: (t: TFunction<string[]>) => TOption[] = (t) => {
  return [
    {
      label: t(ModeOptions[EPlayerMode.AdvertisingMarketing]),
      value: EPlayerMode.AdvertisingMarketing,
    },
    {
      label: t(ModeOptions[EPlayerMode.Training]),
      value: EPlayerMode.Training,
    },
    {
      label: t(ModeOptions[EPlayerMode.HealthSafety]),
      value: EPlayerMode.HealthSafety,
    },
    {
      label: t(ModeOptions[EPlayerMode.KidsMode]),
      value: EPlayerMode.KidsMode,
    },
    {
      label: t(ModeOptions[EPlayerMode.CustomSettings]),
      value: EPlayerMode.CustomSettings,
    },
  ];
};

type TProps = {
  viewMode?: 'create' | 'edit';
  videoId: string;
  linkToEdit?: string;
};

export const Settings: FC<TProps> = ({ videoId, linkToEdit }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { linksData } = useVideoSelector();

  const [mode, setMode] = useState(EPlayerMode.AdvertisingMarketing);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [subtitles, setSubtitles] = useState(false);
  const [captions, setCaptions] = useState('');

  const [settings, setSettings] = useState<Record<string, any>>({});

  const option: TOption[] = useMemo(() => getOptions(t), [t]);

  const onBaseSettingsChange = useCallback(
    (formKey: string, formValue: any) => {
      setSettings((prev) => ({
        ...prev,
        [formKey]: formValue,
      }));
    },
    []
  );

  const handleCreate = useCallback(async () => {
    if (!title) {
      void message.error(t('settings.link.required'));
      return;
    }

    const data: TLinkForm = {
      ...(settings as TLinkForm),
      title: title,
    };

    const loading = message.loading(t('settings.link.loading'), 0);

    try {
      if (!videoId) {
        loading();
        return;
      }

      await API.videos.createLink(
        {
          videoId,
        },
        data,
        description
      );

      void message.success(t('settings.link.success'));
      dispatch(resetVideoStateAction());
      navigate(getVideoItemPath(videoId));
      loading();
    } catch (e) {
      void message.error(t('settings.link.error'));
      loading();
    }
  }, [settings, videoId, title, description]);

  const handleEdit = useCallback(async () => {
    if (!title) {
      void message.error(t('settings.link.required'));
      return;
    }

    if (linkToEdit) {
      const data: TLinkForm = {
        ...(settings as TLinkForm),
        title,
        playerMode: mode,
        captionsEnabled: subtitles,
        captions,
      };

      const loading = message.loading(t('settings.link.loading'), 0);

      try {
        if (!videoId) {
          loading();
          return;
        }

        await API.videos.editLink(
          {
            videoId,
          },
          data,
          linkToEdit,
          description
        );

        void message.success(t('settings.link.success'));
        dispatch(resetVideoStateAction());
        navigate(getVideoItemPath(videoId));
        loading();
      } catch (e) {
        void message.error(t('settings.link.error'));
        loading();
      }
    }
  }, [linkToEdit, settings, title, description, mode, subtitles, captions]);

  useEffect(() => {
    if (linkToEdit && linksData) {
      const linkToCopy = linksData.find((el) => el.id === linkToEdit);

      if (linkToCopy) {
        setSettings(() => ({
          viewingGuarantee: linkToCopy.videoSetting.viewingGuarantee,
          volumeLimit: linkToCopy.videoSetting.volumeLimit,
          volumeLimitValue: linkToCopy.videoSetting.volumeLimitValue,
          increaseAttention: linkToCopy.videoSetting.increaseAttention,
          increaseAttentionValue:
            linkToCopy.videoSetting.increaseAttentionValue,
          rewindOption: linkToCopy.videoSetting.rewindOption,
          distanceControl: linkToCopy.videoSetting.distanceControl,
          videoSpeedControl: linkToCopy.videoSetting.videoSpeedControl,
          videoRecommendations: linkToCopy.videoSetting.videoRecommendations,
          minimumAttentionLimit: linkToCopy.videoSetting.minimumAttentionLimit,
          minimumAttentionLimitValue:
            linkToCopy.videoSetting.minimumAttentionLimitValue,
          attentionLimit: linkToCopy.videoSetting.attentionLimit,
        }));

        setTitle(linkToCopy.videoSetting.title);
        setDescription(linkToCopy.description ?? '');
        setMode(linkToCopy.videoSetting.playerMode);
        setSubtitles(linkToCopy.videoSetting.captionsEnabled);
        setMode(linkToCopy.videoSetting.playerMode);
        setCaptions(linkToCopy.videoSetting.captions);
      }
    }
  }, [linkToEdit, linksData]);

  useEffect(() => {
    window.location.hash = 'settings';
    setTimeout(() => {
      window.location.hash = '';
    }, 100);
  }, [linksData]);

  return (
    <Wrapper id="settings">
      <SettingItem title={t('settings.general')}>
        <SubtitlesArea
          isInput
          text={title}
          setText={setTitle}
          placeholder={t('settings.link.title')}
        />
        <div
          style={{
            marginTop: 20,
          }}
        >
          <SubtitlesArea
            text={description}
            setText={setDescription}
            placeholder={t('settings.link.description')}
          />
        </div>
      </SettingItem>
      <SettingItem title={t('settings.player.title')}>
        <BlockSelectable
          options={option}
          value={mode}
          setValue={setMode as any}
        />
      </SettingItem>
      <SettingItem title={t('settings.title')}>
        <BaseSettings
          settings={settings}
          onBaseSettingsChange={onBaseSettingsChange}
        />
      </SettingItem>
      {/*<SettingItem title="Субтитры">*/}
      {/*  <Subtitles*/}
      {/*    value={subtitles}*/}
      {/*    setValue={setSubtitles}*/}
      {/*    text={captions}*/}
      {/*    setText={setCaptions}*/}
      {/*  />*/}
      {/*</SettingItem>*/}
      <SettingItem>
        <Space>
          <Button
            type="primary"
            size="large"
            onClick={linkToEdit ? handleEdit : handleCreate}
          >
            {!!linkToEdit ? t('buttons.link.change') : t('buttons.link.create')}
          </Button>
          <NavLink to={getVideoItemPath(videoId)}>
            <Button size="large">{t('buttons.cancel')}</Button>
          </NavLink>
        </Space>
      </SettingItem>
    </Wrapper>
  );
};
