import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { boolToText, textToBool } from '../../../utils/common';
import { getVideoLinkStatisticPath } from '../../../utils/paths';
import { ExpandIcon } from '../../icons/expand-icon';
import StatIcon from '../../icons/stat-icon';

type TProps = {
  canBeExpanded: boolean;
  expanded: boolean;
  label: string;
  childIndex: number;
  linkId?: string;
  videoId?: string;
  rowHovered: boolean;
  linkStatTooltip: string;
  email?: string;
};

const NameContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 240px;
`;

const Label = styled('span')`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const EmailText = styled('span')`
  opacity: 0.6;
  font-size: smaller;
`;

const ToLinkStatWrapper = styled(NavLink)<{
  isOpen: 'true' | 'false';
}>`
  cursor: pointer;
  text-decoration: none;
  padding: 4px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  border-radius: 4px;
  opacity: ${({ isOpen }) => (textToBool(isOpen) ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (textToBool(isOpen) ? 'unset' : 'none')};
  transition: opacity 0.3s ease-out;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Wrapper = styled('div')<{
  childIndex: number;
}>`
  display: flex;
  padding-left: ${({ childIndex }) => childIndex * 24}px;

  ${NameContent} {
    flex: 1;
  }
`;

const ArrowWrapper = styled('div')<{
  expanded?: 'true' | 'false';
}>`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  svg {
    width: 100%;
    object-fit: contain;
    transform: rotate(
      ${({ expanded }) => (textToBool(expanded) ? '0' : '180deg')}
    );
  }
`;

export const TableLabelComponent: FC<TProps> = ({
  canBeExpanded,
  expanded,
  label,
  childIndex,
  linkId,
  videoId,
  rowHovered,
  linkStatTooltip,
  email,
}) => {
  return (
    <Wrapper childIndex={childIndex}>
      {canBeExpanded && (
        <ArrowWrapper expanded={boolToText(expanded)}>
          <ExpandIcon />
        </ArrowWrapper>
      )}
      <NameContent>
        <Label>{label}</Label>
        {!!email && <EmailText>{email}</EmailText>}
      </NameContent>
      {linkId && videoId && (
        <Tooltip title={linkStatTooltip} placement="right">
          <ToLinkStatWrapper
            to={getVideoLinkStatisticPath(videoId, linkId)}
            isOpen={boolToText(rowHovered)}
          >
            <StatIcon />
          </ToLinkStatWrapper>
        </Tooltip>
      )}
    </Wrapper>
  );
};
