import styled from '@emotion/styled';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { LangSwitcher } from '../components/form-items/lang-switcher';
import { APP_LANGUAGES } from '../constants/app-lang';

const Wrapper = styled('div')`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled('div')`
  padding: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
`;

export const AuthLayout: FC = () => {
  return (
    <Wrapper>
      {APP_LANGUAGES.length > 1 && <LangSwitcher />}
      <Inner>
        <Outlet />
      </Inner>
    </Wrapper>
  );
};
