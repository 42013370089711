import { combineReducers } from '@reduxjs/toolkit';

import { playlistCurrentReducer } from './currentSlice';
import { playListAllReducer } from './listSlice';
import { viewedPlaylistReducers } from './viewedSlice';

export const playlistsReducer = combineReducers({
  list: playListAllReducer,
  current: playlistCurrentReducer,
  viewed: viewedPlaylistReducers,
});
