import styled from '@emotion/styled';
import Switch from 'antd/lib/switch';
import { FC, HTMLAttributes } from 'react';

import { FormBlock } from '../common/form-block';

type TProps = {
  value: boolean;
  setValue?: (value: boolean) => void;
  label: string;
};

const Label = styled('span')`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

const Wrapper = styled(FormBlock)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export const CommonSelect: FC<TProps & HTMLAttributes<HTMLDivElement>> = ({
  value,
  setValue,
  label,
  ...props
}) => {
  return (
    <Wrapper
      onClick={() => {
        if (setValue) {
          setValue(!value);
        }
      }}
      {...props}
    >
      <Label>{label}</Label>
      <Switch checked={value} />
    </Wrapper>
  );
};
