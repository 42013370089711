import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Popconfirm } from 'antd';
import App from 'antd/lib/app';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { API } from '../api';
import { PageBackButton } from '../components/common/page-back-button';
import { DescriptionEdit } from '../components/video-page/description-edit';
import { DescriptionPreview } from '../components/video-page/description-preview';
import { LinksList } from '../components/video-page/links/links-list';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useLoadVideo } from '../hooks/use-load-video';
import {
  VIDEO_LINK_PARAM_NAME,
  useVideoPathId,
} from '../hooks/use-video-path-id';
import { LocaleKeys } from '../locale';
import mockVideo from '../mocks/mock-video.mp4';
import { useAppDispatch } from '../state';
import { resetVideoStateAction } from '../state/video/byIdSlice';
import { createVideoSetSecondFormAction } from '../state/video/createSlice';
import { resetVideoListState } from '../state/video/listSlice';
import {
  useCreateVideoSelector,
  useVideoSelector,
} from '../state/video/selectors';
import { Paths, SecondaryPaths } from '../types/common';
import { CreateLink } from './create-link';

const Wrapper = styled('div')`
  padding-bottom: 60px;
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 5fr 2fr;
  column-gap: 60px;
  margin-top: 80px;
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

const LoadingWrapper = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  min-height: 340px;
`;

const RightSide = styled('div')`
  padding-right: 40px;
  max-width: 340px;
  overflow: hidden;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
`;

export const Video: FC = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const id = useVideoPathId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { data, loading } = useVideoSelector();
  const navigate = useNavigate();

  const {
    secondStage: { form },
  } = useCreateVideoSelector();

  const { message } = App.useApp();

  useLoadVideo();

  const [descriptionEditMode, setDescriptionEditMode] = useState(false);

  const handleSwitchDescriptionMode = useCallback(() => {
    setDescriptionEditMode((prev) => {
      return !prev;
    });
  }, []);

  const handleResetDescriptionMode = useCallback(() => {
    setDescriptionEditMode(false);
  }, []);

  const handleDeleteVideo = useCallback(async () => {
    if (!id) {
      message.warning(t('video.deleting.warning'));
      return;
    }

    const loading = message.loading(t('video.deleting.loading'), 0);

    try {
      await API.videos.deleteById(id);

      loading();
      message.success(t('video.deleting.success'));
      dispatch(resetVideoListState());
      navigate(Paths.UPLOADED_VIDEOS);
    } catch (e) {
      loading();
      message.error(t('video.deleting.error'));
    }
  }, [id, t]);

  const handleChangeTitle = useCallback(async () => {
    if (!id || !form.title || !form.description) {
      return;
    }

    const loading = message.loading(t('video.changing.loading'), 0);

    try {
      await API.videos.editVideo(
        {
          title: form.title,
          description: form.description,
        },
        id
      );

      handleSwitchDescriptionMode();
      loading();
      message.success(t('video.changing.success'));
      dispatch(resetVideoStateAction());
      dispatch(resetVideoListState());
    } catch (e) {
      loading();
      message.error(t('video.changing.error'));
    }
  }, [id, form, t]);

  useEffect(() => {
    if (data && descriptionEditMode) {
      dispatch(
        createVideoSetSecondFormAction({
          title: data.title,
          description: data.description,
        })
      );
    }
  }, [data, descriptionEditMode]);

  return (
    <Wrapper>
      <PageBackButton text={t('buttons.goBack')} />
      {loading ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <VideoWrapper>
            <VideoPlayer
              videoSrc={data?.originUrls ?? mockVideo}
              ref={videoRef}
              preview={
                data?.videoPreview?.url
                  ? {
                      url: data?.videoPreview?.url,
                      alt: data?.title ?? t('statistics.noName'),
                    }
                  : undefined
              }
            />
            <TimeLineControl ref={videoRef} />
          </VideoWrapper>
          <RightSide>
            {descriptionEditMode ? (
              <DescriptionEdit isCreateMode />
            ) : (
              <DescriptionPreview
                title={data?.title ?? t('statistics.noName')}
                content={
                  data?.description ?? t('statistics.link.noDescription')
                }
              />
            )}
            <ButtonsWrapper>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  if (descriptionEditMode) {
                    void handleChangeTitle();
                  } else {
                    handleSwitchDescriptionMode();
                  }
                }}
              >
                {descriptionEditMode
                  ? t('buttons.save.video')
                  : t('buttons.change')}
              </Button>
              {descriptionEditMode ? (
                <Button size="large" onClick={handleResetDescriptionMode}>
                  {t('buttons.cancel')}
                </Button>
              ) : (
                <Popconfirm
                  title={t('video.deleting.title')}
                  description={t('video.deleting.description')}
                  onConfirm={handleDeleteVideo}
                  okText={t('buttons.deleting.success')}
                  cancelText={t('buttons.cancel')}
                  placement="bottom"
                  okButtonProps={{
                    danger: true,
                    type: 'primary',
                  }}
                >
                  <Button type="primary" size="large" danger>
                    {t('buttons.delete')}
                  </Button>
                </Popconfirm>
              )}
            </ButtonsWrapper>
          </RightSide>
        </ContentWrapper>
      )}
      <Routes>
        <Route path={`/`} element={<LinksList />} />
        <Route
          path={`${SecondaryPaths.CREATE_LINK}`}
          element={<CreateLink videoId={id ?? ''} />}
        />
        <Route
          path={`${SecondaryPaths.EDIT_LINK}/:${VIDEO_LINK_PARAM_NAME}`}
          element={<CreateLink isEditMode videoId={id ?? ''} />}
        />
        <Route
          path={`${SecondaryPaths.STATISTICS}/:${VIDEO_LINK_PARAM_NAME}`}
          element={<CreateLink isEditMode videoId={id ?? ''} />}
        />
      </Routes>
    </Wrapper>
  );
};
