export function extractStats(input: string): [number, number] {
  const regex = /^([-]?\d+\.?\d*) \(([-]?\d+).?([-]?\d+)?%\)$/;
  const match = input.match(regex);

  if (match) {
    const number = parseFloat(match[1]);
    const percentage = parseFloat(match[2]);
    return [number, percentage];
  } else {
    throw new Error('Input string format is incorrect');
  }
}
