import styled from '@emotion/styled';
import { Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { TMenuItem } from '../../../types/common';
import { boolToText, textToBool } from '../../../utils/common';

type TProps = {
  menuItem: TMenuItem;
};

const Wrapper = styled('li')`
  height: 50px;
`;

const Inner = styled(NavLink)<{
  active: 'true' | 'false';
}>`
  padding-inline: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;

  background-color: ${(props) =>
    textToBool(props.active) ? props.theme.COLORS.BLACK._300 : ''};

  svg path {
    fill: ${(props) =>
      textToBool(props.active) ? props.theme.COLORS.WHITE._100 : ''};
  }
`;

const Text = styled(Typography.Text)`
  color: ${(props) => props.theme.COLORS.WHITE._100};
  display: inline-block;
  margin-left: 16px;
  font-size: 16px;
`;

const InnerDisabled = styled('div')`
  padding-inline: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: not-allowed;
  user-select: none;

  ${Text} {
    color: ${(props) => props.theme.COLORS.BLACK._700};
  }
`;

const IcoWrapper = styled('div')`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MenuItem: FC<TProps> = ({ menuItem }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const paths = location.pathname
      .split('/')
      .filter(Boolean)
      .map((el) => `/${el}`);

    if (paths.length === 0) {
      paths.push('/');
    }

    let result = false;

    if (menuItem) {
      if (menuItem.path === location.pathname) {
        result = true;
      }

      if (
        Array.isArray(menuItem.activePathTrigger) &&
        menuItem.activePathTrigger.some((el) => paths.includes(el))
      ) {
        result = true;
      }

      if (
        menuItem.activePathTrigger === location.pathname ||
        (Array.isArray(menuItem.activePathTrigger) &&
          menuItem.activePathTrigger.some((el) => location.pathname === el))
      ) {
        result = true;
      }
    }

    setIsActive(() => result);
  }, [location, menuItem]);

  return (
    <Wrapper>
      {menuItem.disabled ? (
        <InnerDisabled>
          <IcoWrapper>
            <menuItem.Icon />
          </IcoWrapper>
          <Text>{menuItem.text}</Text>
        </InnerDisabled>
      ) : (
        <Inner to={menuItem.path} active={boolToText(isActive)}>
          <IcoWrapper>
            <menuItem.Icon />
          </IcoWrapper>
          <Text>{menuItem.text}</Text>
        </Inner>
      )}
    </Wrapper>
  );
};
