import styled from '@emotion/styled';

export const BaseBlock = styled('div')`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  padding-bottom: 10px;
  position: relative;
`;

export const BlockBaseTitle = styled('div')`
  margin-top: 16px;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  padding-bottom: 16px;

  svg {
    width: 34px;
    height: auto;
  }
`;

export const BlockBaseText = styled('div')`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  text-align: center;
  padding-inline: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
`;
