import { ConfigProvider } from 'antd';
import ant_en from 'antd/lib/locale/en_US';
import ant_ru from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocaleSelector } from '../state/locale/selector';

export const LocaleService: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { i18n } = useTranslation();
  const appLocale = useLocaleSelector();

  const antLocale = useMemo(() => {
    switch (appLocale.lang) {
      case 'en':
        return ant_en;
      case 'ru':
        return ant_ru;
      default:
        return ant_ru;
    }
  }, [appLocale]);

  useEffect(() => {
    if (appLocale.lang) {
      dayjs.locale(appLocale.lang);
    }
  }, [appLocale]);

  useEffect(() => {
    void i18n.changeLanguage(appLocale.lang);
  }, [appLocale]);

  return <ConfigProvider locale={antLocale}>{children}</ConfigProvider>;
};
