import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { textToBool } from '../../../utils/common';

export const PreviewWrapper = styled('div')`
  padding-top: 60%;
  position: relative;
  height: 0;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
`;

export const Preview = styled(PreviewWrapper)`
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateY(-50%) translateX(-50%);
    transition:
      width 0.3s ease-out,
      height 0.3s ease-out;
  }
`;

export const Wrapper = styled(NavLink)<{
  resizable?: 'true' | 'false';
}>`
  display: flex;
  flex-direction: column;

  .name {
    transition: color 0.3s ease-out;
  }

  :hover {
    .name {
      color: ${({ theme }) => theme.COLORS.ACCENT._200};
    }

    ${Preview.toString()} {
      img {
        width: ${({ resizable }) => (textToBool(resizable) ? '110%' : '100%')};
        height: ${({ resizable }) => (textToBool(resizable) ? '110%' : '100%')};
      }
    }
  }
`;

export const WrapperSelectable = styled('div')<{
  selectedVideo?: 'true' | 'false';
  resizable?: 'true' | 'false';
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${({ selectedVideo }) => (textToBool(selectedVideo) ? 1 : 0.5)};

  .name {
    transition: color 0.3s ease-out;
  }

  :hover {
    .name {
      color: ${({ theme }) => theme.COLORS.ACCENT._200};
    }

    ${Preview.toString()} {
      img {
        width: ${({ resizable }) => (textToBool(resizable) ? '110%' : '100%')};
        height: ${({ resizable }) => (textToBool(resizable) ? '110%' : '100%')};
      }
    }
  }
`;

export const TimingWrapper = styled('div')`
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 2;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  padding: 5px 6px;
  border-radius: 10px;
`;

export const Title = styled('div')`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.COLORS.WHITE._300};
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Footer = styled('div')`
  display: flex;
  gap: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

export const SelectWrapper = styled('div')<{ placement?: 'left' | 'right' }>`
  position: absolute;
  top: 15px;
  left: ${({ placement }) => (placement === 'left' ? '15px' : 'unset')};
  right: ${({ placement }) => (placement === 'left' ? 'unset' : '15px')};
  bottom: 15px;
  z-index: 2;
`;

export const SelectInner = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
