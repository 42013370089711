import styled from '@emotion/styled';

export const FormBlock = styled('div')`
  border-radius: 20px;
  height: 80px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._100_00};
  padding-inline: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
  text-align: center;
`;
