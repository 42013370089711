import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistedStore, store } from '../state';

export const StateService: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        {children}
      </PersistGate>
    </Provider>
  );
};
