import styled from '@emotion/styled';
import { Button } from 'antd';
import { TFunction } from 'i18next';
import { FC, Fragment, ReactNode, memo } from 'react';

import { LangType } from '../../state/locale/localeSlice';
import {
  EFinancePeriod,
  ETariffFeatureNames,
  TFinanceClear,
  TariffFeatures,
} from '../../types/finance';
import { boolToText, textToBool } from '../../utils/common';
import { NBSP } from '../../utils/text-formatters';

const Wrapper = styled.div<{
  special: 'true' | 'false';
}>`
  background-color: ${({ theme, special }) =>
    textToBool(special)
      ? theme.COLORS.ACCENT._600_dark
      : theme.COLORS.BLACK._500};
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  border-radius: 16px;
  padding: 16px;
  max-width: 350px;
  display: flex;
  flex-direction: column;

  button {
    margin-top: auto;
  }
`;

const PlanName = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.ACCENT._200};

  @media (min-width: 1200px) {
    font-size: 22px;
  }
`;

const PlanType = styled.div`
  font-size: 13px;
  line-height: 110%;
  margin-bottom: 10px;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK._900};
`;

const Sum = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
`;

const PerPeriod = styled.span`
  font-size: 11px;
  font-weight: 600;
`;

const PlanFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 120%;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 28px;
  }
`;

const FeatureLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.BLACK._900};
`;

const FeatureValue = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

type TFeatureProps<T extends ETariffFeatureNames> = {
  label: T;
  value: TariffFeatures[T];
  t: TFunction<string[]>;
};

const INFINITE_SYMBOL = '∞';

const PlanFeature: <T extends ETariffFeatureNames>(
  props: TFeatureProps<T>
) => ReactNode = ({ value, label, t }) => {
  let valueContent = '';

  switch (true) {
    case label === ETariffFeatureNames.storageSize:
      const storageSize =
        value as TariffFeatures[ETariffFeatureNames.storageSize];
      valueContent =
        storageSize.size === 'infinite'
          ? INFINITE_SYMBOL
          : `${storageSize.size} ${t(
              `tariffTextConstants.storageSize.${storageSize.unit}`
            )}`;
      break;
    case typeof value === 'boolean':
      valueContent = t(`tariffTextConstants.bool.${value ? 'yes' : 'no'}`);
      break;
    case label === ETariffFeatureNames.linksCount:
      const linksCount =
        value as TariffFeatures[ETariffFeatureNames.linksCount];
      valueContent =
        linksCount === 'infinite'
          ? INFINITE_SYMBOL
          : linksCount.toLocaleString();
      break;
    case label === ETariffFeatureNames.viewAnalytics:
      const viewAnalytics =
        value as TariffFeatures[ETariffFeatureNames.viewAnalytics];
      valueContent =
        viewAnalytics === 'infinite'
          ? INFINITE_SYMBOL
          : viewAnalytics.toLocaleString() +
            NBSP +
            t('tariffTextConstants.record');
      break;
    case label === ETariffFeatureNames.bandwidth:
      const bandwidth = value as TariffFeatures[ETariffFeatureNames.bandwidth];
      valueContent =
        bandwidth === 'infinite'
          ? INFINITE_SYMBOL
          : bandwidth.toLocaleString() +
            NBSP +
            t('tariffTextConstants.storageSize.tb') +
            '/' +
            t('tariffTextConstants.monthFull');
      break;
    case label === ETariffFeatureNames.support:
      const support = value as TariffFeatures[ETariffFeatureNames.support];
      valueContent = support
        .map(
          (supItem, index, arr) =>
            t(`tariffTextConstants.support.${supItem}`) +
            (index === arr.length - 2 && arr.length > 1
              ? ` ${t('tariffTextConstants.support.divider')} `
              : index !== arr.length - 1
              ? ', '
              : '')
        )
        .join('');
  }

  return (
    <PlanFeatureWrapper>
      <FeatureLabel>{t(`planFeatures.${label}`)}</FeatureLabel>
      <FeatureValue>{valueContent}</FeatureValue>
    </PlanFeatureWrapper>
  );
};

type TProps = {
  planInfo: TFinanceClear;
  lang: LangType;
  period: EFinancePeriod;
  t: TFunction<string[]>;
  onSelect?: () => void;
  special?: boolean;
  current?: boolean;
};

export const TariffPlan: FC<TProps> = memo(
  ({
    planInfo: { name, type, price, features },
    lang,
    t,
    period,
    special,
    current,
    onSelect,
  }) => {
    const buttonText = current
      ? t('tariffTextConstants.current')
      : price !== 'need-request'
      ? t('tariffTextConstants.pay')
      : t('tariffTextConstants.sendRequest');

    return (
      <Wrapper special={boolToText(special)}>
        <PlanName>{t(`plan.${name}`)}</PlanName>
        <PlanType>
          {t('tariffTextConstants.type')} {type}
        </PlanType>
        <PriceWrapper>
          {price !== 'need-request' ? (
            <Fragment>
              <Sum>{price}</Sum>
              {NBSP}
              <PerPeriod>
                {lang === 'ru' ? '₽' : '$'}/{t(`tariffTextConstants.${period}`)}
              </PerPeriod>
            </Fragment>
          ) : (
            <Sum>{t('tariffTextConstants.needRequest')}</Sum>
          )}
        </PriceWrapper>
        {features.map((el) => (
          <PlanFeature key={el.name} value={el.value} label={el.name} t={t} />
        ))}
        <Button
          type={current ? 'default' : 'primary'}
          block
          disabled={current}
          onClick={current ? undefined : onSelect}
        >
          {buttonText}
        </Button>
      </Wrapper>
    );
  }
);
