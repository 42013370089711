import styled from '@emotion/styled';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { PageTitle } from './page-title';
import { ProfilePopover } from './profile-popover';
import { UserAvatar } from './user-avatar';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
`;

const RightWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Avatar = styled(UserAvatar)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-out;

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }

  .header-avatar {
    width: 16px;
    height: 16px;
  }
`;

type TProps = {
  rightAddon?: ReactNode;
} & PropsWithChildren;

export const PageHeader: FC<TProps> = ({ children, rightAddon }) => {
  return (
    <Wrapper>
      <PageTitle>{children}</PageTitle>
      <RightWrapper>
        {rightAddon}
        <ProfilePopover>
          <Avatar imgClassName="header-avatar" />
        </ProfilePopover>
      </RightWrapper>
    </Wrapper>
  );
};
