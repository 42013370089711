import { Form, Input, Modal } from 'antd';
import App from 'antd/lib/app';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { useUserAccountSelector } from '../../state/auth/selectors';
import {
  TFirstStage,
  createVideoNextStageAction,
  createVideoSetFirstStageAction,
} from '../../state/video/createSlice';
import { TUploadByLinkResponse } from '../../types/responses/video';
import { URL_REGEX } from '../../utils/regex';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  withSaving: boolean;
};

export type TLinkForm = {
  url: string;
};

export const UploadWithLinkModal: FC<TProps> = ({
  open,
  setOpen,
  withSaving,
}) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const account = useUserAccountSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const handleSaveStage = useCallback(
    (
      data: Partial<TFirstStage> & {
        size?: number;
      }
    ) => {
      dispatch(createVideoSetFirstStageAction(data));
    },
    []
  );

  const onFormFinish = async (values: TLinkForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    try {
      setLoading(true);

      let videoItem: TUploadByLinkResponse;

      if (withSaving) {
        videoItem = (await API.videos.uploadWithUrl(values.url)).data;
      } else {
        videoItem = (await API.videos.addWithoutSave(values.url)).data;
      }

      handleSaveStage({
        videoDuration: videoItem.lengthSeconds,
        uploadResponse: {
          originUrls: videoItem.Location,
          s3Keys: videoItem.Key,
        },
        uploaded: true,
        pending: false,
        filled: true,
        size: videoItem.size,
      });
      dispatch(createVideoNextStageAction());

      message.success(t('withLinkUpload.success'));
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('withLinkUpload.error'));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        withSaving
          ? t('withLinkUpload.title')
          : t('withLinkUpload.titleWithoutSaving')
      }
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFormFinish}>
        <Form.Item
          name="url"
          label={t('withLinkUpload.urlLabel')}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            {
              pattern: URL_REGEX,
              message: t('rules.urlInvalid'),
            },
          ]}
        >
          <Input
            className="themed"
            placeholder={t('withLinkUpload.urlPlaceholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
