import { useParams } from 'react-router-dom';

export const VIDEO_ID_PARAM_NAME = 'id';

export const VIDEO_LINK_PARAM_NAME = 'linkId';

export function useVideoPathId(): string | undefined {
  const params = useParams<{
    [VIDEO_ID_PARAM_NAME]: string;
  }>();

  return params[VIDEO_ID_PARAM_NAME];
}

export function useVideoLinkPathId(): string | undefined {
  const params = useParams<{
    [VIDEO_LINK_PARAM_NAME]: string;
  }>();

  return params[VIDEO_LINK_PARAM_NAME];
}
