import type { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { MiddlewareAPI } from 'redux';

import { logoutAction, setAuthErrorAction } from '../auth/slice';
import { AppDispatch } from '../index';
import { RootCombine } from '../root';

function isPayloadApiAction(
  action: unknown | PayloadAction
): action is PayloadAction {
  return (
    (action as PayloadAction).type !== undefined &&
    (action as PayloadAction).payload !== undefined
  );
}

const authMiddleware: Middleware =
  (api: MiddlewareAPI<AppDispatch, RootCombine>) => (next) => (action) => {
    if (isPayloadApiAction(action)) {
      if (action.type === setAuthErrorAction.type) {
        api.dispatch(logoutAction());
      }
    }

    return next(action);
  };

export default authMiddleware;
