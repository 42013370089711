import styled from '@emotion/styled';
import { Empty } from 'antd';
import { FC } from 'react';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  right: -6px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

type TProps = {
  description?: string;
};

export const TableEmpty: FC<TProps> = ({ description }) => {
  return (
    <Wrapper>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={description ?? 'Данные отсутствуют'}
      />
    </Wrapper>
  );
};
