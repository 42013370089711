import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TListedPlaylist } from '../../types/responses/video';

type TState = {
  loaded: boolean;
  pending: boolean;
  error: boolean;
  data: TListedPlaylist | undefined;
};

const initialState: TState = {
  loaded: false,
  pending: false,
  error: false,
  data: undefined,
};

const currentSlice = createSlice({
  name: 'playlist/current',
  initialState: initialState as TState,
  reducers: {
    startLoading: (state) => {
      state.loaded = false;
      state.pending = true;
      state.error = false;
    },
    finishLoading: (state, action: PayloadAction<TListedPlaylist>) => {
      state.loaded = true;
      state.pending = false;
      state.error = false;
      state.data = action.payload;
    },
    errorLoading: (state) => {
      state.loaded = false;
      state.pending = false;
      state.error = true;
    },
    editPlayList: (state, action: PayloadAction<TListedPlaylist>) => {
      state.data = action.payload;
    },
    resetState: (state) => {
      state.data = undefined;
      state.loaded = false;
      state.pending = false;
      state.error = false;
    },
  },
});

export const playlistCurrentActions = currentSlice.actions;
export const playlistCurrentReducer = currentSlice.reducer;
