import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const AddVideo: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4C15.0833 4 16 4.92813 16 6.025V18.2172C16 19.3141 15.0833 20.2 14 20.2H1.95833C0.875 20.2 0 19.3141 0 18.2172V6.025C0 4.92813 0.875 4 1.95833 4H14ZM21.875 5.60313C22.75 5.0125 24 5.64531 24 6.7V17.5422C24 18.5969 22.75 19.2297 21.875 18.6391L17.3333 15.4328V8.80938L21.875 5.60313Z"
        fill={theme.COLORS.WHITE._100}
        className="primary"
      />
      <path
        d="M11.4286 11.1429C11.7321 11.1429 12 11.4107 12 11.7143V12.2857C12 12.6071 11.7321 12.8571 11.4286 12.8571H8.85714V15.4286C8.85714 15.75 8.58929 16 8.28571 16H7.71429C7.39286 16 7.14286 15.75 7.14286 15.4286V12.8571H4.57143C4.25 12.8571 4 12.6071 4 12.2857V11.7143C4 11.4107 4.25 11.1429 4.57143 11.1429H7.14286V8.57143C7.14286 8.26786 7.39286 8 7.71429 8H8.28571C8.58929 8 8.85714 8.26786 8.85714 8.57143V11.1429H11.4286Z"
        fill={theme.COLORS.BLACK._700}
        className="secondary"
      />
    </svg>
  );
};

export default withTheme(AddVideo);
