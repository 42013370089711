import { FC } from 'react';

export const StatIncreaseMarker: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6266 12.5L1.33665 12.5C0.164007 12.5 -0.450235 11.0719 0.387369 10.2481L7.03236 3.71194C7.59077 3.16269 8.42837 3.16269 8.93094 3.71194L15.5759 10.2481C16.4694 11.0719 15.8551 12.5 14.6266 12.5Z"
        fill="#39D5C9"
      />
    </svg>
  );
};
