import styled from '@emotion/styled';
import { Dropdown, MenuProps } from 'antd';
import React, { FC, useMemo } from 'react';

import enIco from '../../assets/en-lang-ico.svg';
import ruIco from '../../assets/ru-lang-ico.svg';
import { APP_LANGUAGES } from '../../constants/app-lang';
import { useAppDispatch } from '../../state';
import { LangType, setLang } from '../../state/locale/localeSlice';
import { useLocaleSelector } from '../../state/locale/selector';

const LangItem = styled('div')`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  gap: 6px;
`;

const getIcoPathByLocale = (locale?: LangType) => {
  switch (locale) {
    case 'en':
      return enIco;
    case 'ru':
      return ruIco;
    default:
      return enIco;
  }
};

const Wrapper = styled('div')`
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;

  .inner {
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
`;

export const LangSwitcher: FC = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useLocaleSelector();

  const options: MenuProps['items'] = useMemo(() => {
    return APP_LANGUAGES.map((el) => ({
      key: el.text,
      label: (
        <LangItem
          onClick={() => {
            dispatch(setLang(el.value));
          }}
        >
          <img src={getIcoPathByLocale(el.value)} alt="" /> {el.text}
        </LangItem>
      ),
    }));
  }, []);

  return (
    <Dropdown menu={{ items: options }} placement={'bottomRight'}>
      <Wrapper>
        <div className="inner">
          <img src={getIcoPathByLocale(currentLanguage.lang)} alt="" />
        </div>
      </Wrapper>
    </Dropdown>
  );
};
