import styled from '@emotion/styled';
import { Button, Form, Input, Space, Typography } from 'antd';
import App from 'antd/lib/app';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { API } from '../api';
import { TUseFormEnterProps, useFormEnterPress } from '../hooks/forms';
import { useResetPasswordTokenPath } from '../hooks/use-reset-token-path';
import { LocaleKeys } from '../locale';
import { Paths } from '../types/common';
import { TForgotPasswordCompleteForm } from '../types/users';
import { GET_PASSWORD_BASE_RULES_FORM } from '../utils/forms';

const Title = styled(Typography.Title)`
  color: ${({ theme }) => theme.COLORS.WHITE._100} !important;
  margin: 0;
  margin-bottom: 16px !important;
`;

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  flex: 1;
`;

export const ForgotPasswordComplete: FC = () => {
  const { t } = useTranslation([LocaleKeys.LOGIN]);
  const { message } = App.useApp();
  const [form] = Form.useForm<TForgotPasswordCompleteForm>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = useResetPasswordTokenPath();

  useEffect(() => {
    if (!token) {
      navigate(Paths.BASE);
    }
  }, [token]);

  const onFormSubmit = useCallback(
    async (values: TForgotPasswordCompleteForm) => {
      if (!token) {
        message.error(t('passwordComplete.error'));
        return;
      }

      setLoading(true);
      try {
        await API.auth.forgotPasswordComplete({
          ...values,
          restoreLinkId: token,
        });

        message.success(t('passwordComplete.changing.success'));
        setLoading(false);
        navigate(Paths.LOGIN);
      } catch (e) {
        message.error(t('passwordComplete.changing.error'));
        setLoading(false);
      }
    },
    [token, t]
  );

  const formEnterProps: TUseFormEnterProps<typeof form.submit> = useMemo(
    () => ({
      submitFunc: form.submit,
    }),
    []
  );

  useFormEnterPress(formEnterProps);

  return (
    <div>
      <Title level={4}>{t('passwordComplete.title')}</Title>
      <Form
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        form={form}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="newPassword"
          label={t('passwordComplete.newPassword')}
          className="themed"
          rules={[...GET_PASSWORD_BASE_RULES_FORM(t)]}
        >
          <Input.Password
            className="themed"
            style={{
              width: '100%',
            }}
            type="new-password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label={t('passwordComplete.repeatPassword')}
          dependencies={['newPassword']}
          className="themed"
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('passwordComplete.compareError'))
                );
              },
            }),
          ]}
        >
          <Input.Password
            className="themed"
            style={{
              width: '100%',
            }}
            type="new-password"
          />
        </Form.Item>
      </Form>
      <StyledSpace>
        <StyledButton type="primary" onClick={form.submit} loading={loading}>
          {t('buttons.send')}
        </StyledButton>
        <StyledNavLink to={Paths.LOGIN}>
          <Button type="text" className="themed">
            {t('buttons.enter')}
          </Button>
        </StyledNavLink>
      </StyledSpace>
    </div>
  );
};
