import dayjs from 'dayjs';
import { v4 } from 'uuid';

import { TListedVideo, TPlayList } from '../types/video';
import { getRandomArbitrary } from '../utils/common';
import { NBSP } from '../utils/text-formatters';
import image1 from './images/video-preview-1.jpg';
import image2 from './images/video-preview-2.jpg';
import image3 from './images/video-preview-3.jpg';
import image4 from './images/video-preview-4.jpg';
import image5 from './images/video-preview-5.jpg';
import image6 from './images/video-preview-6.jpg';
import image7 from './images/video-preview-7.jpg';
import image8 from './images/video-preview-8.jpg';

const imagesUrls = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
];

const names = [
  'Бескрайний космический космос',
  'Светящаяся желтая штуковина от земли до неба',
  'Кофе бамбл с соком — вкусно ли это?',
  'Почему небо зеленое',
  'Планета смотри на себя в зеркало. Полная жесть',
  'Сатурн типа',
  'Солнце под песком',
  'Камни на песке',
];

const playlistNames = [
  'Космический рассказ',
  'Космическая лекция',
  'Звезды, галактики и вселенные',
  'Все о космосе',
  'Космический рассказ',
  'Космическая лекция',
  'Звезды, галактики и вселенные',
  'Все о космосе',
];

export const MOCK_VIDEO_DESCRIPTION = `Любите${NBSP}ли${NBSP}вы звёзды? Можно купить дом${NBSP}на${NBSP}Оке${NBSP}в${NBSP}красивом месте, но${NBSP}так${NBSP}и${NBSP}не${NBSP}увидеть небо над${NBSP}ним… Самое яркое и${NBSP}впечатляющее${NBSP}— звёздное небо, которое каждый из${NBSP}нас${NBSP}видит ночью над${NBSP}головой. Чёрная, глубочайшая темнота и${NBSP}созвездия.Но некоторые заглядывают ещё${NBSP}глубже и${NBSP}видят там${NBSP}неповторимую красоту, которую не${NBSP}сможет передать даже${NBSP}наше воображение и${NBSP}фантазия.`;

const createMockVideo: (index: number) => TListedVideo = (index) => {
  return {
    id: v4(),
    previewUrl: imagesUrls[index],
    uploadDate: dayjs().add(-index, 'days').toISOString(),
    name: names[index],
    duration: getRandomArbitrary(12, 738),
    views: getRandomArbitrary(1, 341) * 10000,
    description: MOCK_VIDEO_DESCRIPTION,
  };
};

const createMockPlaylist: (index: number) => TPlayList = (index) => {
  let images: undefined | any[] = [];

  if (index === 0 || index === imagesUrls.length - 1) {
    images.push(imagesUrls[index]);
  } else if (index === 3) {
    images = undefined;
  } else {
    images.push(imagesUrls[index], imagesUrls[index + 1]);
  }

  return {
    id: v4(),
    previewUrls: images,
    name: playlistNames[index],
    videosCount: getRandomArbitrary(0, 15),
  };
};

const getMocksList: () => TListedVideo[] = () => {
  const result: TListedVideo[] = [];

  for (let i = 0; i < 8; i++) {
    result.push(createMockVideo(i));
  }

  return result;
};

const getMocksPlaylists: () => TPlayList[] = () => {
  const result: TPlayList[] = [];

  for (let i = 0; i < 8; i++) {
    result.push(createMockPlaylist(i));
  }

  return result;
};

export const mockVideoList = getMocksList();
export const mockPlayLists = getMocksPlaylists();
