import { TFunction, t } from 'i18next';

export type TListedVideo = {
  id: string;
  previewUrl: string;
  name: string;
  duration: number;
  views: number;
  uploadDate: string;
  description: string;
};

export type TPlayList = {
  id: string;
  previewUrls: string[] | undefined;
  videosCount: number;
  name: string;
};

export type TVideoStatItem = {
  value: number;
  position: number;
};

export type TVideoStats = {
  attention: TVideoStatItem[];
  emotional: TVideoStatItem[];
  interest: TVideoStatItem[];
};

export enum EPlayerMode {
  AdvertisingMarketing = 'advertisingMarketing',
  Training = 'training',
  HealthSafety = 'healthSafety',
  KidsMode = 'kidsMode',
  CustomSettings = 'customSettings',
}

export type TLink = {
  id: string;
  title: string;
  views: number;
  attentionRate: number;
  interestRate: number;
  mode: EPlayerMode;
};

export const ModeOptions = {
  [EPlayerMode.AdvertisingMarketing]: 'settings.player.advertising',
  [EPlayerMode.Training]: 'settings.player.training',
  [EPlayerMode.HealthSafety]: 'settings.player.safety',
  [EPlayerMode.KidsMode]: 'settings.player.kids',
  [EPlayerMode.CustomSettings]: 'settings.player.custom',
};

export enum EVideoQueryPaths {
  PLAYLIST_ID = 'playlistId',
  MODE = 'mode',
}

export enum EVideoQueryMods {
  ADD_VIDEO = 'add-video',
}

export enum ESortOption {
  NEW = 'new-first',
  OLD = 'old-first',
}

export let VideoSortOptions: (t: TFunction<string[]>) => any;
VideoSortOptions = (t) => [
  {
    value: ESortOption.NEW,
    label: t('menu.sort.new'),
  },
  {
    value: ESortOption.OLD,
    label: t('menu.sort.old'),
  },
];

export type TLinkSettings = {
  playerMode: number;
  subtitles: boolean;
  captions?: string;
  guarantee: boolean;
  attention: boolean;
  attentionRate: number;
  interest: boolean;
  interestRate: number;
  voice: boolean;
  voiceRate: number;
  speedControl: boolean;
  timeControl: boolean;
  distanceControl: boolean;
};
