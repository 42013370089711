export const textToBool: (text?: string) => boolean = (t) => {
  return t === 'true';
};

export const boolToText: (bool?: boolean) => 'true' | 'false' = (b) => {
  return b ? 'true' : 'false';
};

export function getRandomArbitrary(min: number = 0, max: number = 100) {
  return Math.round(Math.random() * (max - min) + min);
}

export function timeToString(value: number): string {
  let hours: string | number = Math.round(Math.floor(value / 3600));
  let minutes: string | number = Math.round(
    Math.floor((value - hours * 3600) / 60)
  );
  let seconds: string | number = Math.round(
    value - hours * 3600 - minutes * 60
  );

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${Number(hours) > 0 ? hours + ':' : ''}` + minutes + ':' + seconds;
}

export function parseJwt<T>(token: string): T {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
