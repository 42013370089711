import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TCreatedVideo } from '../../types/responses/video';

type TState = {
  data: TCreatedVideo[];
  loading: boolean;
  loaded: boolean;
  errorOnLoad: boolean;
};

const initialState: TState = {
  data: [],
  loaded: false,
  errorOnLoad: false,
  loading: false,
};

const listSlice = createSlice({
  name: 'videos/list',
  initialState: initialState as TState,
  reducers: {
    setVideoListAction: (state, action: PayloadAction<TCreatedVideo[]>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setVideoListLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setVideoListLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setVideoListError: (state, action: PayloadAction<boolean>) => {
      state.errorOnLoad = action.payload;
    },
    resetVideoListState: (state) => {
      state.data = [];
      state.loaded = false;
      state.loading = false;
      state.errorOnLoad = false;
    },
  },
});

export const {
  setVideoListAction,
  setVideoListError,
  setVideoListLoading,
  resetVideoListState,
} = listSlice.actions;
export const videoListReducer = listSlice.reducer;
