import styled from '@emotion/styled';
import { FC, PropsWithChildren, memo } from 'react';

import { boolToText, textToBool } from '../../utils/common';
import { ESimpleDateKey } from './dates-list';

const Wrapper = styled('button')<{
  isSelected?: 'true' | 'false';
  isDisabled?: 'true' | 'false';
}>`
  border-radius: 30px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  border: 1px solid
    ${({ theme, isSelected }) =>
      textToBool(isSelected)
        ? theme.COLORS.ACCENT._200
        : theme.COLORS.BLACK._400};
  padding: 8px 16px;
  font-size: inherit;
  transition: border-color 0.3s ease-out;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  cursor: ${({ isDisabled }) =>
    textToBool(isDisabled) ? 'not-allowed' : 'pointer'};
  position: relative;

  &:hover {
    border-color: ${({ theme, isDisabled, isSelected }) =>
      textToBool(isDisabled) && !textToBool(isSelected)
        ? theme.COLORS.BLACK._400
        : theme.COLORS.ACCENT._200};
  }
`;

type TProps = {
  onClick?: (key: ESimpleDateKey) => void;
  dateKey: ESimpleDateKey;
  selected: boolean;
  disabled: boolean;
} & PropsWithChildren;

export const FilterPickItem: FC<TProps> = memo(
  ({ children, onClick, dateKey, selected, disabled }) => {
    const handleClick = () => {
      onClick?.(dateKey);
    };

    return (
      <Wrapper
        onClick={handleClick}
        isSelected={boolToText(selected)}
        isDisabled={boolToText(disabled)}
      >
        {children}
      </Wrapper>
    );
  }
);
