import {
  ECurrency,
  EPlanName,
  EStorageUnit,
  ESupportKey,
  TTariffPlan,
} from '../types/finance';

export const tariffPlans: TTariffPlan[] = [
  {
    name: EPlanName.BASE,
    type: 'Cloud',
    pricePerYear: {
      [ECurrency.RUR]: 0,
      [ECurrency.USD]: 0,
    },
    pricePerMonth: {
      [ECurrency.RUR]: 0,
      [ECurrency.USD]: 0,
    },
    features: {
      storageSize: {
        size: '500',
        unit: EStorageUnit.MB,
      },
      uploadWithoutSaving: true,
      linksCount: 10,
      viewAnalytics: 10,
      bandwidth: 0.5,
      pcApplication: false,
      branding: false,
      support: [ESupportKey.chatBot],
    },
  },
  {
    name: EPlanName.START,
    type: 'Cloud',
    pricePerYear: {
      [ECurrency.RUR]: 36000,
      [ECurrency.USD]: 1200,
    },
    pricePerMonth: {
      [ECurrency.RUR]: 3000,
      [ECurrency.USD]: 100,
    },
    features: {
      storageSize: {
        size: '100',
        unit: EStorageUnit.GB,
      },
      uploadWithoutSaving: true,
      linksCount: 100,
      viewAnalytics: 100,
      bandwidth: 1,
      pcApplication: false,
      branding: false,
      support: [ESupportKey.chatBot, ESupportKey.email],
    },
  },
  {
    name: EPlanName.STANDARD,
    type: 'Cloud',
    pricePerYear: {
      [ECurrency.RUR]: 120000,
      [ECurrency.USD]: 3600,
    },
    pricePerMonth: {
      [ECurrency.RUR]: 10000,
      [ECurrency.USD]: 300,
    },
    features: {
      storageSize: {
        size: '1',
        unit: EStorageUnit.TB,
      },
      uploadWithoutSaving: true,
      linksCount: 1000,
      viewAnalytics: 1000,
      bandwidth: 2,
      pcApplication: false,
      branding: false,
      support: [ESupportKey.chatBot, ESupportKey.email, ESupportKey.phone],
    },
  },
  {
    name: EPlanName.BUSINESS,
    type: 'Cloud',
    pricePerYear: {
      [ECurrency.RUR]: 240000,
      [ECurrency.USD]: 7200,
    },
    pricePerMonth: {
      [ECurrency.RUR]: 20000,
      [ECurrency.USD]: 600,
    },
    features: {
      storageSize: {
        size: '5',
        unit: EStorageUnit.TB,
      },
      uploadWithoutSaving: true,
      linksCount: 'infinite',
      viewAnalytics: 'infinite',
      bandwidth: 2,
      pcApplication: false,
      branding: false,
      support: [ESupportKey.chatBot, ESupportKey.email, ESupportKey.phone],
    },
  },
  {
    name: EPlanName.CORP,
    type: 'On-Premise',
    pricePerYear: {
      [ECurrency.RUR]: 'need-request',
      [ECurrency.USD]: 'need-request',
    },
    pricePerMonth: {
      [ECurrency.RUR]: 'need-request',
      [ECurrency.USD]: 'need-request',
    },
    features: {
      storageSize: {
        size: 'infinite',
        unit: EStorageUnit.TB,
      },
      uploadWithoutSaving: true,
      linksCount: 'infinite',
      viewAnalytics: 'infinite',
      bandwidth: 'infinite',
      pcApplication: true,
      branding: true,
      support: [ESupportKey.chatBot, ESupportKey.email, ESupportKey.phone],
    },
  },
];
