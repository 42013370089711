import { FC } from 'react';

import { RU_LANG_SUPPORT } from '../../constants';

export const Logo: FC = () => {
  if (RU_LANG_SUPPORT) {
    return (
      <svg
        width="100"
        height="48"
        viewBox="0 0 100 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_89_217)">
          <path
            d="M99.1165 22.3865C100.294 22.9042 100.294 24.5778 99.1165 25.0955L48.2014 47.482V0L99.1165 22.3865Z"
            fill="url(#paint0_linear_89_217)"
          />
          <path
            d="M87.6058 22.3865C88.7835 22.9042 88.7835 24.5778 87.6058 25.0955L36.6907 47.482V0L87.6058 22.3865Z"
            fill="url(#paint1_linear_89_217)"
          />
          <path
            opacity="0.9"
            d="M56.3899 17.3104C56.3899 18.0222 56.2608 18.6514 56.0026 19.1983C55.7443 19.7411 55.3749 20.1955 54.8941 20.5614C54.4135 20.9273 53.8295 21.2048 53.1422 21.3937C52.459 21.5787 51.6902 21.6712 50.8361 21.6712C49.982 21.6712 49.2093 21.5787 48.5181 21.3937C47.8308 21.2048 47.2449 20.9273 46.7602 20.5614C46.2755 20.1955 45.9021 19.7411 45.6399 19.1983C45.3777 18.6514 45.2466 18.0222 45.2466 17.3104C45.2466 16.5987 45.3777 15.9714 45.6399 15.4286C45.9021 14.8817 46.2755 14.4253 46.7602 14.0594C47.2449 13.6935 47.8308 13.4181 48.5181 13.2331C49.2093 13.0441 49.982 12.9496 50.8361 12.9496C51.6902 12.9496 52.459 13.0441 53.1422 13.2331C53.8295 13.4181 54.4135 13.6935 54.8941 14.0594C55.3749 14.4253 55.7443 14.8817 56.0026 15.4286C56.2608 15.9714 56.3899 16.5987 56.3899 17.3104ZM54.1552 17.3104C54.1552 17.0008 54.1056 16.6932 54.0063 16.3876C53.9109 16.078 53.7381 15.8005 53.4879 15.5553C53.2415 15.31 52.9038 15.1109 52.4749 14.9581C52.0458 14.8053 51.4995 14.7289 50.8361 14.7289C50.3912 14.7289 49.9999 14.7651 49.6622 14.8375C49.3245 14.9058 49.0326 15.0024 48.7862 15.127C48.5399 15.2517 48.3333 15.3984 48.1665 15.5673C47.9997 15.7322 47.8666 15.9111 47.7672 16.1041C47.6679 16.2931 47.5964 16.4922 47.5527 16.7012C47.513 16.9063 47.4931 17.1094 47.4931 17.3104C47.4931 17.5155 47.513 17.7226 47.5527 17.9317C47.5964 18.1407 47.6679 18.3418 47.7672 18.5348C47.8666 18.7238 47.9997 18.9007 48.1665 19.0656C48.3333 19.2304 48.5399 19.3752 48.7862 19.4999C49.0326 19.6205 49.3245 19.717 49.6622 19.7894C49.9999 19.8577 50.3912 19.8919 50.8361 19.8919C51.4995 19.8919 52.0458 19.8155 52.4749 19.6627C52.9038 19.5099 53.2415 19.3109 53.4879 19.0656C53.7381 18.8203 53.9109 18.5449 54.0063 18.2393C54.1056 17.9296 54.1552 17.62 54.1552 17.3104Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M44.1705 15.0366C44.1108 15.0164 44.0235 14.9903 43.9082 14.9581C43.797 14.9219 43.6659 14.8878 43.515 14.8556C43.364 14.8194 43.1972 14.7893 43.0144 14.7651C42.8356 14.741 42.6509 14.7289 42.4602 14.7289C42.063 14.7289 41.6915 14.7792 41.3459 14.8797C41.0043 14.9762 40.6844 15.1069 40.3865 15.2718C40.0886 15.4326 39.8105 15.6176 39.5523 15.8267C39.298 16.0317 39.0636 16.2428 38.8491 16.46V21.448H36.6383V13.1607H38.8491V14.5058C39.1153 14.3048 39.3874 14.1117 39.6655 13.9268C39.9475 13.7378 40.2395 13.5709 40.5415 13.4261C40.8473 13.2814 41.1691 13.1668 41.5068 13.0823C41.8445 12.9939 42.202 12.9496 42.5794 12.9496C42.7224 12.9496 42.8674 12.9577 43.0144 12.9737C43.1654 12.9858 43.3104 13.0019 43.4494 13.022C43.5924 13.0422 43.7255 13.0642 43.8487 13.0884C43.9718 13.1125 44.0791 13.1366 44.1705 13.1607V15.0366Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M26.5696 13.1604V17.8288C26.5696 18.1384 26.5954 18.4199 26.647 18.6732C26.6987 18.9225 26.794 19.1376 26.9331 19.3186C27.0721 19.4955 27.2667 19.6342 27.517 19.7347C27.7673 19.8312 28.0931 19.8795 28.4943 19.8795C28.7962 19.8795 29.1121 19.8373 29.4418 19.7528C29.7755 19.6684 30.1052 19.5558 30.431 19.415C30.7567 19.2743 31.0666 19.1135 31.3606 18.9325C31.6585 18.7476 31.9247 18.5566 32.1591 18.3596V13.1604H34.3639V21.4477H32.1591V20.3137C31.881 20.4746 31.585 20.6375 31.2712 20.8023C30.9573 20.9632 30.6237 21.1099 30.2701 21.2426C29.9165 21.3713 29.5411 21.4758 29.1439 21.5563C28.7466 21.6407 28.3275 21.6829 27.8865 21.6829C27.3343 21.6829 26.8397 21.6145 26.4027 21.4778C25.9657 21.3452 25.5962 21.1562 25.2944 20.9109C24.9924 20.6616 24.7601 20.362 24.5972 20.0122C24.4383 19.6583 24.3588 19.2643 24.3588 18.83V13.1604H26.5696Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M21.7129 21.0378C21.0415 21.263 20.3701 21.4239 19.6988 21.5204C19.0314 21.6209 18.348 21.6712 17.6488 21.6712C16.755 21.6712 15.9585 21.5747 15.2593 21.3817C14.5641 21.1847 13.9781 20.9012 13.5014 20.5312C13.0247 20.1613 12.6612 19.7089 12.4109 19.1742C12.1606 18.6394 12.0355 18.0342 12.0355 17.3586C12.0355 16.7314 12.1606 16.1504 12.4109 15.6155C12.6652 15.0768 13.0227 14.6103 13.4836 14.2163C13.9483 13.8182 14.5085 13.5086 15.164 13.2874C15.8195 13.0622 16.5504 12.9496 17.3569 12.9496C18.0998 12.9496 18.779 13.0522 19.3949 13.2573C20.0146 13.4583 20.5449 13.7578 20.9859 14.156C21.4308 14.554 21.7744 15.0446 22.0168 15.6276C22.2631 16.2107 22.3862 16.8822 22.3862 17.6422V18.1971H14.3178C14.3893 18.4584 14.5065 18.6976 14.6694 18.9148C14.8362 19.1319 15.0607 19.3169 15.3427 19.4697C15.6248 19.6225 15.9704 19.7411 16.3796 19.8255C16.7928 19.91 17.2854 19.9522 17.8574 19.9522C18.2626 19.9522 18.6599 19.9281 19.0492 19.8799C19.4385 19.8276 19.8021 19.7612 20.1397 19.6808C20.4774 19.5963 20.7813 19.5039 21.0514 19.4033C21.3255 19.3028 21.546 19.2023 21.7129 19.1018V21.0378ZM20.1874 16.5565C20.1675 16.3514 20.106 16.1403 20.0026 15.9232C19.9034 15.702 19.7444 15.503 19.526 15.326C19.3075 15.1491 19.0214 15.0044 18.6678 14.8918C18.3143 14.7752 17.8733 14.7168 17.3449 14.7168C16.8484 14.7168 16.4193 14.7792 16.0578 14.9038C15.6963 15.0285 15.3924 15.1833 15.1461 15.3683C14.9038 15.5532 14.7151 15.7523 14.58 15.9654C14.4449 16.1785 14.3575 16.3755 14.3178 16.5565H20.1874Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M7.73475 21.4477L2.21078 15.754V21.4477H0V13.1604H2.40146L7.93137 18.8783V13.1604H10.1302V21.4477H7.73475Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M57.3149 32.3592V27.8218H59.5257V32.3592H57.3149Z"
            fill="#00FFEE"
          />
          <path
            opacity="0.9"
            d="M53.0465 30.8058H48.4819L47.7132 32.374H45.2462L49.5307 24.0867H51.9977L56.2822 32.374H53.8152L53.0465 30.8058ZM49.2745 29.1893H52.2659L50.7761 26.1374L49.2745 29.1893Z"
            fill="#00FFEE"
          />
          <path
            opacity="0.9"
            d="M36.6382 32.3591V24.0718H38.8489V30.5436H44.172V32.3591H36.6382Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M34.5892 26.9006C34.5892 27.3147 34.5296 27.6967 34.4104 28.0465C34.2912 28.3923 34.0986 28.6919 33.8324 28.9452C33.5702 29.1945 33.2286 29.3896 32.8075 29.5303C32.3863 29.671 31.8739 29.7414 31.27 29.7414H26.6186V32.3591H24.4078V24.0718H31.27C31.8739 24.0718 32.3863 24.1422 32.8075 24.2829C33.2286 24.4196 33.5702 24.6146 33.8324 24.8679C34.0986 25.1173 34.2912 25.4148 34.4104 25.7606C34.5296 26.1064 34.5892 26.4864 34.5892 26.9006ZM32.3545 26.9368C32.3545 26.7437 32.3268 26.5788 32.2711 26.4422C32.2195 26.3014 32.1321 26.1868 32.0089 26.0983C31.8858 26.0099 31.7249 25.9455 31.5263 25.9053C31.3276 25.8611 31.0833 25.839 30.7933 25.839H26.6186V27.9742H30.7933C31.0833 27.9742 31.3276 27.9581 31.5263 27.9259C31.7249 27.8897 31.8858 27.8314 32.0089 27.751C32.1321 27.6665 32.2195 27.558 32.2711 27.4252C32.3268 27.2926 32.3545 27.1297 32.3545 26.9368Z"
            fill="white"
          />
          <path
            d="M58.4047 26.6238C59.1269 26.6238 59.7123 26.0312 59.7123 25.3003C59.7123 24.5694 59.1269 23.9768 58.4047 23.9768C57.6826 23.9768 57.0972 24.5694 57.0972 25.3003C57.0972 26.0312 57.6826 26.6238 58.4047 26.6238Z"
            fill="#00FFEE"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_89_217"
            x1="48.1729"
            y1="47.482"
            x2="102.21"
            y2="47.516"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFF1" stopOpacity="0" />
            <stop offset="0.33" stopColor="#00FFF1" stopOpacity="0.2" />
            <stop offset="0.66" stopColor="#00FFF0" stopOpacity="0.5" />
            <stop offset="1" stopColor="#00FFF3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_89_217"
            x1="36.6621"
            y1="47.482"
            x2="90.6993"
            y2="47.516"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFF1" stopOpacity="0" />
            <stop offset="0.33" stopColor="#00FFF1" stopOpacity="0.2" />
            <stop offset="0.66" stopColor="#00FFF0" stopOpacity="0.5" />
            <stop offset="1" stopColor="#00FFF3" />
          </linearGradient>
          <clipPath id="clip0_89_217">
            <rect width="100" height="47.482" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width="100"
        height="53"
        viewBox="0 0 100 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_91_285)">
          <path
            d="M98.2704 23.6801C100.577 24.8522 100.577 28.1541 98.2704 29.3263L52.3696 52.66C50.2694 53.7276 47.7847 52.1977 47.7847 49.8369V40.8634C47.7847 40.4058 48.1557 40.0347 48.6134 40.0347C49.0711 40.0347 49.4422 40.4058 49.4422 40.8634V45.7289C49.4422 46.9366 50.737 47.6992 51.7893 47.1113L86.1961 27.8856C87.276 27.282 87.276 25.7243 86.1961 25.1207L51.7893 5.89505C50.737 5.3071 49.4422 6.06979 49.4422 7.27748V11.7471C49.4422 12.2048 49.0711 12.5759 48.6134 12.5759C48.1557 12.5759 47.7847 12.2048 47.7847 11.7471V3.16943C47.7847 0.808639 50.2694 -0.721304 52.3696 0.346304L98.2704 23.6801Z"
            fill="#09F5E6"
          />
          <path
            d="M64.7945 36.4716H61.2344V16.6931H64.7945V36.4716Z"
            fill="#09F5E6"
          />
          <path
            d="M59.177 36.4716H55.1438L48.8922 24.3672L42.6406 36.4716H38.6074L48.8922 16.5349L59.177 36.4716Z"
            fill="#09F5E6"
          />
          <path
            d="M26.6956 32.883L37.2628 32.9115L35.4432 36.4716H27.0411C26.0333 36.4716 25.0832 36.2438 24.2193 35.8166C22.9524 35.19 21.9159 34.1647 21.3112 32.9115C20.8793 32.0571 20.6489 31.1172 20.6489 30.1204V16.5349H24.2481V30.4621C24.4209 31.7153 25.4287 32.7121 26.6956 32.883Z"
            fill="white"
          />
          <path
            d="M16.2904 18.1014C17.2554 19.0697 17.8797 20.4653 17.8797 21.9463C17.8797 24.1109 16.6026 25.9906 14.7579 26.845C14.2754 27.0729 13.4414 27.3007 12.437 27.3007H3.54757V36.4716H0V16.5349H12.5158C13.9916 16.5349 15.3255 17.133 16.2904 18.1014ZM13.2821 23.6267C13.9349 23.3419 14.3605 22.6583 14.3605 21.9463C14.3605 21.2912 14.02 20.8355 13.8214 20.6362C13.5943 20.4083 13.1686 20.1235 12.5442 20.1235H3.54757V23.7976H12.5442C12.7996 23.7976 13.0551 23.7406 13.2821 23.6267Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_91_285">
            <rect width="100" height="53.0063" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
