import React, { ReactNode, memo } from 'react';

import { cellStyle } from './styles';

interface TableCellProps {
  content: any;
  canBeExpanded: boolean;
  onClick?: () => void;
}

export const TableCell: (props: TableCellProps) => ReactNode = memo(
  ({ content, canBeExpanded, onClick }) => {
    return (
      <td css={cellStyle(canBeExpanded)} onClick={onClick}>
        {content}
      </td>
    );
  }
);
