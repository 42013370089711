import { TAnalyticsDataType } from '../../components/analytics/analytics-table/types';
import { TSortValue, TableRowData } from '../../components/table/types';

export function sortAnalyticsTable(
  a: TableRowData<TAnalyticsDataType>,
  b: TableRowData<TAnalyticsDataType>,
  sortedData: Partial<Record<keyof TAnalyticsDataType, TSortValue>>
): number {
  const key = (Object.keys(sortedData) as (keyof TAnalyticsDataType)[])[0];

  if (sortedData[key]) {
    switch (key) {
      case 'views':
        return sortedData[key] === 'asc'
          ? a.data.views - b.data.views
          : b.data.views - a.data.views;
      case 'time':
        return sortedData[key] === 'asc'
          ? parseInt(a.data.time) - parseInt(b.data.time)
          : parseInt(b.data.time) - parseInt(a.data.time);
      case 'name':
        return sortedData[key] === 'asc'
          ? a.data.name.localeCompare(b.data.name)
          : b.data.name.localeCompare(a.data.name);
      case 'absent_mindedness':
      case 'attentiveness':
      case 'engagement':
      case 'fatigue':
      case 'emotionality':
        return sortedData[key] === 'asc'
          ? (a.data[key] ?? 0) - (b.data[key] ?? 0)
          : (b.data[key] ?? 0) - (a.data[key] ?? 0);
    }
  }

  return 0;
}
