import styled from '@emotion/styled';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBack from '../icons/arrow-back';

const Wrapper = styled('div')`
  display: flex;
  gap: 15px;
  align-items: center;
  height: 50px;
  padding-inline: 14px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  color: ${({ theme }) => theme.COLORS.BLACK._800};
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  width: fit-content;
  border-radius: 20px;
  margin-top: 20px;

  .icon {
    transition: transform 0.3s ease-out;
  }

  :hover {
    .icon {
      transform: translateX(-5px);
    }
  }
`;

type TProps = {
  text: string;
  onClick?: () => void;
};

export const PageBackButton: FC<TProps> = ({ text, onClick }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <Wrapper onClick={handleBack}>
      <ArrowBack className="icon" />
      <span>{text}</span>
    </Wrapper>
  );
};
