import { FC } from 'react';

export const ExpandIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99999 4.86204L13.8047 10.6667L12.862 11.6094L7.99999 6.74737L3.13799 11.6094L2.19532 10.6667L7.99999 4.86204Z"
        fill="#767676"
      />
    </svg>
  );
};
