import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const Finance: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6861 0C12.3775 0 12.9361 0.670312 12.9361 1.5V3.17344C12.9986 3.18281 13.0572 3.19219 13.1197 3.20625C13.1353 3.21094 13.147 3.21094 13.1627 3.21562L15.0377 3.62813C15.7173 3.77813 16.1666 4.56094 16.0416 5.37187C15.9166 6.18281 15.2642 6.72656 14.5884 6.57656L12.733 6.16875C11.5103 5.95313 10.4322 6.09844 9.67437 6.45938C8.91656 6.82031 8.61187 7.31719 8.54156 7.77656C8.46344 8.27812 8.52203 8.55938 8.58844 8.73281C8.65875 8.91563 8.80328 9.12187 9.08844 9.35156C9.72515 9.85312 10.7017 10.1813 11.9673 10.5844L12.0806 10.6219C13.1978 10.9781 14.565 11.4094 15.5806 12.2062C16.1353 12.6422 16.6587 13.2328 16.983 14.0578C17.315 14.8969 17.3853 15.8344 17.233 16.8328C16.9634 18.6141 15.94 19.8047 14.6705 20.4281C14.1353 20.6906 13.5533 20.8594 12.9361 20.9437V22.5C12.9361 23.3297 12.3775 24 11.6861 24C10.9947 24 10.4361 23.3297 10.4361 22.5V20.8641C10.4205 20.8594 10.4009 20.8594 10.3853 20.8547H10.3775C9.42437 20.6766 7.85797 20.1844 6.80328 19.6219C6.17437 19.2844 5.88922 18.3984 6.17047 17.6437C6.45172 16.8891 7.19 16.5469 7.8189 16.8844C8.63531 17.3203 9.97906 17.7516 10.7564 17.8969C12.0025 18.1172 13.0298 17.9906 13.7252 17.6484C14.3853 17.325 14.6861 16.8563 14.772 16.2938C14.8462 15.7969 14.7877 15.5109 14.7212 15.3375C14.647 15.15 14.5025 14.9437 14.2134 14.7141C13.5728 14.2125 12.5923 13.8844 11.3228 13.4813L11.2134 13.4484C10.1002 13.0922 8.73297 12.6562 7.71734 11.8594C7.16265 11.4234 6.64312 10.8281 6.3189 10.0031C5.99078 9.16406 5.92437 8.22656 6.08062 7.22812C6.36187 5.4375 7.47906 4.275 8.74859 3.67031C9.26812 3.42188 9.83844 3.25313 10.4361 3.15469V1.5C10.4361 0.670312 10.9947 0 11.6861 0Z"
        fill={theme.COLORS.BLACK._700}
      />
    </svg>
  );
};

export default withTheme(Finance);
