import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TAnalyticsResponse } from '../../types/responses/analytics';

type TState = {
  pending: boolean;
  loaded: boolean;
  error: boolean;
  data?: TAnalyticsResponse;
};

const initialState: TState = {
  pending: false,
  loaded: false,
  error: false,
  data: undefined,
};

const dataSlice = createSlice({
  name: 'analytics/data',
  initialState,
  reducers: {
    startAnalyticsAction(state) {
      state.pending = true;
    },
    finishAnalyticsAction(state, action: PayloadAction<TAnalyticsResponse>) {
      state.data = action.payload;
      state.loaded = true;
      state.error = false;
      state.pending = false;
    },
    errorAnalyticsAction(state) {
      state.pending = false;
      state.error = true;
      state.loaded = false;
    },
    setNeedLoadAnalyticsAction(state) {
      state.loaded = false;
      state.error = false;
      state.pending = false;
    },
  },
});

export const {
  startAnalyticsAction,
  finishAnalyticsAction,
  errorAnalyticsAction,
  setNeedLoadAnalyticsAction,
} = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
