import styled from '@emotion/styled';
import { Button, Form, Input, Space, Typography } from 'antd';
import App from 'antd/lib/app';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { API } from '../api';
import { TUseFormEnterProps, useFormEnterPress } from '../hooks/forms';
import { LocaleKeys } from '../locale';
import { Paths } from '../types/common';
import { TForgotLoginForm } from '../types/users';

const Title = styled(Typography.Title)`
  color: ${({ theme }) => theme.COLORS.WHITE._100} !important;
  margin: 0;
  margin-bottom: 16px !important;
`;

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  flex: 1;
`;

export const ForgotPassword: FC = () => {
  const { t } = useTranslation([LocaleKeys.LOGIN]);
  const { message } = App.useApp();
  const [form] = Form.useForm<TForgotLoginForm>();
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (values: TForgotLoginForm) => {
    setLoading(true);

    try {
      await API.auth.forgotPassword(values);

      message.success(t('password.success'));
      setLoading(false);
    } catch (e) {
      message.error(t('password.error'));
      setLoading(false);
    }
  };

  const formEnterProps: TUseFormEnterProps<typeof form.submit> = useMemo(
    () => ({
      submitFunc: form.submit,
    }),
    []
  );

  useFormEnterPress(formEnterProps);

  return (
    <div>
      <Title level={4}>{t('password.title')}</Title>
      <Form
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        form={form}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="email"
          label="Email"
          className="themed"
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
          ]}
        >
          <Input
            className="themed"
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Form>
      <StyledSpace>
        <StyledButton type="primary" onClick={form.submit} loading={loading}>
          {t('buttons.send')}
        </StyledButton>
        <StyledNavLink to={Paths.LOGIN}>
          <Button type="text" className="themed">
            {t('buttons.enter')}
          </Button>
        </StyledNavLink>
        <StyledNavLink to={Paths.REGISTRATION}>
          <Button type="text" className="themed">
            {t('buttons.account.no')}
          </Button>
        </StyledNavLink>
      </StyledSpace>
    </div>
  );
};
