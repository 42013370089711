import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { LocaleKeys } from '../../locale';
import { Paths, SecondaryPaths } from '../../types/common';
import { ESortOption, VideoSortOptions } from '../../types/video';

const Wrapper = styled('div')`
  padding: 40px 50px 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkItem = styled(NavLink)`
  height: 50px;
  padding-inline: 20px;
  border-radius: 25px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
  border: 1px solid transparent;
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out,
    border-color 0.3s ease-out;

  :hover:not(.active) {
    color: ${({ theme }) => theme.COLORS.WHITE._600};
    border-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }

  &.active {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};
    background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  }
`;

const PlayListButton = styled(Button)`
  height: 50px;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
`;

const OperationsButtons = styled('div')`
  margin-right: 16px;
  display: flex;
  gap: 24px;
`;

export const PageMenu: FC<{
  setPlayListModal: Dispatch<SetStateAction<boolean>>;
  setSortOption: Dispatch<SetStateAction<ESortOption>>;
  playlistEditMode?: boolean;
  handleSavePlaylist?: () => void;
  exitEditMode?: () => void;
  operationsButtonProps?: ButtonProps;
  sortOption: ESortOption;
  videoLink?: string;
  playListLink?: string;
  blockEditPlaylist?: boolean;
}> = ({
  setPlayListModal,
  playlistEditMode,
  handleSavePlaylist,
  exitEditMode,
  operationsButtonProps,
  sortOption,
  setSortOption,
  videoLink,
  playListLink,
  blockEditPlaylist,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const location = useLocation();
  const [isPlayListPage, setIsPlayListPage] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(SecondaryPaths.PLAYLISTS)) {
      setIsPlayListPage(true);
    } else {
      setIsPlayListPage(false);
    }
  }, [location]);

  const handleOpenPlayList = () => {
    setPlayListModal(true);
  };

  return (
    <Wrapper>
      <Space size={10}>
        <LinkItem end to={videoLink ?? Paths.BASE}>
          {t('menu.video')}
        </LinkItem>
        {!playlistEditMode && (
          <LinkItem end to={playListLink ?? Paths.UPLOADED_VIDEOS_PLAYLIST}>
            {t('menu.playlist.title')}
          </LinkItem>
        )}
      </Space>
      <Space>
        {isPlayListPage && !playlistEditMode && !blockEditPlaylist && (
          <PlayListButton type="primary" onClick={handleOpenPlayList}>
            {t('menu.playlist.create')}
          </PlayListButton>
        )}
        {playlistEditMode && (
          <OperationsButtons>
            <Button
              type="primary"
              onClick={handleSavePlaylist}
              size="large"
              shape="round"
              {...operationsButtonProps}
            >
              {t('buttons.save.common')}
            </Button>
            <Button
              onClick={exitEditMode}
              size="large"
              shape="round"
              {...operationsButtonProps}
            >
              {t('buttons.cancel')}
            </Button>
          </OperationsButtons>
        )}
        <Select
          value={sortOption}
          style={{ width: 250 }}
          options={VideoSortOptions(t)}
          onChange={setSortOption}
        />
      </Space>
    </Wrapper>
  );
};
