import { useAppSelector } from '../index';

export const usePlaylistAllSelector = () =>
  useAppSelector((state) => state.playLists.list);

export const usePlaylistCurrentSelector = () =>
  useAppSelector((state) => state.playLists.current);

export const useViewedPlaylists = () =>
  useAppSelector((state) => state.playLists.viewed);
