import App from 'antd/lib/app';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from '../api';
import { useAppDispatch } from '../state';
import { useMeSelector } from '../state/me/selectors';
import {
  setVideoByIdAction,
  setVideoByIdError,
  setVideoByIdLoading,
} from '../state/video/byIdSlice';
import { useVideoSelector } from '../state/video/selectors';
import { Paths } from '../types/common';
import { useVideoLinkPathId, useVideoPathId } from './use-video-path-id';

export const useLoadVideo: () => [
  () => Promise<void>,
  string | undefined,
  string | undefined,
] = () => {
  const dispatch = useAppDispatch();
  const id = useVideoPathId();
  const linkId = useVideoLinkPathId();
  const loadingRef = useRef(false);
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { meLoaded } = useMeSelector();

  const { data, loading, loaded, errorOnLoad } = useVideoSelector();

  const handleLoadVideo = useCallback(async () => {
    if (id && data?.id !== id) {
      loadingRef.current = true;
      dispatch(setVideoByIdLoading(true));
      try {
        const dataPromise: ReturnType<typeof API.videos.getById> = new Promise(
          (res) => {
            API.videos.getById(id).then((r) => {
              res(r);
            });
          }
        );
        const linksPromise: ReturnType<typeof API.videos.getLinksByVideo> =
          new Promise((res) => {
            API.videos.getLinksByVideo(id).then((r) => {
              res(r);
            });
          });

        const [response, linksResponse] = await Promise.all([
          dataPromise,
          linksPromise,
        ]);
        dispatch(
          setVideoByIdAction({
            videoData: response.data,
            linksData: linksResponse.data,
          })
        );
        loadingRef.current = false;
      } catch (e) {
        dispatch(setVideoByIdError(true));
        dispatch(setVideoByIdLoading(false));
        loadingRef.current = false;
        message.error('Видео не найдено');
        navigate(Paths.UPLOADED_VIDEOS);
      }
    }
  }, [id, data]);

  useEffect(() => {
    if (
      (!loading && !loaded && !errorOnLoad && meLoaded) ||
      (id && loaded && data && data.id !== id && meLoaded)
    ) {
      if (!loadingRef.current) {
        void handleLoadVideo();
      }
    }
  }, [loaded, loading, errorOnLoad, meLoaded]);

  return [handleLoadVideo, id, linkId];
};
