import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import {
  BasePopover,
  BasePopoverMenuItemLabel,
  BasePopoverMenuItemText,
  BasePopoverMenuList,
  BasePopoverWrapper,
  TBasePopoverProps,
} from './base-popover';

type TVariantsProps = {
  onUploadWithLinkClick: () => void;
  onWithoutUploadClick: () => void;
};

const UploadVariants: FC<TVariantsProps> = ({
  onWithoutUploadClick,
  onUploadWithLinkClick,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  return (
    <BasePopoverWrapper
      style={{
        minWidth: 260,
      }}
    >
      <BasePopoverMenuList>
        <BasePopoverMenuItemLabel htmlFor="add-video-file">
          {t('videos.download.computer')}
        </BasePopoverMenuItemLabel>
        {/*<BasePopoverMenuItemText to={'/'} onClick={onUploadWithLinkClick}>
          {t('videos.download.url')}
        </BasePopoverMenuItemText>*/}
        <BasePopoverMenuItemText to={'/'} onClick={onWithoutUploadClick}>
          {t('videos.download.without')}
        </BasePopoverMenuItemText>
      </BasePopoverMenuList>
    </BasePopoverWrapper>
  );
};

type TProps = PropsWithChildren<TBasePopoverProps> & TVariantsProps;

export const UploadVideoVariantsPopover: FC<TProps> = ({
  children,
  onUploadWithLinkClick,
  onWithoutUploadClick,
  ...props
}) => {
  return (
    <BasePopover
      componentToShow={
        <UploadVariants
          onWithoutUploadClick={onWithoutUploadClick}
          onUploadWithLinkClick={onUploadWithLinkClick}
        />
      }
      {...props}
    >
      {children}
    </BasePopover>
  );
};
