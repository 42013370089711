import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const User: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 22.5C13.75 22.5 8.75 17.5 8.75 11.25C8.75 5.07812 13.75 0 20 0C26.1719 0 31.25 5.07812 31.25 11.25C31.25 17.5 26.1719 22.5 20 22.5ZM30 25C35.4688 25 40 29.5312 40 35V36.25C40 38.3594 38.2812 40 36.25 40H3.75C1.64062 40 0 38.3594 0 36.25V35C0 29.5312 4.45312 25 10 25H14.2969C16.0156 25.8594 17.9688 26.25 20 26.25C22.0312 26.25 23.9062 25.8594 25.625 25H30Z"
        fill={theme.COLORS.BLACK._700}
      />
    </svg>
  );
};

export default withTheme(User);
