import styled from '@emotion/styled';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import { usePopoversContext } from '../../services/popovers-service';
import { boolToText, textToBool } from '../../utils/common';

const Wrapper = styled('div')`
  position: relative;
`;

const Inner = styled('div')<{
  opened: 'true' | 'false';
  wrapperHeight: number;
}>`
  position: absolute;
  pointer-events: ${({ opened }) => (textToBool(opened) ? 'all' : 'none')};
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
  top: ${({ wrapperHeight }) => wrapperHeight + 14}px;
  right: 0;
  transform: translateY(${({ opened }) => (textToBool(opened) ? '0' : '10px')});
  opacity: ${({ opened }) => (textToBool(opened) ? '1' : '0')};
  z-index: 10;
`;

export const BasePopoverWrapper = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  padding-block: 8px;
  border-radius: 10px;
`;

export const BasePopoverMenuList = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const BasePopoverMenuItem = styled(NavLink)`
  padding: 8px 16px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  transition:
    background-color 0.3s ease-out,
    coloe 0.3s ease-out;
  text-decoration: none;
  font-size: 14px;
  line-height: 120%;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};
    background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  }
`;

export const BasePopoverMenuItemText = styled(
  BasePopoverMenuItem.withComponent('span')
)`
  cursor: pointer;
`;

export const BasePopoverMenuItemLabel = styled('label')`
  cursor: pointer;
  padding: 8px 16px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  transition:
    background-color 0.3s ease-out,
    coloe 0.3s ease-out;
  text-decoration: none;
  font-size: 14px;
  line-height: 120%;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};
    background-color: ${({ theme }) => theme.COLORS.BLACK._500};
  }
`;

export type TBasePopoverProps = {
  defaultOpened?: boolean;
  onVisibilityChange?: (opened: boolean) => void;
};

type TProps = {
  componentToShow: ReactNode;
} & TBasePopoverProps &
  PropsWithChildren;

export const BasePopover: FC<TProps> = memo(
  ({ children, componentToShow, defaultOpened, onVisibilityChange }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isShow, setIsShow] = useState(defaultOpened ?? false);
    const [wrapperHeight, setWrapperHeight] = useState(10);

    const popoverId = useId();

    const { initPopover, clearPopovers } = usePopoversContext();

    const handleChange = useCallback((opened: boolean) => {
      if (opened) {
        clearPopovers(popoverId);
      }

      setIsShow(opened);
      onVisibilityChange?.(opened);
    }, []);

    useLayoutEffect(() => {
      let timeOutId: NodeJS.Timeout;

      initPopover({
        id: popoverId,
        callback: () => {
          handleChange(false);
        },
      });

      const listenerOpen = () => {
        if (timeOutId) {
          clearTimeout(timeOutId);
        }

        handleChange(true);
      };

      const listenerClose = () => {
        if (timeOutId) {
          clearTimeout(timeOutId);
        }

        timeOutId = setTimeout(() => {
          handleChange(false);
        }, 600);
      };

      if (wrapperRef.current) {
        setWrapperHeight(wrapperRef.current.clientHeight);

        wrapperRef.current.addEventListener('mouseenter', listenerOpen);
        wrapperRef.current.addEventListener('click', listenerOpen);
        wrapperRef.current.addEventListener('mouseleave', listenerClose);
      }

      return () => {
        if (wrapperRef.current) {
          wrapperRef.current.removeEventListener('mouseenter', listenerOpen);
          wrapperRef.current.removeEventListener('click', listenerOpen);
          wrapperRef.current.removeEventListener('mouseleave', listenerClose);
        }
      };
    }, []);

    return (
      <Wrapper ref={wrapperRef}>
        {children}
        <Inner opened={boolToText(isShow)} wrapperHeight={wrapperHeight}>
          {componentToShow}
        </Inner>
      </Wrapper>
    );
  }
);
