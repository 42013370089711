import { FC } from 'react';

export const TooltipQuestionIcon: FC = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40136 8.10204V8.06122C5.4059 7.62812 5.45125 7.28345 5.53742 7.02721C5.62358 6.77098 5.74603 6.56349 5.90476 6.40476C6.06349 6.24603 6.25397 6.09977 6.47619 5.96599C6.60998 5.88435 6.73016 5.78798 6.83673 5.67687C6.94331 5.56349 7.02721 5.43311 7.08844 5.28571C7.15193 5.13832 7.18367 4.97506 7.18367 4.79592C7.18367 4.5737 7.13152 4.38095 7.02721 4.21769C6.9229 4.05442 6.78345 3.92857 6.60884 3.84014C6.43424 3.7517 6.24036 3.70748 6.02721 3.70748C5.84127 3.70748 5.66213 3.74603 5.4898 3.82313C5.31746 3.90023 5.17347 4.02154 5.05782 4.18708C4.94218 4.35261 4.87528 4.56916 4.85714 4.83673H4C4.01814 4.45125 4.11791 4.12132 4.29932 3.84694C4.48299 3.57256 4.72449 3.36281 5.02381 3.21769C5.3254 3.07256 5.65986 3 6.02721 3C6.4263 3 6.77324 3.07937 7.06803 3.2381C7.36508 3.39683 7.5941 3.61451 7.7551 3.89116C7.91837 4.1678 8 4.48299 8 4.83673C8 5.08617 7.96145 5.31179 7.88435 5.51361C7.80952 5.71542 7.70068 5.89569 7.55782 6.05442C7.41723 6.21315 7.24717 6.35374 7.04762 6.47619C6.84807 6.60091 6.68821 6.73243 6.56803 6.87075C6.44785 7.0068 6.36054 7.16893 6.30612 7.35714C6.2517 7.54535 6.22222 7.78005 6.21769 8.06122V8.10204H5.40136ZM5.83673 10.1156C5.66893 10.1156 5.52494 10.0556 5.40476 9.93537C5.28458 9.81519 5.22449 9.6712 5.22449 9.5034C5.22449 9.3356 5.28458 9.19161 5.40476 9.07143C5.52494 8.95125 5.66893 8.89116 5.83673 8.89116C6.00454 8.89116 6.14853 8.95125 6.26871 9.07143C6.38889 9.19161 6.44898 9.3356 6.44898 9.5034C6.44898 9.61451 6.42063 9.71655 6.36395 9.80952C6.30952 9.90249 6.23583 9.97732 6.14286 10.034C6.05215 10.0884 5.95011 10.1156 5.83673 10.1156Z"
        fill="#767676"
      />
      <circle cx="6" cy="6.5" r="5.5" stroke="#767676" />
    </svg>
  );
};
