import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FinanceHeader } from '../components/finance/finance-header';
import { FinancePlanPicker } from '../components/finance/finance-plan-picker';
import { FinanceTabs } from '../components/finance/finance-tabs';
import { FinanceTariffPlans } from '../components/finance/finance-tariff-plans';
import { LocaleKeys } from '../locale';
import { FinanceReportModal } from '../modals/finance/finance-report-modal';
import { FinanceSendContacts } from '../modals/finance/finance-send-contacts';
import { LegalFormModal } from '../modals/finance/legal-form-modal';
import { SelectPaymentMethodModal } from '../modals/finance/select-payment-method-modal';
import { useAppDispatch } from '../state';
import { setSelectedPlanInfoAction } from '../state/finance/legalReportCreateSlice';
import { EFinancePeriod, EPaymentMethod, EPlanName } from '../types/finance';

const currentPlan = EPlanName.STANDARD;
const currentPeriod = EFinancePeriod.MONTH;

export const FinancePage: FC = () => {
  const { t } = useTranslation([LocaleKeys.FINANCE]);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState<EFinancePeriod>(EFinancePeriod.MONTH);

  const [selectedTariffPlan, setSelectedTariffPlan] = useState<
    EPlanName | undefined
  >();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [legalFormModalOpen, setLegalFormModalOpen] = useState(false);
  const [financeReportModalOpen, setFinanceReportModalOpen] = useState(false);
  const [sendContactsModalOpen, setSendContactsModalOpen] = useState(false);

  const onPeriodChange = useCallback((value: EFinancePeriod) => {
    setPeriod(value);
  }, []);

  const selectNewTariffPlan = useCallback(
    (plan: EPlanName) => {
      setSelectedTariffPlan(plan);

      if (plan === EPlanName.CORP) {
        setSendContactsModalOpen(true);
      } else {
        setPaymentModalOpen(true);
      }
    },
    [period]
  );

  const onSelectPaymentMethod = useCallback(
    (method: EPaymentMethod) => {
      if (method === EPaymentMethod.Legal && selectedTariffPlan) {
        setLegalFormModalOpen(true);
        dispatch(
          setSelectedPlanInfoAction({
            plan: selectedTariffPlan,
            period: period,
          })
        );
      }
    },
    [selectedTariffPlan, period]
  );

  const handleGoBackToLegalEdit = useCallback(() => {
    setLegalFormModalOpen(true);
  }, []);

  const handleShowReport = useCallback(() => {
    setFinanceReportModalOpen(true);
  }, []);

  return (
    <div>
      <FinanceHeader
        currentPlan={currentPlan}
        currentType={currentPeriod}
        t={t}
      />
      <FinanceTabs t={t} />
      <FinancePlanPicker
        period={period}
        onPeriodChange={onPeriodChange}
        t={t}
      />
      <FinanceTariffPlans
        period={period}
        currentPlan={currentPlan}
        currentType={currentPeriod}
        selectNewTariffPlan={selectNewTariffPlan}
        t={t}
      />
      <SelectPaymentMethodModal
        open={paymentModalOpen}
        setOpen={setPaymentModalOpen}
        onNextStep={onSelectPaymentMethod}
        t={t}
      />
      <LegalFormModal
        open={legalFormModalOpen}
        setOpen={setLegalFormModalOpen}
        showReport={handleShowReport}
        t={t}
      />
      <FinanceReportModal
        setOpen={setFinanceReportModalOpen}
        open={financeReportModalOpen}
        backToEdit={handleGoBackToLegalEdit}
        t={t}
      />
      <FinanceSendContacts
        open={sendContactsModalOpen}
        setOpen={setSendContactsModalOpen}
        t={t}
      />
    </div>
  );
};
