import styled from '@emotion/styled';
import { Input, Typography } from 'antd';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { createVideoSetSecondFormAction } from '../../state/video/createSlice';
import { useCreateVideoSelector } from '../../state/video/selectors';

const Text = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

const TitleInput = styled(Input)`
  font-weight: 700;
  margin: 12px 0;
`;

type TProps = {
  isCreateMode?: boolean;
};

export const DescriptionEdit: FC<TProps> = ({ isCreateMode }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const {
    secondStage: { form },
  } = useCreateVideoSelector();

  const onChangeTitle: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (isCreateMode) {
        dispatch(
          createVideoSetSecondFormAction({
            title: e.target.value,
          })
        );
      }
    },
    [isCreateMode]
  );

  const onChangeDescription: ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        if (isCreateMode) {
          dispatch(
            createVideoSetSecondFormAction({
              description: e.target.value,
            })
          );
        }
      },
      [isCreateMode]
    );

  return (
    <div>
      <Text>{t('newVideo.title')}</Text>
      <TitleInput
        placeholder={t('newVideo.titlePlaceholder')}
        className="themed"
        onChange={onChangeTitle}
        value={isCreateMode ? form.title : undefined}
      />
      <Input.TextArea
        placeholder={t('newVideo.descriptionPlaceholder')}
        autoSize={{
          minRows: 4,
          maxRows: 8,
        }}
        className="themed"
        onChange={onChangeDescription}
        value={isCreateMode ? form.description : undefined}
      />
    </div>
  );
};
