import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const CopyIcon: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.25 0C38.2812 0 40 1.71875 40 3.75V26.25C40 28.3594 38.2812 30 36.25 30H13.75C11.6406 30 10 28.3594 10 26.25V3.75C10 1.71875 11.6406 0 13.75 0H36.25ZM13.75 32.5H30V36.25C30 38.3594 28.2812 40 26.25 40H3.75C1.64062 40 0 38.3594 0 36.25V13.75C0 11.7188 1.64062 10 3.75 10H7.5V26.25C7.5 29.7656 10.2344 32.5 13.75 32.5Z"
        fill={theme.COLORS.WHITE._100}
      />
    </svg>
  );
};

export default withTheme(CopyIcon);
