import App from 'antd/lib/app';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';

import { API } from '../../api';
import { useAppDispatch } from '../../state';
import { playListAllActions } from '../../state/playlists/listSlice';
import { usePlaylistAllSelector } from '../../state/playlists/selectors';
import { ESortOption } from '../../types/video';
import { PlaylistColItem } from './uploaded-items/playlist-col-item';
import { PlaylistItem } from './uploaded-items/playlist-item';

export const Playlists: FC<{
  sortOption: ESortOption;
  layout?: 'grid' | 'col';
}> = ({ sortOption, layout = 'grid' }) => {
  const { message } = App.useApp();
  const dispatch = useAppDispatch();
  const { loaded, pending, error, data } = usePlaylistAllSelector();
  const pendingRef = useRef(false);

  const handleLoadData = useCallback(async () => {
    dispatch(playListAllActions.startLoading());
    try {
      const data = await API.playlists.getAll({
        skip: 0,
        take: 50,
      });
      dispatch(playListAllActions.finishLoading(data.data));
      pendingRef.current = false;
    } catch (e) {
      dispatch(playListAllActions.errorLoading());
      message.error('Ошибка при загрузке плейлистов');
      pendingRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loaded && !pending && !error && !pendingRef.current) {
      pendingRef.current = true;
      void handleLoadData();
    }
  }, [loaded, pending, error]);

  const memoData = useMemo(() => {
    const dataToSort = [...data];

    if (!dataToSort || dataToSort.length === 0) {
      return [];
    }

    if (dataToSort.length === 1) {
      return [...dataToSort];
    }

    if (sortOption) {
      return dataToSort.sort(function (a, b) {
        if (sortOption === ESortOption.NEW) {
          return a.createdAt > b.createdAt ? 1 : -1;
        }

        return a.createdAt > b.createdAt ? -1 : 1;
      });
    }

    return [...dataToSort];
  }, [data, sortOption]);

  return (
    <Fragment>
      {memoData.map((el) =>
        layout === 'grid' ? (
          <PlaylistItem key={el.id} playListData={el} />
        ) : (
          <PlaylistColItem key={el.id} playListData={el} />
        )
      )}
    </Fragment>
  );
};
