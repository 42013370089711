import { RU_LANG_SUPPORT } from '../../constants';
import { TStateMigration } from '../../types/state';
import { IAppLocaleState, appLocaleInitialState } from './localeSlice';

export const appLangMigrations: TStateMigration<IAppLocaleState> = {
  0: async (state) => {
    if (
      !RU_LANG_SUPPORT &&
      (state.lang === 'ru' || state.variants.includes('ru'))
    ) {
      return {
        ...state,
        variants: appLocaleInitialState.variants,
        lang: 'en',
      };
    } else if (RU_LANG_SUPPORT && !state.variants.includes('ru')) {
      return {
        ...state,
        variants: appLocaleInitialState.variants,
      };
    }

    return {
      ...state,
    };
  },
};
