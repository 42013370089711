import styled from '@emotion/styled';
import { FC } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTitle = styled('span')`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledParagraph = styled('span')`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface DescriptionPreviewProps {
  title: string;
  content: string;
}

export const DescriptionPreview: FC<DescriptionPreviewProps> = ({
  title,
  content,
}) => {
  return (
    <Wrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledParagraph>{content}</StyledParagraph>
    </Wrapper>
  );
};
