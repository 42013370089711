import App from 'antd/lib/app';
import { useCallback, useEffect, useRef } from 'react';

import { API } from '../api';
import { useAppDispatch } from '../state';
import {
  setLinkListAction,
  setLinkListError,
  setLinkListLoading,
} from '../state/video/linksListSlice';
import { useLinksListSelector } from '../state/video/selectors';

type THookResponse = {
  handleLoadLinks: () => Promise<void>;
} & ReturnType<typeof useLinksListSelector>;

export const useLoadLinks: () => THookResponse = () => {
  const dispatch = useAppDispatch();
  const { loading, loaded, data, errorOnLoad } = useLinksListSelector();
  const loadingRef = useRef(loading);

  const { message } = App.useApp();

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const handleLoadLinks = useCallback(async () => {
    if (!loadingRef.current) {
      dispatch(setLinkListLoading(true));
      try {
        const response = await API.videos.getAllLinks();
        dispatch(setLinkListAction(response.data));
        loadingRef.current = false;
      } catch (e) {
        message.error('Ошибка при загрузке ссылок');
        dispatch(setLinkListError(true));
        dispatch(setLinkListLoading(false));
        loadingRef.current = false;
      }
    }
  }, []);

  useEffect(() => {
    if (!loading && !loaded && !loadingRef.current && !errorOnLoad) {
      setTimeout(() => {
        void handleLoadLinks();
      }, 0);
    }
  }, [loaded, loading, errorOnLoad]);

  return { data, loading, errorOnLoad, handleLoadLinks, loaded };
};
