import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const DuplicateIcon: FC<TIconBaseProps> = ({}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2857 9.42857C23.1964 9.42857 24 10.2321 24 11.1429V12.8571C24 13.8214 23.1964 14.5714 22.2857 14.5714H14.5714V22.2857C14.5714 23.25 13.7679 24 12.8571 24H11.1429C10.1786 24 9.42857 23.25 9.42857 22.2857V14.5714H1.71429C0.75 14.5714 0 13.8214 0 12.8571V11.1429C0 10.2321 0.75 9.42857 1.71429 9.42857H9.42857V1.71429C9.42857 0.803571 10.1786 0 11.1429 0H12.8571C13.7679 0 14.5714 0.803571 14.5714 1.71429V9.42857H22.2857Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default withTheme(DuplicateIcon);
