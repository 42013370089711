import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TLinkListed } from '../../types/responses/video';

type TState = {
  data: TLinkListed[];
  loaded: boolean;
  errorOnLoad: boolean;
  loading: boolean;
};

const initialState: TState = {
  data: [],
  loaded: false,
  errorOnLoad: false,
  loading: false,
};

const linksListSlice = createSlice({
  name: 'links/all',
  initialState,
  reducers: {
    setLinkListAction(state, action: PayloadAction<TLinkListed[]>) {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setLinkListError(state, action: PayloadAction<boolean>) {
      state.errorOnLoad = action.payload;
    },
    setLinkListLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetLinksListState: (state) => {
      state.data = [];
      state.loaded = false;
      state.loading = false;
      state.errorOnLoad = false;
    },
  },
});

export const {
  setLinkListAction,
  setLinkListLoading,
  setLinkListError,
  resetLinksListState,
} = linksListSlice.actions;

export const linksListReducer = linksListSlice.reducer;
