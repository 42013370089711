import { Rule } from 'antd/lib/form';
import { TFunction } from 'i18next';

export const GET_PASSWORD_BASE_RULES_FORM: (
  t: TFunction<string[]>
) => Rule[] = (t) => [
  {
    required: true,
    message: t('rules.required'),
  },
  {
    min: 8,
    message: t('rules.symbolsRequired'),
  },
];
