import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TMeResponse } from '../../types/users';

type InitialStateType = {
  meData?: TMeResponse;
  mePending: boolean;
  meLoaded: boolean;
  meError: boolean;
};

const initialState: InitialStateType = {
  meData: undefined,
  mePending: false,
  meLoaded: false,
  meError: false,
};

const meSlice = createSlice({
  name: 'me',
  initialState: initialState as InitialStateType,
  reducers: {
    startMe(state) {
      state.mePending = true;
      state.meLoaded = false;
      state.meError = false;
    },
    finishMe(state, action: PayloadAction<TMeResponse>) {
      state.mePending = false;
      state.meLoaded = true;
      state.meError = false;
      state.meData = action.payload;
    },
    errorMe(state) {
      state.mePending = false;
      state.meLoaded = false;
      state.meError = true;
    },
    resetMe(state) {
      state.mePending = false;
      state.meLoaded = false;
      state.meError = false;
      state.meData = undefined;
    },
  },
});

export const meActions = meSlice.actions;
export const meReducer = meSlice.reducer;
