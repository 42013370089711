import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { logoutAction } from '../../state/auth/slice';
import { useMeSelector } from '../../state/me/selectors';
import { Paths } from '../../types/common';
import {
  BasePopover,
  BasePopoverMenuItem,
  BasePopoverMenuList,
  BasePopoverWrapper,
  TBasePopoverProps,
} from './base-popover';
import { UserAvatar } from './user-avatar';

const Avatar = styled(UserAvatar)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-out;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._200};

  .header-avatar {
    width: 16px;
    height: 16px;
  }
`;

const UserInfoWrapper = styled('div')`
  padding: 10px 16px;
  display: flex;
  align-items: center;
`;

const UserNameWrapper = styled('div')`
  span {
    white-space: nowrap;
    font-size: 14px;
    display: block;
    min-width: 200px;

    &:first-of-type {
      color: ${({ theme }) => theme.COLORS.WHITE._100};
    }

    &:last-of-type {
      color: ${({ theme }) => theme.COLORS.BLACK._700};
    }
  }
`;

const ProfileComponent: FC = () => {
  const { t } = useTranslation([LocaleKeys.MENU]);
  const dispatch = useAppDispatch();
  const { meData } = useMeSelector();

  const userName =
    meData && meData.firstName
      ? [meData.lastName, meData.firstName, meData.middleName]
          .filter(Boolean)
          .join(' ')
      : t('menu.name');

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <BasePopoverWrapper>
      <UserInfoWrapper>
        <Avatar imgClassName="header-avatar" />
        <UserNameWrapper>
          <span>{userName}</span>
          <span>{meData?.email}</span>
        </UserNameWrapper>
      </UserInfoWrapper>
      <BasePopoverMenuList>
        <BasePopoverMenuItem to={Paths.ACCOUNT}>
          {t('popover.profile')}
        </BasePopoverMenuItem>
        {/*<BasePopoverMenuItem to={Paths.BASE}>*/}
        {/*  {t('popover.rates')}*/}
        {/*</BasePopoverMenuItem>*/}
        <BasePopoverMenuItem to={'/'} onClick={logout}>
          {t('popover.exit')}
        </BasePopoverMenuItem>
      </BasePopoverMenuList>
    </BasePopoverWrapper>
  );
};

export const ProfilePopover: FC<PropsWithChildren & TBasePopoverProps> = ({
  children,
  ...props
}) => {
  return (
    <BasePopover {...props} componentToShow={<ProfileComponent />}>
      {children}
    </BasePopover>
  );
};
