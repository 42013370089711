import styled from '@emotion/styled';
import { FC } from 'react';

type TProps = {
  value?: number;
  color: string;
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ProgressWrapper = styled('div')`
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._500};
`;

const ProgressInner = styled('div')<{
  percent: number;
}>`
  height: 4px;
  border-radius: 2px;
  width: ${({ percent }) => percent}%;
`;

const PercentText = styled('span')`
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

export const TableProgress: FC<TProps> = ({ value, color }) => {
  return (
    <Wrapper>
      <ProgressWrapper>
        <ProgressInner
          percent={value ?? 0}
          style={{
            backgroundColor: color,
          }}
        />
      </ProgressWrapper>
      <PercentText>{value ?? 0}%</PercentText>
    </Wrapper>
  );
};
