import App from 'antd/lib/app';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../api';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { useMeSelector } from '../state/me/selectors';
import {
  setVideoListAction,
  setVideoListError,
  setVideoListLoading,
} from '../state/video/listSlice';
import { useVideoListSelector } from '../state/video/selectors';

type THookResponse = {
  handleLoadVideos: () => Promise<void>;
} & ReturnType<typeof useVideoListSelector>;

export const useLoadVideos: () => THookResponse = () => {
  const dispatch = useAppDispatch();
  const { loading, loaded, data, errorOnLoad } = useVideoListSelector();
  const { meLoaded } = useMeSelector();
  const loadingRef = useRef(loading);
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const { message } = App.useApp();

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const handleLoadVideos = useCallback(async () => {
    if (!loadingRef.current) {
      dispatch(setVideoListLoading(true));
      try {
        const response = await API.videos.getAll();
        dispatch(setVideoListAction(response.data));
      } catch (e) {
        message.error(t('upload.error'));
        dispatch(setVideoListError(true));
        dispatch(setVideoListLoading(false));
      }
    }
  }, []);

  useEffect(() => {
    if (
      !loading &&
      !loaded &&
      !loadingRef.current &&
      !errorOnLoad &&
      meLoaded
    ) {
      setTimeout(() => {
        void handleLoadVideos();
      }, 0);
    }
  }, [loaded, loading, errorOnLoad, meLoaded]);

  return { data, loading, errorOnLoad, handleLoadVideos, loaded };
};
