import { useParams } from 'react-router-dom';

export const PLAYLIST_ID_PARAM_NAME = 'playListId';

export function usePlayListPathId(): string | undefined {
  const params = useParams<{
    [PLAYLIST_ID_PARAM_NAME]: string;
  }>();

  return params[PLAYLIST_ID_PARAM_NAME];
}
