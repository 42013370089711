import styled from '@emotion/styled';
import { Form, Input, Modal, Typography } from 'antd';
import App from 'antd/lib/app';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useUserAccountSelector } from '../../state/auth/selectors';
import { Paths } from '../../types/common';
import { GET_PASSWORD_BASE_RULES_FORM } from '../../utils/forms';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type TPasswordForm = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

const Paragraph = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.COLORS.WHITE._200};
`;

const ForgotButton = styled('span')`
  color: ${({ theme }) => theme.COLORS.WHITE._600};
  text-decoration: underline;
  cursor: pointer;
`;

export const AccountPasswordModal: FC<TProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const account = useUserAccountSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const onFormFinish = async (values: TPasswordForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    if (values.newPassword !== values.repeatNewPassword) {
      void message.error(t('password.error'));
      return;
    }

    try {
      setLoading(true);

      await API.me.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });

      message.success(t('password.changing.success'));
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('password.changing.error'));
      setLoading(false);
    }
  };

  const handleForgot = useCallback(() => {
    navigate(Paths.FORGOT_PASSWORD);
  }, []);

  return (
    <Modal
      title={t('password.title')}
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Paragraph>{t('password.change')}</Paragraph>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFormFinish}
        requiredMark={false}
      >
        <Form.Item
          name="oldPassword"
          label={t('password.old')}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
          ]}
        >
          <Input.Password className="themed" type="password" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={t('password.new')}
          rules={[...GET_PASSWORD_BASE_RULES_FORM(t)]}
        >
          <Input.Password className="themed" type="new-password" />
        </Form.Item>
        <Form.Item
          name="repeatNewPassword"
          label={t('password.repeatNew')}
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('password.error')));
              },
            }),
          ]}
        >
          <Input.Password className="themed" type="new-password" />
        </Form.Item>
      </Form>
      <Paragraph>
        <ForgotButton onClick={handleForgot}>
          {t('password.forgot')}
        </ForgotButton>
      </Paragraph>
    </Modal>
  );
};
