import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export enum ESimpleDateKey {
  DAY = 'today',
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  PERIOD = 'period',
}

export const getAnalyticsSimpleDatesFilterList: (t: TFunction<string[]>) => {
  name: string;
  dateStart: string;
  dateEnd: string;
  key: ESimpleDateKey;
}[] = (t) => [
  {
    name: t('periods.today'),
    dateStart: dayjs().startOf('day').toISOString(),
    dateEnd: dayjs().endOf('day').toISOString(),
    key: ESimpleDateKey.DAY,
  },
  {
    name: t('periods.yesterday'),
    dateStart: dayjs().add(-1, 'days').startOf('day').toISOString(),
    dateEnd: dayjs().add(-1, 'days').endOf('day').toISOString(),
    key: ESimpleDateKey.YESTERDAY,
  },
  {
    name: t('periods.week'),
    dateStart: dayjs().startOf('week').toISOString(),
    dateEnd: dayjs().endOf('week').toISOString(),
    key: ESimpleDateKey.WEEK,
  },
  {
    name: t('periods.month'),
    dateStart: dayjs().startOf('month').toISOString(),
    dateEnd: dayjs().endOf('month').toISOString(),
    key: ESimpleDateKey.MONTH,
  },
  {
    name: t('periods.quarter'),
    dateStart: dayjs().startOf('quarter').toISOString(),
    dateEnd: dayjs().endOf('quarter').toISOString(),
    key: ESimpleDateKey.QUARTER,
  },
  {
    name: t('periods.year'),
    dateStart: dayjs().endOf('year').toISOString(),
    dateEnd: dayjs().endOf('year').toISOString(),
    key: ESimpleDateKey.YEAR,
  },
];
