export function sortByDateField<T>(
  a: T,
  b: T,
  field: keyof T,
  negative?: boolean
): number {
  return negative
    ? new Date(a[field] as string).getTime() -
        new Date(b[field] as string).getTime()
    : new Date(b[field] as string).getTime() -
        new Date(a[field] as string).getTime();
}
