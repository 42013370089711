import { useParams } from 'react-router-dom';

export const RESET_TOKEN_PARAM = 'resetToken';

export function useResetPasswordTokenPath(): string | undefined {
  const params = useParams<{
    [RESET_TOKEN_PARAM]: string;
  }>();

  return params[RESET_TOKEN_PARAM];
}
