import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Settings } from '../components/video-page/settings';

export const CreateLink: FC<{
  videoId: string;
  isEditMode?: boolean;
}> = ({ videoId, isEditMode }) => {
  const { linkId } = useParams();

  if (!linkId && isEditMode) {
    return null;
  }

  return (
    <div>
      <Settings videoId={videoId} viewMode="create" linkToEdit={linkId} />
    </div>
  );
};
