import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC } from 'react';

import { boolToText, textToBool } from '../../../utils/common';
import { StatIncreaseMarker } from '../../icons/stat-increase-marker';
import { ESimpleDateKey } from '../dates-list';

const Wrapper = styled.div`
  border-radius: 10px;
  padding: 16px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  min-height: 180px;
  display: flex;
  flex-direction: column;
`;

const Title = styled('span')`
  font-size: 16px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  flex: 1;
`;

const Value = styled('span')`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

const StatWrapper = styled('div')<{
  isNegative: 'true' | 'false';
}>`
  display: flex;
  align-items: center;

  svg {
    transform: rotate(
      ${({ isNegative }) => (textToBool(isNegative) ? '180deg' : '0')}
    );
    width: 16px;
    height: 16px;

    path {
      fill: ${({ isNegative, theme }) =>
        textToBool(isNegative)
          ? theme.COLORS.DANGER
          : theme.COLORS.ACCENT._200};
    }
  }

  span {
    color: ${({ isNegative, theme }) =>
      textToBool(isNegative) ? theme.COLORS.DANGER : theme.COLORS.ACCENT._200};
  }
`;

const StatText = styled('span')`
  font-size: 14px;
  padding-left: 4px;
`;

type TProps = {
  label: string;
  value: string;
  statChange: number | false;
  negative?: boolean;
  t: TFunction<string[]>;
  period?: ESimpleDateKey;
};

export const StatCard: FC<TProps> = ({
  label,
  value,
  statChange,
  negative,
  t,
  period = ESimpleDateKey.PERIOD,
}) => {
  return (
    <Wrapper>
      <Title>{label}</Title>
      <Value>{value}</Value>
      {statChange !== false && (
        <StatWrapper isNegative={boolToText(negative)}>
          <StatIncreaseMarker />
          <StatText>
            {t(`mainStats.valuePer_${period}`, {
              value: statChange,
            })}
          </StatText>
        </StatWrapper>
      )}
    </Wrapper>
  );
};
