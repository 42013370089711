import styled from '@emotion/styled';
import { Form, Input, Modal, Typography } from 'antd';
import App from 'antd/lib/app';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { useUserAccountSelector } from '../../state/auth/selectors';
import { useMeSelector } from '../../state/me/selectors';
import { meActions } from '../../state/me/slice';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type TEmailForm = {
  email: string;
};

const Paragraph = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.COLORS.WHITE._200};

  span.mark {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }
`;

export const AccountEmailModal: FC<TProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const account = useUserAccountSelector();
  const { meData } = useMeSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const onFormFinish = async (values: TEmailForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    try {
      setLoading(true);

      await API.me.changeEmail(values.email);
      message.success(t('email.changing.success'));
      dispatch(meActions.resetMe());
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('email.changing.error'));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('email.title')}
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFormFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            {
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              message: t('rules.valid'),
            },
          ]}
        >
          <Input className="themed" type={'email'} />
        </Form.Item>
      </Form>
      <Paragraph>
        {t('email.check.start')}
        <span className="mark">{meData?.email}</span>
        {t('email.check.end')}
      </Paragraph>
      <Paragraph>{t('email.change')}</Paragraph>
      <Paragraph>
        {t('email.support.title')}
        <NavLink to={'/'}>
          <span className="mark">{t('email.support.link')}</span>
        </NavLink>
        .
      </Paragraph>
    </Modal>
  );
};
