import styled from '@emotion/styled';
import { Button, Empty, message } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { API } from '../../../api';
import { useVideoPathId } from '../../../hooks/use-video-path-id';
import { LocaleKeys } from '../../../locale';
import { useAppDispatch } from '../../../state';
import {
  addLinkAction,
  deleteLinkAction,
} from '../../../state/video/byIdSlice';
import { useVideoSelector } from '../../../state/video/selectors';
import { getCreateLinkPath } from '../../../utils/paths';
import { VideoLink } from './video-link';

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 64px;
`;

const Wrapper = styled('div')`
  padding-right: 40px;
`;

export const LinksList: FC = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const { linksData, loading } = useVideoSelector();
  const id = useVideoPathId();
  const dispatch = useAppDispatch();

  const handleDeleteLink = useCallback(
    async (id: string) => {
      const loading = message.loading(t('video.link.deleting.loading'), 0);

      try {
        await API.videos.deleteLink(id);

        loading();
        message.success(t('video.link.deleting.success'));
        dispatch(deleteLinkAction(id));
      } catch (e) {
        loading();
        message.error(t('video.link.deleting.error'));
      }
    },
    [t]
  );

  const handleDuplicate = useCallback(
    async (linkId: string) => {
      if (linksData && !loading && id) {
        const loading = message.loading(t('video.link.duplicate.loading'), 0);

        try {
          const linkToCopy = linksData.find((el) => el.id === linkId);

          if (!linkToCopy) {
            throw new Error(t('video.link.duplicate.notFound'));
          }

          const response = await API.videos.createLink(
            {
              videoId: id,
            },
            {
              viewingGuarantee: linkToCopy.videoSetting.viewingGuarantee,
              volumeLimit: linkToCopy.videoSetting.volumeLimit,
              volumeLimitValue: linkToCopy.videoSetting.volumeLimitValue,
              increaseAttention: linkToCopy.videoSetting.increaseAttention,
              increaseAttentionValue:
                linkToCopy.videoSetting.increaseAttentionValue,
              rewindOption: linkToCopy.videoSetting.rewindOption,
              distanceControl: linkToCopy.videoSetting.distanceControl,
              videoSpeedControl: linkToCopy.videoSetting.videoSpeedControl,
              videoRecommendations:
                linkToCopy.videoSetting.videoRecommendations,
              minimumAttentionLimit:
                linkToCopy.videoSetting.minimumAttentionLimit,
              minimumAttentionLimitValue:
                linkToCopy.videoSetting.minimumAttentionLimitValue,
              attentionLimit: linkToCopy.videoSetting.attentionLimit,
              title:
                linkToCopy.videoSetting.title + t('video.link.duplicate.copy'),
              playerMode: linkToCopy.videoSetting.playerMode,
              captions: linkToCopy.videoSetting.captions,
              captionsEnabled: linkToCopy.videoSetting.captionsEnabled,
            }
          );

          loading();
          message.success(t('video.link.duplicate.success'));
          dispatch(addLinkAction(response.data));
        } catch (e) {
          loading();
          message.error(t('video.link.duplicate.error'));
        }
      } else {
        message.warning(t('video.link.duplicate.warning'));
      }
    },
    [linksData, id, t]
  );

  return (
    <Wrapper>
      {linksData?.map((el, index) => (
        <VideoLink
          key={el.id}
          {...el}
          index={index}
          handleDeleteLink={handleDeleteLink}
          handleDuplicate={handleDuplicate}
        />
      )) ?? <Empty description={t('video.link.noAvailable')} />}
      <NavLink to={getCreateLinkPath(id ?? '404')}>
        <StyledButton type="primary" size="large">
          {t('video.link.create')}
        </StyledButton>
      </NavLink>
    </Wrapper>
  );
};
