import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TServerError } from '../../types/responses/core';
import { TAuthJWT } from '../../types/users';
import { parseJwt } from '../../utils/common';

export type TAuthState = {
  authed: boolean;
  data?: TAuthJWT;
  error?: TServerError;
};

const initialState: TAuthState = {
  authed: false,
  data: undefined,
  error: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    loginAction(state, action: PayloadAction<string>) {
      state.authed = true;
      state.data = parseJwt<TAuthJWT>(action.payload);
      state.error = undefined;
    },
    logoutAction() {
      localStorage.clear();
      window.location.reload();
    },
    setAuthErrorAction(state, action: PayloadAction<TServerError>) {
      state.error = action.payload;
    },
  },
});

export const { logoutAction, loginAction, setAuthErrorAction } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
