import { PauseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import Slider from 'antd/lib/slider';
import { forwardRef, useEffect, useState } from 'react';

import { boolToText, textToBool } from '../../utils/common';
import { VideoPlayFilled } from '../icons/video-play-filled';
import { Volume } from '../icons/volume';

type TProps = {
  play: boolean;
  togglePlay: () => void;
  controlsVisible: boolean;
};

const Wrapper = styled('div')<{ visible: 'true' | 'false' }>`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  transform: ${({ visible }) =>
    textToBool(visible) ? 'unset' : 'translateY(10px)'};
  opacity: ${({ visible }) => (textToBool(visible) ? 1 : 0)};
  pointer-events: ${({ visible }) => (textToBool(visible) ? 'all' : 'none')};
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
`;

const PlayButton = styled('div')`
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  font-size: 20px;

  .icon {
    transform: translateX(3px);
  }
`;

const VolumeWrapper = styled('div')`
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 30px;
    height: 30px;
  }

  .slider {
    width: 50px;

    .ant-slider-handle {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    .ant-slider-track {
      background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
      border-radius: 2px;
    }

    .ant-slider-rail {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
    }

    .ant-slider-handle {
      width: 5px;
      height: 5px;
    }
  }

  &:hover {
    .ant-slider-handle {
      opacity: 1;
    }
  }
`;

export const Controls = forwardRef<HTMLVideoElement, TProps>(
  ({ play, togglePlay, controlsVisible }, videoRef) => {
    const [volume, setVolume] = useState(100);

    useEffect(() => {
      if (videoRef && 'current' in videoRef && videoRef.current) {
        videoRef.current.volume = volume / 100;
      }
    }, [volume]);

    return (
      <Wrapper visible={boolToText(controlsVisible)}>
        <PlayButton onClick={togglePlay}>
          {play ? <PauseOutlined /> : <VideoPlayFilled className="icon" />}
        </PlayButton>
        <VolumeWrapper>
          <Volume />
          <Slider
            className="slider"
            value={volume}
            onChange={setVolume}
            min={0}
            max={100}
          />
        </VolumeWrapper>
      </Wrapper>
    );
  }
);
