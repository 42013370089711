import { Theme, withTheme } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';

type TProps = {
  theme: Theme;
};

const ArrowBack: FC<TProps & HTMLAttributes<HTMLOrSVGElement>> = ({
  theme,
  ...props
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0469 19.9062L12.0156 20.9375C11.5469 21.3594 10.8438 21.3594 10.4219 20.9375L1.32812 11.7969C0.859375 11.375 0.859375 10.6719 1.32812 10.25L10.4219 1.10938C10.8438 0.6875 11.5938 0.6875 12.0156 1.10938L13.0469 2.14062C13.5156 2.60938 13.4688 3.3125 13.0469 3.78125L7.375 9.125H20.875C21.4844 9.125 22 9.64062 22 10.25V11.75C22 12.4062 21.4844 12.875 20.875 12.875H7.375L13.0469 18.2656C13.4688 18.7344 13.5156 19.4375 13.0469 19.9062Z"
        fill={theme.COLORS.BLACK._800}
      />
    </svg>
  );
};

export default withTheme(ArrowBack);
