import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC, useMemo } from 'react';

import { tariffPlans } from '../../constants/tariff-plans';
import { useLocaleSelector } from '../../state/locale/selector';
import { EFinancePeriod, EPlanName, TFinanceClear } from '../../types/finance';
import {
  getPlanPriceByPeriodAndLocale,
  mapTariffFeaturesToArray,
} from '../../utils/finance';
import { TariffPlan } from './tariff-plan';

const Wrapper = styled.div<{
  itemsCount: number;
}>`
  margin-top: 40px;
  padding-right: 20px;
  padding-bottom: 40px;
  display: flex;
  gap: 20px;

  & > div {
    flex-basis: ${({ itemsCount }) => 100 / itemsCount}%;
  }
`;

type TProps = {
  period: EFinancePeriod;
  currentPlan: EPlanName;
  currentType: EFinancePeriod;
  selectNewTariffPlan: (plan: EPlanName) => void;
  t: TFunction<string[]>;
};

export const FinanceTariffPlans: FC<TProps> = ({
  period,
  currentPlan,
  currentType,
  selectNewTariffPlan,
  t,
}) => {
  const { lang } = useLocaleSelector();

  const plansList: TFinanceClear[] = useMemo(() => {
    return tariffPlans.map((el) => ({
      name: el.name,
      type: el.type,
      price: getPlanPriceByPeriodAndLocale(period, lang, {
        pricePerMonth: el.pricePerMonth,
        pricePerYear: el.pricePerYear,
      }),
      features: mapTariffFeaturesToArray(el.features),
    }));
  }, [period, lang]);

  return (
    <Wrapper itemsCount={plansList.length}>
      {plansList.map((el) => (
        <TariffPlan
          key={el.name}
          planInfo={el}
          lang={lang}
          t={t}
          period={period}
          current={el.name === currentPlan && currentType === period}
          onSelect={() => selectNewTariffPlan(el.name)}
          special={[EPlanName.BUSINESS, EPlanName.CORP].includes(el.name)}
        />
      ))}
    </Wrapper>
  );
};
