import { TFunction } from 'i18next';

import Analytics from '../components/icons/analytics';
import Finance from '../components/icons/finance';
import User from '../components/icons/user';
import Video from '../components/icons/video';
import { Paths, TMenuItem } from '../types/common';

export const appMainMenu: (t: TFunction<string[]>) => TMenuItem[] = (t) => [
  {
    path: Paths.BASE,
    activePathTrigger: [
      Paths.UPLOADED_VIDEOS,
      Paths.PLAYLIST,
      Paths.VIDEO_CREATE,
      Paths.VIDEO,
    ],
    Icon: Video,
    text: t('menu.videos'),
  },
  // {
  //   path: Paths.VIEWED_VIDEOS,
  //   activePathTrigger: [Paths.VIEWED_VIDEOS],
  //   Icon: Video,
  //   text: `Просмотренные\nвидео`,
  // },
  {
    path: Paths.ANALYTICS,
    activePathTrigger: [Paths.ANALYTICS],
    Icon: Analytics,
    text: t('menu.analytics'),
  },
  {
    path: Paths.FINANCE,
    activePathTrigger: [Paths.FINANCE],
    Icon: Finance,
    text: t('menu.finance'),
  },
  {
    path: Paths.ACCOUNT,
    activePathTrigger: [Paths.ACCOUNT],
    Icon: User,
    text: t('menu.profile'),
  },
];
