import styled from '@emotion/styled';
import Slider from 'antd/lib/slider';
import { FC } from 'react';

import { FormBlock } from '../common/form-block';

const Wrapper = styled(FormBlock)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  text-align: left;
`;

const Name = styled('span')`
  display: flex;
`;

const SlideWrapper = styled('div')`
  width: 180px;
`;

type TProps = {
  label: string;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  value?: number;
};

export const SlideInput: FC<TProps> = ({
  label,
  min,
  max,
  value,
  onChange,
}) => {
  const marks: Record<number, string> =
    min !== undefined && max !== undefined
      ? {
          [min]: min.toString(),
          [max]: max.toString(),
        }
      : {};

  return (
    <Wrapper>
      <Name>{label}</Name>
      <SlideWrapper>
        <Slider marks={marks} value={value} onChange={onChange} />
      </SlideWrapper>
    </Wrapper>
  );
};
