import styled from '@emotion/styled';
import React, { JSX } from 'react';

import { tableStyle } from './styles';
import { TableEmpty } from './table-empty';
import { TableHeader } from './table-header';
import { TableLoader } from './table-loader';
import { TableRow } from './table-row';
import { TableProps } from './types';

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
`;

export const Table: <T extends object>(props: TableProps<T>) => JSX.Element = ({
  columns,
  rows,
  loading,
  emptyText,
  sortedData,
  setSortedData,
}) => {
  return (
    <Wrapper>
      <table
        css={tableStyle((loading && rows.length === 0) || rows.length === 0)}
      >
        <TableHeader
          columns={columns}
          sortedData={sortedData}
          setSortedData={setSortedData}
        />
        <tbody>
          {rows.map((row) => (
            <TableRow key={row.id} row={row} columns={columns} depth={0} />
          ))}
        </tbody>
      </table>
      {loading && <TableLoader />}
      {rows.length === 0 && !loading && <TableEmpty description={emptyText} />}
    </Wrapper>
  );
};
