import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EFinancePeriod, EPlanName, TLegalForm } from '../../types/finance';

type TState = {
  legalForm?: TLegalForm;
  selectedPlan?: EPlanName;
  selectedPeriod?: EFinancePeriod;
};

const initialState: TState = {};

const legalReportCreateSlice = createSlice({
  name: 'finance/create-legal-report',
  initialState: { ...initialState },
  reducers: {
    setSelectedPlanInfoAction(
      state,
      action: PayloadAction<{
        plan: EPlanName;
        period: EFinancePeriod;
      }>
    ) {
      state.selectedPeriod = action.payload.period;
      state.selectedPlan = action.payload.plan;
    },
    setLegalFormAction(state, action: PayloadAction<TLegalForm>) {
      state.legalForm = action.payload;
    },
    resetLegalReportCreateAction(state) {
      state = { ...initialState };
    },
  },
});

export const { setSelectedPlanInfoAction, setLegalFormAction } =
  legalReportCreateSlice.actions;
export const legalReportCreateReducer = legalReportCreateSlice.reducer;
