import { FC } from 'react';

export const ArrowTopIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35355 2.14645C8.15829 1.95118 7.84171 1.95118 7.64645 2.14645L4.46447 5.32843C4.2692 5.52369 4.2692 5.84027 4.46447 6.03553C4.65973 6.2308 4.97631 6.2308 5.17157 6.03553L8 3.20711L10.8284 6.03553C11.0237 6.2308 11.3403 6.2308 11.5355 6.03553C11.7308 5.84027 11.7308 5.52369 11.5355 5.32843L8.35355 2.14645ZM8.5 14.5L8.5 2.5H7.5L7.5 14.5H8.5Z"
        fill="#767676"
      />
    </svg>
  );
};
