import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const Video: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="46"
      height="30"
      viewBox="0 0 46 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.75 0C28.7812 0 30.5 1.71875 30.5 3.75V26.3281C30.5 28.3594 28.7812 30 26.75 30H4.17188C2.14062 30 0.5 28.3594 0.5 26.3281V3.75C0.5 1.71875 2.14062 0 4.17188 0H26.75ZM41.5156 2.96875C43.1562 1.875 45.5 3.04688 45.5 5V25.0781C45.5 27.0312 43.1562 28.2031 41.5156 27.1094L33 21.1719V8.90625L41.5156 2.96875Z"
        fill={theme.COLORS.BLACK._700}
      />
    </svg>
  );
};

export default withTheme(Video);
