import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  right: -6px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.COLORS.ACCENT._200};
  font-size: 32px;
  border-radius: 6px;
`;

type TLoaderProps = {} & HTMLAttributes<HTMLDivElement>;

export const TableLoader: FC<TLoaderProps> = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <LoadingOutlined />
    </Wrapper>
  );
};
