import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const SettingsIcon: FC<TIconBaseProps> = ({}) => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6842 15.6557C23.9368 15.8085 24.0379 16.1141 23.9874 16.3687C23.3811 18.2023 22.4716 19.8322 21.2085 21.2073C21.0064 21.4111 20.7033 21.462 20.4506 21.3092L18.2781 20.0868C17.3687 20.8508 16.3582 21.462 15.2466 21.8695V24.3652C15.2466 24.6198 15.0445 24.8745 14.7414 24.9763C12.973 25.3329 11.1036 25.3838 9.23419 24.9763C8.98157 24.8745 8.77947 24.6198 8.77947 24.3652V21.8695C7.61741 21.462 6.60692 20.8508 5.69747 20.0868L3.52492 21.3092C3.27229 21.462 2.96915 21.4111 2.76705 21.2073C1.55446 19.8322 0.594493 18.2023 0.0387224 16.3687C-0.0623268 16.1141 0.0387224 15.8085 0.291345 15.6557L2.4639 14.4333C2.2618 13.2109 2.2618 12.0395 2.4639 10.8171L0.291345 9.59471C0.0387224 9.44191 -0.0623268 9.13631 0.0387224 8.88165C0.594493 7.04808 1.55446 5.41823 2.76705 4.04305C2.96915 3.83932 3.27229 3.78839 3.52492 3.94119L5.69747 5.2145C6.60692 4.39958 7.61741 3.78839 8.77947 3.38093V0.885234C8.77947 0.630571 8.93105 0.375908 9.23419 0.324976C11.0026 -0.082485 12.872 -0.133418 14.7414 0.324976C15.0445 0.375908 15.2466 0.630571 15.2466 0.885234V3.38093C16.3582 3.78839 17.3687 4.39958 18.2781 5.16357L20.4506 3.94119C20.7033 3.78839 21.0064 3.83932 21.2085 4.04305C22.4211 5.41823 23.3811 7.04808 23.9368 8.88165C24.0379 9.13631 23.9368 9.44191 23.6842 9.59471L21.5117 10.8171C21.7643 12.0395 21.7643 13.2109 21.5117 14.4333L23.6842 15.6557ZM12.013 16.6743C14.2361 16.6743 16.055 14.8917 16.055 12.5997C16.055 10.3587 14.2361 8.52512 12.013 8.52512C9.73944 8.52512 7.97108 10.3587 7.97108 12.5997C7.97108 14.8917 9.73944 16.6743 12.013 16.6743Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default withTheme(SettingsIcon);
