import { useCallback } from 'react';

import { API } from '../api';
import { useAppDispatch } from '../state';
import { playlistCurrentActions } from '../state/playlists/currentSlice';

export const useHandleLoadPlaylist = () => {
  const dispatch = useAppDispatch();

  return useCallback(async (idToLoad: string) => {
    dispatch(playlistCurrentActions.startLoading());
    try {
      const response = await API.playlists.getById(idToLoad);
      dispatch(playlistCurrentActions.finishLoading(response.data));
    } catch (e) {
      dispatch(playlistCurrentActions.errorLoading());
    }
  }, []);
};
