import { useCallback, useEffect, useRef } from 'react';

import { API } from '../api';
import { ESimpleDateKey } from '../components/analytics/dates-list';
import { useAppDispatch } from '../state';
import {
  errorAnalyticsAction,
  finishAnalyticsAction,
  setNeedLoadAnalyticsAction,
  startAnalyticsAction,
} from '../state/analytics/dataSlice';
import {
  TAllStatLocal,
  TDynamicFilters,
  errorDynamicsAction,
  finishDynamicsAction,
  startDynamicsAction,
} from '../state/analytics/dynamicsSlice';
import {
  useAnalyticsDataSelector,
  useAnalyticsFilterSelector,
  useDynamicsDataSelector,
} from '../state/analytics/selectors';
import { TAnalyticsFilter } from '../types/responses/analytics';

let isPending = false;

export const useLoadAllAnalytics = () => {
  const dispatch = useAppDispatch();
  const { data, loaded, error, pending } = useAnalyticsDataSelector();
  const { filters, pending: dynamicsPending } = useDynamicsDataSelector();

  const loadRef = useRef(pending);
  loadRef.current = pending;
  const loadDynamicsRef = useRef(dynamicsPending);
  loadDynamicsRef.current = dynamicsPending;

  const { startDate, endDate, selectedSimpleDateKey } =
    useAnalyticsFilterSelector();

  const handleLoadDynamics = useCallback(
    async (videoListStats: TAllStatLocal) => {
      if (
        (endDate &&
          startDate &&
          (filters?.fromDate !== startDate || filters?.toDate !== endDate)) ||
        (selectedSimpleDateKey &&
          selectedSimpleDateKey !== filters?.period &&
          selectedSimpleDateKey !== ESimpleDateKey.PERIOD &&
          !loadDynamicsRef.current)
      ) {
        loadDynamicsRef.current = true;

        try {
          dispatch(startDynamicsAction());

          let filters: TDynamicFilters = {
            allWatchedCount: videoListStats.allWatchedCount,
            viewingDuration: videoListStats.viewingDuration,
            uploadVideosCount: videoListStats.uploadVideosCount,
            watchedTillEndCount: videoListStats.watchedTillEndCount,
          };

          if (endDate && startDate) {
            filters = {
              ...filters,
              fromDate: startDate,
              toDate: endDate,
            };
          }

          if (selectedSimpleDateKey !== ESimpleDateKey.PERIOD) {
            filters.period = selectedSimpleDateKey;
            delete filters.fromDate;
            delete filters.toDate;
          }

          const response = await API.analytics.getDynamics(filters);

          dispatch(
            finishDynamicsAction({
              data: response.data,
              filters,
              allStats: videoListStats,
            })
          );
        } catch (e) {
          dispatch(errorDynamicsAction());
        } finally {
          loadDynamicsRef.current = false;
        }
      }
    },
    [filters, selectedSimpleDateKey, startDate, endDate]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (!loadRef.current && !pending && !isPending) {
        isPending = true;

        dispatch(startAnalyticsAction());
        loadRef.current = true;

        const params: TAnalyticsFilter = {
          fromDate: startDate,
          toDate: endDate,
        };

        if (selectedSimpleDateKey !== ESimpleDateKey.PERIOD) {
          params.byPeriod = selectedSimpleDateKey;
          delete params.fromDate;
          delete params.toDate;
        }

        const response = await API.analytics.getAll(params);

        dispatch(finishAnalyticsAction(response.data));

        let seconds = 0;

        response.data.linksStats.forEach((el) => {
          const usersByLink = response.data?.usersLinksStats[el.linkId];
          if (usersByLink) {
            usersByLink.forEach((user) => {
              seconds += user.viewingDuration;
            });
          }
        });

        await handleLoadDynamics({
          viewingDuration: seconds,
          watchedTillEndCount: response.data.watchedTillEndCount,
          allWatchedCount: response.data.videosStats.reduce(
            (acc, cur) => acc + cur.views,
            0
          ),
          uploadVideosCount: response.data.videosStats.length,
        });
        isPending = false;
      }
    } catch (e) {
      isPending = false;
      dispatch(errorAnalyticsAction());
    } finally {
      loadRef.current = false;
    }
  }, [
    startDate,
    endDate,
    data,
    selectedSimpleDateKey,
    handleLoadDynamics,
    pending,
  ]);

  useEffect(() => {
    if (!loadRef.current && !pending && !loaded) {
      void handleLoad();
    }
  }, [handleLoad, pending, loaded]);

  useEffect(() => {
    if (!loadRef.current && !isPending) {
      dispatch(setNeedLoadAnalyticsAction());
    }
  }, [startDate, endDate]);

  return { data, loaded, pending, error };
};
