import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import emptyVideoPreview from '../../../assets/empty-video.svg';
import { LocaleKeys } from '../../../locale';
import { useLocaleSelector } from '../../../state/locale/selector';
import { TCreatedVideo } from '../../../types/responses/video';
import { boolToText, timeToString } from '../../../utils/common';
import { getVideoItemPath } from '../../../utils/paths';
import { pluralize } from '../../../utils/text-formatters';
import {
  Footer,
  Preview,
  SelectInner,
  SelectWrapper,
  TimingWrapper,
  Title,
  Wrapper,
  WrapperSelectable,
} from './components';

type TProps = {
  videoData: TCreatedVideo;
  editMode?: boolean;
  handleChangeVideoList?: (id: string) => void;
  selectedVideos?: string[];
};

export const VideoItem: FC<TProps> = ({
  videoData: { id, createdAt, viewsCount, title, videoPreview, duration },
  handleChangeVideoList,
  selectedVideos,
  editMode,
}) => {
  const curLanguage = useLocaleSelector();
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const isSelected = useMemo(
    () => selectedVideos && selectedVideos.includes(id),
    [selectedVideos, id]
  );

  const handleSelect = useCallback(() => {
    if (handleChangeVideoList) {
      handleChangeVideoList(id);
    }
  }, [handleChangeVideoList, id]);

  const havePreview = !!videoPreview?.url;

  const content = (
    <Fragment>
      <Preview>
        <img
          src={havePreview ? videoPreview?.url : emptyVideoPreview}
          style={{
            objectFit: havePreview ? 'cover' : 'contain',
          }}
          alt={`Превью видео: ${title}`}
        />
        <TimingWrapper>{timeToString(Math.round(duration ?? 0))}</TimingWrapper>
      </Preview>
      <Title className={'name'} title={title}>
        {title}
      </Title>
      <Footer>
        <span>
          {viewsCount}{' '}
          {pluralize(viewsCount ?? 0, curLanguage.lang, [
            t('videos.viewing.one'),
            t('videos.viewing.many'),
            t('videos.viewing.few'),
          ])}
        </span>
        <span>{dayjs(createdAt).format('D MMMM')}</span>
      </Footer>
    </Fragment>
  );

  if (editMode) {
    return (
      <WrapperSelectable
        onClick={handleSelect}
        selectedVideo={boolToText(isSelected)}
        resizable={boolToText(havePreview)}
      >
        <SelectWrapper>
          <SelectInner>
            <Checkbox className="plai-large-checkbox" checked={isSelected} />
          </SelectInner>
        </SelectWrapper>
        {content}
      </WrapperSelectable>
    );
  }

  return (
    <Wrapper resizable={boolToText(havePreview)} to={getVideoItemPath(id)}>
      {content}
    </Wrapper>
  );
};
