import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const LayoutTableIcon: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="45"
      height="24"
      viewBox="0 0 45 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H9C9.82843 0 10.5 0.671573 10.5 1.5V9C10.5 9.82843 9.82843 10.5 9 10.5H1.5C0.671573 10.5 0 9.82843 0 9V1.5Z"
        fill={theme.COLORS.BLACK._700}
      />
      <path
        d="M13.5 1.5C13.5 0.671573 14.1716 0 15 0H43.5C44.3284 0 45 0.671573 45 1.5V9C45 9.82843 44.3284 10.5 43.5 10.5H15C14.1716 10.5 13.5 9.82843 13.5 9V1.5Z"
        fill={theme.COLORS.BLACK._700}
      />
      <path
        d="M0 15C0 14.1716 0.671573 13.5 1.5 13.5H9C9.82843 13.5 10.5 14.1716 10.5 15V22.5C10.5 23.3284 9.82843 24 9 24H1.5C0.671573 24 0 23.3284 0 22.5V15Z"
        fill={theme.COLORS.BLACK._700}
      />
      <path
        d="M13.5 15C13.5 14.1716 14.1716 13.5 15 13.5H43.5C44.3284 13.5 45 14.1716 45 15V22.5C45 23.3284 44.3284 24 43.5 24H15C14.1716 24 13.5 23.3284 13.5 22.5V15Z"
        fill={theme.COLORS.BLACK._700}
      />
    </svg>
  );
};

export default withTheme(LayoutTableIcon);
