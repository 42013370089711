import styled from '@emotion/styled';
import { Button, Space } from 'antd';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { EFinancePeriod, EPlanName } from '../../types/finance';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

const PageTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
`;

const CurrentPlanSubText = styled.div`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

const CurrentPlanText = styled.div`
  font-size: 16px;
  line-height: 100%;
`;

const ExcludeText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 110%;
  max-width: 230px;
`;

type TProps = {
  t: TFunction<string[]>;
  currentPlan: EPlanName;
  currentType: EFinancePeriod;
};

export const FinanceHeader: FC<TProps> = ({ t, currentPlan, currentType }) => {
  return (
    <Wrapper>
      <Space align="center" size={30}>
        <PageTitle>{t('header.pageTitle')}</PageTitle>
        <div>
          <CurrentPlanSubText>
            {t('header.currentPlanSubText')}
          </CurrentPlanSubText>
          <CurrentPlanText>
            {t(`plan.${currentPlan}`)} ({t(`planPeriodVariants.${currentType}`)}
            )
          </CurrentPlanText>
        </div>
      </Space>
      <Space align="center" size={10}>
        <ExcludeText>
          <Trans
            t={t}
            i18nKey="header.planEndIn"
            count={4}
            components={{
              mark: <mark />,
            }}
          />
        </ExcludeText>
        <Button type="primary">{t('header.extend')}</Button>
      </Space>
    </Wrapper>
  );
};
