import { ButtonProps, message } from 'antd';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API } from '../api';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { playlistCurrentActions } from '../state/playlists/currentSlice';
import { playListAllActions } from '../state/playlists/listSlice';
import { usePlaylistCurrentSelector } from '../state/playlists/selectors';
import { getPlaylistItemPath } from '../utils/paths';

export const usePlaylistHandleSave: () => [
  () => void,
  ButtonProps | undefined,
  () => void,
  string[],
  Dispatch<SetStateAction<string[]>>,
] = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const { data } = usePlaylistCurrentSelector();
  const [editSelected, setEditSelected] = useState<string[]>([]);
  const navigate = useNavigate();

  const exitEditMode = useCallback(() => {
    navigate(getPlaylistItemPath(data?.id ?? ''));
  }, [data]);

  const [operationProps, setOperationProps] = useState<
    ButtonProps | undefined
  >();

  const handleSavePlaylist = useCallback(async () => {
    if (data) {
      const loading = message.loading(t('playlist.changing.loading'), 0);
      setOperationProps({
        loading: true,
      });

      try {
        const response = await API.playlists.edit(
          {
            ...data,
            videoIds: editSelected,
          },
          data.id
        );

        loading();
        message.success(t('playlist.changing.success'));

        dispatch(playlistCurrentActions.editPlayList(response.data));
        dispatch(playListAllActions.resetState());
        exitEditMode();
        setOperationProps({
          loading: false,
        });
      } catch (e) {
        loading();
        message.error(t('playlist.changing.error'));
        setOperationProps({
          loading: false,
        });
      }
    }
  }, [editSelected, data, exitEditMode, t]);

  return [
    handleSavePlaylist,
    operationProps,
    exitEditMode,
    editSelected,
    setEditSelected,
  ];
};
