import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TVideoResponse } from '../../types/responses/video';

type TState = {
  pending: boolean;
  loaded: boolean;
  error: boolean;
  data: TVideoResponse[];
};

const initialState: TState = {
  pending: false,
  loaded: false,
  error: false,
  data: [],
};

const viewedSlice = createSlice({
  name: 'video/viewed',
  initialState: initialState,
  reducers: {
    viewedVideosStartAction: (state) => {
      state.pending = true;
      state.loaded = false;
      state.error = false;
    },
    viewedVideosEndAction: (state, action: PayloadAction<TVideoResponse[]>) => {
      state.pending = false;
      state.loaded = true;
      state.error = false;
      state.data = action.payload;
    },
    viewedVideosErrorAction: (state) => {
      state.pending = false;
      state.loaded = false;
      state.error = true;
    },
  },
});

export const {
  viewedVideosStartAction,
  viewedVideosErrorAction,
  viewedVideosEndAction,
} = viewedSlice.actions;
export const viewedReducer = viewedSlice.reducer;
