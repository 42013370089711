import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthService } from './auth-service';
import { LocaleService } from './local-service';
import { PopoversService } from './popovers-service';
import { RootRoutes } from './routes';
import { StateService } from './state-service';
import { ThemeService } from './theme-service';

export const Services: FC = () => {
  return (
    <BrowserRouter>
      <StateService>
        <ThemeService>
          <AuthService>
            <LocaleService>
              <PopoversService>
                <RootRoutes />
              </PopoversService>
            </LocaleService>
          </AuthService>
        </ThemeService>
      </StateService>
    </BrowserRouter>
  );
};
