import styled from '@emotion/styled';
import { Button, Form, Input, Space, Typography } from 'antd';
import App from 'antd/lib/app';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { API, TOKEN_LS_PATH } from '../api';
import { TUseFormEnterProps, useFormEnterPress } from '../hooks/forms';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { loginAction } from '../state/auth/slice';
import { Paths } from '../types/common';
import { TLoginForm } from '../types/users';
import { GET_PASSWORD_BASE_RULES_FORM } from '../utils/forms';

const Title = styled(Typography.Title)`
  color: ${({ theme }) => theme.COLORS.WHITE._100} !important;
  margin: 0;
  margin-bottom: 16px !important;
`;

export const Registration: FC = () => {
  const { t } = useTranslation([LocaleKeys.LOGIN]);
  const dispatch = useAppDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm<TLoginForm>();
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (values: TLoginForm) => {
    setLoading(true);

    try {
      const response = await API.auth.registration(values);

      if (response.data.authorizationToken) {
        message.success(t('registration.success'));
        setLoading(false);

        localStorage.setItem(TOKEN_LS_PATH, response.data.authorizationToken);

        dispatch(loginAction(response.data.authorizationToken));
      } else {
        message.error(t('registration.error'));
        setLoading(false);
      }
    } catch (e) {
      message.error(t('registration.error'));
      setLoading(false);
    }
  };

  const formEnterProps: TUseFormEnterProps<typeof form.submit> = useMemo(
    () => ({
      submitFunc: form.submit,
    }),
    []
  );

  useFormEnterPress(formEnterProps);

  return (
    <div>
      <Title level={4}>{t('registration.title')}</Title>
      <Form
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        form={form}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="email"
          label="Email"
          className="themed"
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
          ]}
        >
          <Input
            className="themed"
            style={{
              width: 330,
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          className="themed"
          rules={[...GET_PASSWORD_BASE_RULES_FORM(t)]}
        >
          <Input.Password
            className="themed"
            style={{
              width: 330,
            }}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
      <Space>
        <Button type="primary" onClick={form.submit} loading={loading}>
          {t('buttons.registration')}
        </Button>
        <NavLink to={Paths.LOGIN}>
          <Button type="text" className="themed">
            {t('buttons.account.yes')}
          </Button>
        </NavLink>
      </Space>
    </div>
  );
};
