import { withTheme } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';

import { TIconBaseProps } from '../../types/common';

const Plus: FC<TIconBaseProps & HTMLAttributes<SVGElement>> = ({
  theme,
  ...props
}) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33 14.25C34.3281 14.25 35.5 15.4219 35.5 16.75V19.25C35.5 20.6562 34.3281 21.75 33 21.75H21.75V33C21.75 34.4062 20.5781 35.5 19.25 35.5H16.75C15.3438 35.5 14.25 34.4062 14.25 33V21.75H3C1.59375 21.75 0.5 20.6562 0.5 19.25V16.75C0.5 15.4219 1.59375 14.25 3 14.25H14.25V3C14.25 1.67188 15.3438 0.5 16.75 0.5H19.25C20.5781 0.5 21.75 1.67188 21.75 3V14.25H33Z"
        fill={theme.COLORS.BLACK._700}
      />
    </svg>
  );
};

export default withTheme(Plus);
