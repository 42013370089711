import { Paths, SecondaryPaths } from '../types/common';

export function getVideoItemPath(id: string): string {
  return `${Paths.VIDEO}/${id}`;
}

export function getPlaylistItemPath(id: string): string {
  return `${Paths.PLAYLIST}/${id}`;
}

export function getEditVideoPath(id: string): string {
  return getVideoItemPath(id) + '/edit';
}

export function getVideoActionPagePath(id: string): string {
  return getVideoItemPath(id) + '/actions';
}

export function getPlayerLink(videoId: string): string {
  return `${process.env.PLAYER_HOST}${videoId}`;
}

export function getCreateLinkPath(id: string): string {
  return getVideoItemPath(id) + SecondaryPaths.CREATE_LINK;
}

export function getPlayerEditLinkPath(videoId: string, linkId: string): string {
  return getVideoItemPath(videoId) + SecondaryPaths.EDIT_LINK + `/${linkId}`;
}

export function getVideoLinkStatisticPath(
  videoId: string,
  linkId: string
): string {
  return getVideoItemPath(videoId) + SecondaryPaths.STATISTICS + `/${linkId}`;
}
