import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useLocalStorageValue<T>(
  initialValue: T,
  lsKey: string
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(
    localStorage.getItem(lsKey)
      ? JSON.parse(localStorage.getItem(lsKey) as string)
      : initialValue
  );

  useEffect(() => {
    localStorage.setItem(lsKey, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
}
