import { combineReducers } from '@reduxjs/toolkit';

import { dataReducer } from './dataSlice';
import { dynamicsReducer } from './dynamicsSlice';
import { analyticFiltersReducer } from './filtersSlice';

export const analyticsReducer = combineReducers({
  filters: analyticFiltersReducer,
  all: dataReducer,
  dynamics: dynamicsReducer,
});
